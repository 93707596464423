<template>

  <div class="captions-v2">

    <div v-if="showCaptions">

      <div class="row caption-row" v-for="caption in captions">

        <div class="col-md-12 caption-entry nopad" v-on:mouseenter="mouseEnter(caption)" v-on:mouseleave="mouseLeave(caption)">

          <!-- timestamp and actions -->
          <p class="caption-header">
            <small class="pull-left">{{caption.start|timestamp('HH:mm:ss,SSS')}} --> {{caption.end|timestamp('HH:mm:ss,SSS')}}</small>
            <span class="float-right" v-if="caption.hover">
              <i ng-if="!$first" @click="merge(caption,-1)" v-b-tooltip.hover title="Merge with caption above" class="fa fa-level-up-alt text-info caption-action"/>
              <i ng-if="!$last" @click="merge(caption,1)" v-b-tooltip.hover title="Merge with caption below" class="fa fa-level-down-alt text-info caption-action"/>
              <i v-b-tooltip.hover title="Remove caption" @click="remove(caption)" class="fa fa-trash text-info caption-action" />
            </span>
          </p>

          <b-form-textarea
              v-model="caption.text"
              rows="2"
              max-rows="4"
          ></b-form-textarea>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { actions, getters } from '@/services/store'

export default {

  name: 'CaptionsEditor',

  data() {
    return {
      timer:false,
      captions: [],
      showCaptions:true
    }
  },

  props: {

    extent: {
      type: Array,
      required: true
    },

    source: {
      type: Object,
      required: true
    }

  },

  created() {
    const clip = this.source.clip
    if ( clip && clip.captions && clip.captions.entries ) {
      this.captions = clip.captions.entries
    } else this.fetchCaptions()
  },

  watch: {

    extent: {
      handler() {
        this.fetchCaptions()
      },
      deep:true
    }

  },

  methods: {

    toggleCaptions(ev) {
      ev.stopPropagation()
      ev.preventDefault()
      this.showCaptions = !this.showCaptions
    },

    mouseEnter(caption) {
      caption.hover=true
    },

    mouseLeave(caption) {
      caption.hover=false
    },

    merge(c,dir) {

      // selected caption
      var source = c
      var sidx = this.captions.indexOf(source)

      // target caption
      var tidx = sidx+dir
      var target = this.captions[tidx]

      // merge timestamps
      if ( dir < 0 ) { // merge up, set target end timestamp to source end
        target.text = target.text + ' ' + source.text
        target.end = source.end
      } else { // merge down, set target start timestamp to source start
        target.text = source.text + ' ' + target.text
        target.start = source.start
      }

      // and remove the source
      this.captions.splice(sidx,1)

      this.$emit('captions-edited',this.captions)

    },

    remove(c) {
      var idx = this.captions.indexOf(c)
      this.captions.splice(idx,1)
      this.$emit('captions-edited',this.captions)
    },

    async fetchCaptionsImpl() {

      try {

        const dashboard = getters.dashboard()

        const options = {
          source: `file:${this.source.file}`,
          start: this.extent[0],
          end: this.extent[1]
        }

        // fetch captions
        const captions = await actions.fetchCaptionsForSource( dashboard._id, options )

        // strip linefeeds
        this.captions = captions.reduce((acc,c) => {
          c.text = c.text.replace(/[\r|\n]/g,' ')
          c.hover=false
          acc.push(c)
          return acc
        },[])

        // console.log('fetched captions', typeof this.captions, JSON.stringify(this.captions,0,1) )

      } catch ( err ) {
        this.$toasted.error('Unable to fetch captions')
        console.error(err)
      }

    },

    fetchCaptions() {
      if ( this.timer ) {
        clearTimeout(this.timer)
        this.timer = false
      }
      this.timer = setTimeout( this.fetchCaptionsImpl, 500 )
    }

  }
}
</script>

<style lang="scss" >

.captions-v2 {
}

.caption-entry {
  margin-bottom:10px;
}

p.caption-header {
  margin-top: 10px;
}

i.caption-action {
  padding-left:10px;
}

</style>
