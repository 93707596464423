<template>

  <b-container class="vh-100">

    <b-row class="h-75" align-v="center" >

      <div class="col-md-7 offset-md-2">

        <h1>Let's get you back into Lately!</h1>
        <p v-if="state=='sent'" class="lead">We've sent an email to that user account - please check your inbox and follow the instructions to reset your password.</p>

        <b-form @submit.prevent="send" action="#">
          <p class="lead" v-if="state=='unsent'">Enter your registration email</p>
          <b-form-group
            label-for="input-1"
          >
            <b-form-input
              @input="validate"
              :disabled="state=='sent'"
              autocomplete="email"
              autofocus
              v-model="email"
              type="email"
              placeholder="Registration email"
              required
            ></b-form-input>
          </b-form-group>

          <div class="mt-4" v-if="state=='unsent'">
            <b-button @click="send" :disabled="!valid" type="submit"  variant="primary">SUBMIT</b-button>
            <b-button @click="email=''" type="button" variant="outline"><small>Reset</small></b-button>
          </div>

          <p class="mt-4">
            <small>Remembered that password?
              <a href='#/app/login'>Login</a>
            </small></p>

          <p class="text-danger" v-if="msg">{{msg}}</p>

        </b-form>

      </div>

    </b-row>

  </b-container>

</template>

<script>

  'use strict'

  import { actions } from "../services/store.js";

  export default {

    name: 'ResetPassword',

    data() {
      return {
        valid:false,
        state:'unsent',
        email:'',
        msg:''
      }
    },

    methods:{

      validate() {
        var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        this.valid = re.test(this.email)
      },

      async send() {

        this.msg = ''

        try {

          await actions.resetPassword(this.email)
          this.state = 'sent'

        } catch(err) {
          this.msg = (err.message || `Hmm, that didn't work. Please try again in a few minutes`)
        }

        return false;

      },

      reset() {
        this.valid=false;
        this.email='';
        this.msg='';
      }
    }

  }
</script>
