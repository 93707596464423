<template>

    <!-- actions modal -->
    <b-modal static lazy :ref="id" :size="state==='self-schedule'? 'xl':'lg'" :id="id" hide-header hide-footer  @show="show" no-close-on-esc>

      <template slot="default" slot-scope="{ hide }">

        <div class="schedule-dest-modal nopad nomargin">

          <div class="header text-center nopad nomargin">
            <img src="@/assets/loading_green.gif" width="100px" />
          </div>

          <div class="content text-center" v-if="partner==='lately'">

            <div v-if="state==='options'">

              <h3>Schedule Your Post</h3>

              <!--
              <p><b-button @click="sendTo('lately-scheduled')">LET LATELY SCHEDULE IT</b-button></p>
              -->
              <p><b-button @click="state='self-schedule'">PICK A DATE & TIME</b-button></p>
              <p><b-button @click="sendTo(partner,true)">POST IT NOW</b-button></p>
              <p><b-button @click="sendTo('queue')">SAVE IT FOR LATER</b-button></p>

            </div>

            <div v-if="state==='self-schedule'" class="text-center">
                <h3>Select a Date and Time for your Post</h3>
                <Scheduler :post="post" @on-scheduled="onScheduled" @on-action="onAction" />
                <div class="form-group buttons text-right">
                  <b-button variant="default" @click="state='options'">Back</b-button>
                  <b-button :disabled="!selectedSchedule" variant="primary" @click="sendTo('self-scheduled')">SCHEDULE IT</b-button>
                </div>
            </div>

          </div>

          <div class="content text-center" v-else>

            <h3>Save your Post</h3>
            <p><b-button @click="sendTo(partner,true)">SEND IT TO {{partner.toUpperCase()}}</b-button></p>
            <p><b-button @click="sendTo('queue')">SAVE TO DRAFTS</b-button></p>

          </div>

        </div>

      </template>

    </b-modal>

</template>

<script>

import ChannelPickerModal from '@/components/ChannelPickerModal'
import Scheduler from '@/components/Scheduler'

import moment from 'moment'

const now = moment()

export default {

  name: 'PostActionsDialog',

  data() {
    return {
      sendToTarget:'',
      selectedSchedule:'',
      scheduledPosts:[],
      showScheduled: false,
      state: 'options',
      schedule: {
        date:false,
        valid:false
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    post: {
      type: Object,
      required: true
    },
    partner: {
      type: String,
      required: true
    },
    dashboard: {
      type: Object,
      required: true
    }
  },

  methods: {

    onAction(ev) {
      switch( ev.action ) {
        case 'schedule-selected' : {
          this.selectedSchedule = ev.schedule
          break;
        }
      }
    },

    show() {
     this.state = 'options'
    },

    onScheduled(schedule) {
      this.schedule.valid = moment(schedule).isAfter(moment())
      if ( this.schedule.valid ) {
        this.schedule.selected = schedule
      }
    },

    sendTo(dest,publish=false) {
      switch( dest ) {
        case 'lately-scheduled' : {
          this.$refs[this.id].hide()
          this.$emit('on-scheduled','pick-for-me' )
          break;
        }
        case 'self-schedule' : {
          this.state = 'self-schedule' // shows the pick a date form
          // this.schedule.date = this.post.(this.schedule.date).toDate()
          break;
        }
        case 'self-scheduled' : {
          this.$refs[this.id].hide()
          this.$emit('on-scheduled', this.selectedSchedule.toISOString() )
          break;
        }
        default: {
          this.$refs[this.id].hide()
          this.$emit('on-send-to',{
            dest:dest,
            post:this.post._id,
            publish:publish
          })
        }
      }
    },
    latelySchedule() {
      this.$refs[this.id].hide()
      this.$emit('on-send-to',{
        dest:'partner',
        post:this.post._id,
        schedule:'lately',
      })
    }
  },
  components: {
    ChannelPickerModal,
    Scheduler
  }
}
</script>

<style lang="scss">

[id^=schedule-modal] .modal-content {

  border-radius:20px!important;

  .modal-body {

    padding: 0px;
    margin: 0px;

    .schedule-dest-modal {

      .header {
        height: 160px;
        border-radius: 20px 20px 0px 0px;
        background-color: #e6f9f0!important;
        img {
          // position: relative;
          width: 100px;
          // top: 5px;
        }
      }

      .content {
        margin-top: 0px;
        padding: 20px;
        border-radius: 0px 0px 20px 20px;
        background-color:white!important;

        .date-picker-section {
          width: 100%!important;
          height: 400px!important;
          overflow: auto;
          .v-cal {
            padding: 0px 20px 0px 20px;
            height: 600px!important;
            overflow: auto;
            .v-cal-header {
              display:none
            }
          }
        }

        .agenda-picker-section {
          width: 40%!important;
          height: 400px!important;
          overflow: auto;
          .v-cal {
            padding: 0px 20px 0px 20px;
            height: 600px!important;
            overflow: auto;
            .v-cal-header {
              display:none
            }
            .v-cal-weekdays {
              display:none
            }
          }
        }

        .buttons {
          margin: 20px;
        }

        h3 {
          font-weight: bold;
          margin-bottom: 20px;
        }
        p {
          margin: 0px;
          button {
            font-size: 16px;
            color: black;
            border: 2px solid darkgrey;
            border-radius: 10px;
            background-color: white;
            margin: 10px!important;
            font-weight: bold;
            padding: 10px 30px 10px 30px;
            min-width: 270px!important;
          }
        }

        .scheduled-posts {
          padding-left:40px;
          margin-bottom: 20px;
          h4 {
            font-size:14px;
            font-weight: bold;
          }
          p {
            font-size: 12px;
          }
        }

        .schedule-picker {

          text-align: center;

          label {
            padding-right: 10px;
          }

          input {
            display: inline;
            width: 260px;
          }

          select {
            margin-right: 5px;
            width: 80px;
          }

          button {
            padding: 5px 20px 5px 20px!important;
            margin-left: 20px;
            border-radius: 10px;
          }

          button.btn-primary {
            background-color: #008CFF !important;
          }

        }

      }

    }

  }

}


</style>
