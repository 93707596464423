import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

// Create and Install Session Replay Plugin
if (process.env.VUE_APP_AMPLITUDE_KEY) {
    amplitude.init(process.env.VUE_APP_AMPLITUDE_KEY)
    const sessionReplayTracking = sessionReplayPlugin()
    amplitude.add(sessionReplayTracking)
}

const log = (id, props = {}) => {
    // console.log('logger', { id, props })
    try {
        if (process.env.VUE_APP_AMPLITUDE_KEY) {
            amplitude.track(id, props)
        }
    } catch (err) {
        console.error(`amplitude threw ${err.message}`)
    }
}

class Logger {
    static log(key, props) {
        console.log('Logger.log', key, props)
    }

    // post login initialize
    static userSessionStarted(user) {
        amplitude.setUserId(user._id)
        const identify = new amplitude.Identify()
        identify.set('email', user.email)
        amplitude.identify(identify)
        log('user-login')
    }

    static pageViewed(page) {
        log('page-viewed', { 'Page Name': page })
    }

    static registrationStarted(user) {
        Logger.userSessionStarted(user)
        log('registration-started')
    }

    static noCCRegistration(user, page) {
        Logger.userSessionStarted(user)
        log('nocc-registration', { page })
    }

    static registrationCompleted(user, plan, price) {
        log('registration-completed', { plan: plan.name, amount: price.amount, currency: price.currency, interval: price.interval })
    }

    static channelsUpdated(dashboard, channels, action) {
        Object.keys(channels).forEach((id) => {
            const selected = channels[id]
            const channel = dashboard.channels.find((ch) => {
                return ch._id === id
            })
            if (channel && selected && action === 'select') {
                log('channel-connected', { dashboard: dashboard._id, type: channel.type, partner: channel.content.partner })
            } else if (channel && selected && action === 'remove') {
                log('channel-disconnected', { dashboard: dashboard._id, type: channel.type, partner: channel.content.partner })
            }
        })
    }

    static onboardingStep(step) {
        log('onboarding-step', { step })
    }

    static onboardingCompleted(key) {
        log('onboarding-completed', { type: key })
    }

    static sessionCreated(session) {
        log('generator-session-initiated', { type: session.config.steps.source.type })
    }

    static postsEdited(posts) {
        log('posts-edited', { posts: posts.length })
    }

    static postsScheduled(posts, channels) {
        posts.forEach((p) => {
            if (p.status === 'DT') {
                channels.forEach((ch) => {
                    log('post-scheduled', { dashboard: p.dashboard, post: p._id, type: p.channel, channel: ch.channel })
                })
            } else log('post-scheduled', { dashboard: p.dashboard, post: p._id, type: p.contentType, channel: p.channel_meta.id })
        })
    }

    static postsSent(posts, partner) {
        posts.forEach((p) => {
            const type = p.contentType === 'temporary' ? p.channel : p.contenType
            log('post-sent', { dashboard: p.dashboard, post: p._id, type: type, partner: partner })
        })
    }

    static postsExported(posts) {
        posts.forEach((p) => {
            log('post-exported', { dashboard: p.dashboard, post: p._id, type: p.channel }) // at export channel identifies the content type
        })
    }

    static postsRemoved(posts) {
        posts.forEach((p) => {
            log('post-removed', { post: p._id, dashboard: p.dashboard, type: p.contentType, channel: p.channel })
        })
    }

    static postSent(dashboard, partner, post, channels, dest) {
        channels.forEach((ch2) => {
            const channel = dashboard.channels.find((ch) => {
                return ch._id == ch2.channel || (ch.content.id && ch.content.id === ch2.channel)
            })
            if (channel) {
                if (dest === 'queue') {
                    log('post-sent-to-queue', { dashboard: dashboard._id, post: post._id, type: channel.type, partner: partner, status: post.status })
                } else {
                    log('post-sent-to-partner', { dashboard: dashboard._id, post: post._id, type: channel.type, partner: partner, status: post.status })
                }
            } else console.error('Logger.postSent did not find channel', ch2.channel)
        })
    }

    static userSessionEnded() {
        log('user-logout')
        amplitude.reset()
    }
}

export default Logger
