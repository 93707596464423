<template>

  <span class="channel-icon" v-if="media[channelType]">

    <span v-if="numericBadge" class="fa fa-stack" :data-count="numericBadge">
      <i class="fa fa-circle fa-stack-2x" :style="'color:'+media[channelType].color"></i>
      <i :class="'fa-brands fa-stack-1x fa-inverse fa-' + channelIcon"></i>
    </span>

    <span v-else-if="checkBadge" class="fa fa-stack complete" :data-count="0">
      <i class="fa fa-circle fa-stack-2x" :style="'color:'+media[channelType].color"></i>
      <i :class="'fa-brands fa-stack-1x fa-inverse fa-' + channelIcon"></i>
    </span>

    <span v-else @click="click($event,channelType)" :class="mode==='badge'? 'channel-icon stacked-channel-icon fa-stack fa-2x':''">
      <i v-if="mode !== 'badge'" :class="'fa-brands fa-fw fa-'+size+' fa-'+ channelIcon"  :style="'color:'+media[channelType].color"></i>
      <i v-if="mode === 'badge'" :class="'fa fa-solid fa-circle fa-stack-2x'" :style="'color:'+media[channelType].color"></i>
      <i v-if="mode === 'badge'" :class="'fa-brands fa-solid fa-inverse fa-stack-1x fa-'+ channelIcon" ></i> {{label|titlecase}}
    </span>

  </span>

</template>

<script>

'use strict'

import { media } from '../services/constants'

export default {
  name: 'ChannelIcon',
  data() {
    return {
      media:media
    }
  },
  props: {
    post: {
      type: Object
    },
    type: {
      type: String,
      default:''
    },
    size: {
      type: String,
      required: true,
    },
    click: {
      type: Function,
      default: function() {}
    },
    label: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    numericBadge: {
      type: Number
    },
    checkBadge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    channelType() {
      return this.type? this.type : this.post? this.post.contentType !== 'temporary'? this.post.contentType : this.post.channel : this.type
    },
    channelIcon() {
      const type = this.channelType
      return type === 'twitter'? 'square-x-twitter' : type
    }
  }
}
</script>

<style lang="scss" >

.channel-icon {
  font-size: 19px;
  margin-left: 10px;
  color: white;

.fa-stack[data-count]:after{
  position:absolute;
  right:-10%;
  top:-10%;
  content: attr(data-count);
  font-size:50%;
  padding:.45em;
  border-radius:999px;
  border:0px solid white;
  line-height:1.2em;
  color: white;
  background:#3D3C3A;
  text-align:center;
  min-width:2em;
  font-weight:bold;
}

.fa-stack.complete[data-count]:after{
  position:absolute;
  right:-10%;
  top:-10%;
  background-color:white;
  content: '\2713';
  font-size:50%;
  padding:.4em;
  border-radius:999px;
  border:1px solid white;
  line-height:.75em;
  background:#20C763;
  text-align:center;
  min-width:2em;
  font-weight:bold;
}

}

</style>
