<template>

  <div class="container" >

    <div class="row">

      <div class="col-md-12">
        <h2 class="msg">
          Settings
        </h2>

        <hr>

        <br/>
      </div>

      <div class="col-md-12">

        <b-tabs content-class="mt-3" vertical pills card v-model="tab">

            <b-tab title="Account" >
              <AccountInfo />
            </b-tab>

            <b-tab title="Password" >
              <PasswordInfo />
            </b-tab>

        </b-tabs>

      </div>

    </div>

  </div>
</template>

<script>

'use strict'

import PasswordInfo from './PasswordInfo';
import AccountInfo from './AccountInfo';

export default {

    name:'Settings',

    data() {
      return {
        tab:parseInt(this.$route.query.tab||'0')
      }
    },

    components: {
      PasswordInfo,
      AccountInfo
    },

    watch: {
      async tab () {
        await this.$router.replace({ path: this.$route.path, query: { tab: this.tab } }).catch(()=>{})
      }
    }

}

</script>

<style>


</style>
