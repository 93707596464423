,<template>

  <div class="row goal-picker" v-if="goal">

    <!--
    <div class="col-md-1">
      <input v-if="mode==='individual'" class="form-control" v-model="model[0]" >
    </div>
    -->

    <div class="col-md-11">
      <vue-slider v-model="goal[scope][channel].val" :max="goal[scope][channel].max" tooltip="active" :enable-cross="false"></vue-slider>
      <p v-if="msg">{{msg}} (min {{goal[scope][channel].val[0]}}, max {{goal[scope][channel].val[1]}})</p>
      <p v-if="!msg">{{channel|titlecase}} (min {{goal[scope][channel].val[0]}}, max {{goal[scope][channel].val[1]}})</p>
    </div>

    <div class="col-md-1">
      <input type="number" min="1" class="form-control" v-model.number="goal[scope][channel].max" >
    </div>

  </div>

</template>

<script>
export default {

  name: 'GoalPicker',

  props: {
    goal: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    },
    channel: {
      type: String,
      required: true
    },
    msg: {
      type: String
    }
  }

}
</script>

<style lang="scss" >

</style>
