<template>
  <div class="GPT">
    <Transition>
      <div v-if="state==='prompt'">
        <b-overlay :show="busy">
          <b-form-textarea 
            v-model="request.text"
            :placeholder="request.placeholder"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <b-button v-if="response.text" size="sm" :disabled="busy" variant="default" class="float-left" @click="reset('response')">SHOW RESPONSE</b-button>          
          <b-button size="sm" :disabled="busy" variant="primary" class="float-right" @click="submit()">COMPOSE</b-button>
        </b-overlay>
      </div>
      <div v-if="state==='response'">
        <b-form-textarea 
            v-model="response.text"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <b-button size="sm" class="float-left" variant="default" @click="reset('prompt')">EDIT PROMPT</b-button>          
          <b-button size="sm" class="float-right" variant="primary" @click="accept($event)">ACCEPT THIS TEXT</b-button>
      </div>      
    </Transition>
  </div>
</template>

<script>

import { actions, getters } from '@/services/store'
import Vue from 'vue'

export default {
  name: 'GPT',
  data() {
    return {
      busy: false,
      state: 'prompt',      
      request: {
        text: '',
      },
      response: {
        text:'',
        image:undefined
      }
    }
  },
  props: {
    goal: {
      type: String,
      default: ''
    },
    prompt: {
      type: String,
      default:''
    },
    placeholder: {
      type: String,
      default:''
    }
  },
  created() {
    this.request.text = this.prompt
    this.request.placeholder = this.placeholder
  },
  methods: {
    reset(state='prompt') {
      this.state = state
    },
    accept(ev) {
      ev.stopPropagation()
      ev.preventDefault()
      this.$emit('on-gpt-accept',this.response.text)
      Vue.nextTick(()=>{
        this.request.text = ''
        this.response.text = ''
        this.state = 'prompt'      
      })
      return false;
    },
    async submit() {
      this.busy = true
      this.response.text = ''
      try {
        const dashboard = getters.dashboard()
        console.log('submitting', this.text)
        const response = await actions.gptRequest( dashboard._id, this.goal, this.request.text )
        if ( this.goal === 'text' ) {
          this.response.text = response.content.trim()
          this.state = 'response'
          console.log('gpt3 response', response)
        }
      } catch ( err ) {
        console.error(err)
      } finally {
        this.busy = false
      }
    }
  }
}


</script>

<style lang="scss" >
.GPT {
  textarea {
    margin-bottom: 10px;
  }
}
</style>
