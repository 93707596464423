<template>
  <div class="container onboard-page onboard-timing">

    <div class="row onboard-page-header text-center">
      <div class="col-10 offset-1 timing-graphic">
        <img src="@/assets/onboarding/onb_time.png" />
      </div>
      <div class="col-10 offset-1">
        <span class="title"> The average person takes 12 minutes to write just one social post. GAH!</span>
      </div>
      <div class="col-10 offset-1">
        <span class="description">How long does it take you? #behonest</span>
      </div>
    </div>

    <b-row class="timing-sections">
      <b-col sm="11" offset=1>
        <b-row>
          <b-col class="timing-section text-center" v-for="(section,idx) in sections" :key="idx" @click="select(section)" :class="{selected:(selected===section)}">
            <span>{{section}} <br/> minutes</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- time wasted -->
    <transition name="fade">
    <b-row v-show="selected">
      <b-col sm="11" offset=1>
        <div class="d-flex justify-content-center time-wasted">
          <img src="@/assets/onboarding/eyes_1f440.png" />
          <span class="time-wasted-description text-center">With Lately, the average is 3 minutes.
            <span v-if="waste.hours || waste.minutes">
              <span v-if="waste.hours > 1">Based on your cadence data, that means you're wasting <b>{{waste.hours|number(0)}} hrs a day!</b> </span>
              <span v-else>Based on your cadence data, that means you're wasting <b>{{waste.minutes|number(0)}} minutes a day!</b> </span>
            </span>
          </span>
        <img src="@/assets/onboarding/eyes_1f440.png" />
        </div>
      </b-col>
    </b-row>
    </transition>

    <div class="row onboard-page-footer">
      <div class="col-10 offset-1 text-center">
        <b-button @click="next(-1)" class="onboard-back">BACK</b-button>
        <b-button @click="next(1)" :disabled="!selected" class="onboard-continue">SAVE ME, LATELY!</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: 'GeneratorOnboard.Ready',

  data() {
    return {
      waste: {
        minutes: 0,
        hours: 0
      },
      postsPerDay:0,
      selected:false,
      sections:[5,10,15,20,25,30,60]
    }
  },

  props: {
    stats: {
      type: Object
    }
  },

  // compute number of posts for time wasted computation
  created() {

    this.stats.buckets = this.stats.buckets || {}

    this.postsPerDay = Object.keys(this.stats.buckets).reduce((acc,key)=>{
      const bucket = this.stats.buckets[key]
      acc += (bucket.content||0) / (bucket.days||1)
      return acc
    },0)

  },

  methods: {
    select(selection) {
      if ( this.postsPerDay ) {
        this.waste.minutes = (selection-3) * this.postsPerDay
        this.waste.hours = this.waste.minutes / 60
      }
      this.selected = selection
    },
    next(dir) {
      this.$emit('on-next',dir)
    }
  }

}
</script>

<style lang="scss" >

.fade-enter-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.onboard-timing {

  .timing-sections {

    margin-top: 36px;

    .timing-section {
      margin: 10px;

      padding: 32px 5px 32px 5px;
      border: 3px solid #D6D6D6;
      border-radius: 5px;
      font-size: 22px;
      font-weight: bold;

      &:hover {
        border-color: grey;
      }
    }

    .timing-section.selected {
      border: 3px solid #2D2D2D;
    }

  }

  .time-wasted {

    background-color: #DCF0FF!important;
    border-radius: 20px;
    margin-top: 36px;
    padding: 24px;
    font-style: italic;

    img {
      width:52px;
    }

    .time-wasted-description {
      margin-top: 8px;
      font-size: 24px
    }
  }

}

</style>
