<template>
  <div class="row">
    <div class="container">
      <h3 class="msg">Manage Team</h3>
      <p class="lead">Invite clients or coworkers to join your team!</p>
      <div class="collaborators">
        <MemberView v-for="(participant,idx) in participants" :participant="participant" :user="user" @member-removed="memberRemoved" :key="idx" />
        <div class="new-invite" v-if="isOwner">
          <InviteForm @member-added="memberAdded" :participants="participants" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { actions, getters } from '@/services/store'
import MemberView from './MemberView'
import InviteForm from './InviteForm'
import Vue from 'vue'

export default {

  name: 'TeamView',

  data() {

    return {
      user:false,
      isOwner:false,
      participants:[],
      showInvite:false
    }

  },

  async created() {
    this.init()
  },

  methods: {

    async init() {
      try {
        this.user = getters.user()
        this.isOwner = getters.isOwner()
        const dashboard = getters.dashboard()
        this.participants = await actions.fetchMembers( dashboard._id )
      } catch( err ) {
        this.$toasted.error('Unable to fetch members')
      }
    },

    memberAdded(participant) {
      if ( participant.type === 'invitation' ) {
        this.$toasted.success('Invitation sent')
      } else if ( participant.type === 'member' ) {
        this.$toasted.success('Member added')
      }
      Vue.nextTick(()=>{
        this.participants.push(participant)
      })
    },

    memberRemoved(participant) {
      if ( participant.type === 'invitation' ) {
        this.$toasted.success('Invitation removed')
      } else if ( participant.type === 'member' ) {
        this.$toasted.success('Member removed')
      }
      const idx = this.participants.indexOf(participant)
      if ( idx !== -1 ) {
        Vue.nextTick(()=>{
          this.participants.splice(idx,1)
        })
      }
    }

  },

  components: {
    MemberView,
    InviteForm
  }

}
</script>

<style lang="scss" >

.collaborators {
  padding:20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid lightgrey;
}

.invite-trigger {
  color: #0096FF;
}

.invite-trigger .fa-plus{
  color: white;
}

.invite-form {
  color: purple;
}

.member-email {
  width: 100px;
}

.new-invite {
  width: 100%;
}

.invite-actions button {
  margin-left:10px;
}

.collaborators hr {
  border: 1px solid lightgrey;
}
</style>
