<template>
  <PublisherView mode="onboard" @on-action="onAction" />
</template>

<script>

import PublisherView from '@/components/PublisherView'

export default {
    name:'Connect',
    methods: {
        onAction(evt) {
            this.$emit('on-action',evt)
        }
    },
    components: {
        PublisherView
    }
}
</script>

<style lang="scss" >

</style>
