let SELECTIONS = []
import { events } from './store'

class SelectionService {
    static toggle(items) {
        let modded = false,
            idx
        items = Array.isArray(items) ? items : [items]
        items.forEach((item) => {
            idx = SELECTIONS.findIndex((p) => {
                return p._id === item._id
            })
            if (idx === -1) {
                SELECTIONS.push(item)
                modded = true
            } else {
                SELECTIONS.splice(idx, 1)
                modded = true
            }
        })
        if (modded) {
            events.$emit('selections-changed')
        }
        return idx === -1
    }

    static exclude(item) {
        let idx = SELECTIONS.findIndex((p) => {
            return p._id === item._id
        })
        if (idx !== -1) {
            SELECTIONS.splice(idx, 1)
            events.$emit('selections-changed')
        }
    }

    static includes(item) {
        return (
            SELECTIONS.find((i) => {
                return i._id === item._id
            }) !== undefined
        )
    }

    static clear() {
        SELECTIONS.length = 0
        events.$emit('selections-changed')
    }

    static items() {
        return SELECTIONS.slice()
    }
}

export default SelectionService
