<template>
    <div class="onboarding-nav ">
      <div class="row">
        <div class="col-md-8 offset-2">
          <div class="steps container text-center"> 
            <div class="step" :class="s.ids.includes(state)?'active':''" v-for="(s,idx) in steps">
                <p class="index">{{ idx+1 }}</p>
                <p class="name">{{ s.name }}</p>        
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'steps',
  data() {
    return {
        steps:[{
          ids:['generate','review'],
          name:'Generate'
        },{
          ids:['edit'],
          name:'Edit'            
        },{
          ids:['preview'],
          name:'Preview'            
        }

        ]
    }
  },
  props: {
    msg: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default:'generate'
    }
  }
}
</script>

<style lang="scss" >
.onboarding-nav {

    padding: 15px 30px 0px 30px;
    z-index: 1000;

  .steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .step {
      font-size: 22px;      
      p {
          margin-bottom: 0px; 
      }
      p.index {
        font-size: 26px;
        font-weight: bold;
      }

    }

    .step.active {
        color: rgb(32, 199, 99);
    }

  }
  

}
</style>
