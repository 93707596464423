
<template>

  <ChipEditor v-if="messages"
    :chips="messages"
    :editable="true"
    label="message"
    :prompt="prompt"
    :format="format"
    :onCreate="addMessage"
    :onRemove="removeMessage" />

</template>

<script>

'use strict'

import { store, actions } from '@/services/store';

import ChipEditor from '../ChipEditor';

export default {

  name: 'Messages',

  data() {
      return {
        prompt:false,
        messages:false
      }
  },

  props: {

    type: {
        type: String,
        required:true
    },
    editable: {
        type: Boolean,
        default: false
    },
    words: {
      type: Array
    }

  },

  async created() {
      this.messages = this.words //.map((w)=>{return w.message})
      this.prompt = 'Enter a ' + this.type.substring(0,this.type.length-1)
  },

  methods: {

    format(chip) {
      return this.type==='Hashtags' && !chip.message.startsWith('#')? '#' + chip.message : chip.message
    },

    async addMessage(chip) {
      chip = this.type === 'Hashtags'? `#${chip}` : chip
      this.$emit('add-message', { type:this.type, group: this.group, message: chip})
    },

    async removeMessage(message) {
      this.$emit('remove-message', message, this.type )
    }

  },

  components: {
    ChipEditor
  }

}
</script>

<style lang="scss" >

</style>
