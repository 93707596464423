<template>
  <div class="no-results-view">
    <div class="row">
      <div class="col-md-12">
        <h3><b>Oops! Looks like there's a problem with that link.</b></h3>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6">
        <h5><b>Common Problems:</b></h5>
        <ul class="no-results-list">
          <li v-for="(row,idx) in msgs" :key="idx">
            {{row.p}}
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <h5><b>Try:</b></h5>
        <ul class="no-results-list">
          <li v-for="(row,idx) in msgs" :key="idx" v-if="row.s">
            {{row.s}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResultsText',
  data() {
    return {
      msgs: [{
        p:'The text is too short.',
        s:`Include at least 1000 characters of text.`
      },{
        p:'The text does not have enough meaningful content.',
        s:`The AI identifies key statements and concepts from the text, but won't be able to proceed if it does not find any. Try rephrasing the content around a few central ideas.`
      }]
    }
  }
}
</script>

<style lang="scss" >
.no-results-view {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

ul.no-results-list {
  list-style: none; /* Remove default bullets */
}

ul.no-results-list li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: lightgreen; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1.5em; /* Also needed for space (tweak if needed) */
  margin-left: -2em; /* Also needed for space (tweak if needed) */
}
</style>
