<template>
  <div class="onboarding-info d-flex align-items-center justify-content-center">
    <div class="container">
        <h3>How do we build your Voice Model?</h3>
        <p>Lately is unique because we build custom Voice Models that learn from your social channels and create a baseline of what works and what doesn't. 
        We call this the 'DNA" that makes up the ideal messaging for you and your target audience. </p>
        <div>
        <img src="~@/assets/onboarding/v2/molecules.svg" height="250px">
        </div>
      <b-button @click="next(1)">COOL, LET'S GO!</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  methods: {
    next(dir) {
      this.$emit('onboarding-next',dir)
    }
  }
}
</script>

<style lang="scss" >
.onboarding-info {

  flex: 1;

  min-height: 100vh;

  h3 {
    font-size: 32px;
    font-weight: bold;
  }

  button {
    margin-top: 50px;
  }
}
</style>
