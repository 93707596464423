<template>
  <div class="container article-view">
    <div class="row">
      <div class="col-md-12">
        <h2>Discover Articles
          <small class="float-right">
            <i class="powered-by">Powered by </i>
            <img src="@/assets/integrations/upcontent.png" height="40px" />
          </small>
        </h2>
      </div>
      <div class="col-md-12">
        <p v-if="category" class="float-right category-select">
          <b-dropdown :text="category.title" class="m-2" variant="outline">
            <b-dropdown-item href="#" v-for="(category,idx) in categories" :key="idx" @click="selectCategory(category)">{{category.title}}</b-dropdown-item>
          </b-dropdown>
        </p>
      </div>
    </div>

    <!--
    Article format
    {
      "id": "c96308e8-2b0d-46b6-86f6-2d9c3eb90076",
      "title": "Male Body Diversity Highlighted at Central Saint Martins MA Fashion Show",
      "posted": "2022-02-21",
      "creator": "Women's Wear Daily",
      "text": "Edward Mendoza and Jessan Macatangay took home the grand prize L\u2019Or\u00e9al Professional Creative Award this year.",
      "article_url": "https://wwd.com/fashion-news/designer-luxury/central-saint-martins-male-body-diversity-ma-fashion-graduate-show-1235087140/",
      "short_url": "https://go.upcontent.com/c96308e8-2b0d-46b6-86f6-2d9c3eb90076",
      "image_url": "https://q.img.upcontent.com/500x/https://s.yimg.com/ny/api/res/1.2/AmGcX5qX0DqEAbS7PqmSBA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD04MDA-/https://s.yimg.com/uu/api/res/1.2/6jvIpE2WK_JrmQCxx7_gsg--~B/aD0xODM0O3c9MzI2NDthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en/wwd_409/ce09a1dd4439c7d1f3f2a5497590841f",
      "fallback_image": "https://app.lately.ai/img/lately_signup_heart.b65a5245.png"
    }
    -->

    <div class="row article" v-for="article in articles" :key="article.id">
      <div class="col-md-2">
        <!-- todo - add fallback image support -->
        <img fluid-grow :src="article.image_url" width="100%" />
      </div>
      <div class="col-md-8">
        <h5 class="article-title"><b><a :href="article.article_url" target="_blank">{{article.title}}</a></b></h5>
        <p>{{article.text}}</p>
      </div>
      <div class="col-md-2">
        <b-button class="float-right" variant="success" @click="selectArticle(article)">Generate</b-button>
      </div>
    </div>
  </div>
</template>

<script>

const SESSION_KEY = 'discover-articles.selection'

import Session from '@/services/session'
import { actions, getters } from '@/services/store'
import { Categories } from '@/services/constants'

export default {

  name: 'HelloWorld',

  data() {
    return {
      articles:[],
      categories: [],
      category: false,
      dashboard: false
    }
  },

  methods: {

    async selectCategory(category) {
      try {
        this.category = category
        Session.set(SESSION_KEY, category.title)
        this.articles = await actions.fetchArticlesFor(this.dashboard._id,category.url)
      } catch( err ) {
        this.$toasted.error(`We were not able to load any articles for that source. Please try another, or try again in a few minutes.`)
      }
    },

    async selectArticle(article) {
        const resp = await actions.createSession( 'text', article.short_url, {
          title: article.title
         } )
        this.$router.push(`/${this.dashboard._id}/generate/${resp.session._id}/${resp.count}`)
    }

  },

  created() {

    this.dashboard = getters.dashboard()

    this.categories = Categories.slice()

    let category = Session.get(SESSION_KEY, this.categories[0].title )
    category = this.categories.find((c)=>{ return c.title === category })
    if ( category ) {
      this.selectCategory(category)
    }

  }
}
</script>

<style lang="scss" >

.article-view .article {
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.article-view .article-title {
  padding-top:10px;
}

.article-view .category-select button {
  min-width: 200px;
  border-color: lightgrey;
}

.article-view .powered-by {
  font-size: 0.6em;
}

</style>
