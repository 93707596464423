<template>
    <div class="row analytics-overall-chart" v-if="ready">
    <div class="col text-center">
      <apexchart v-if="ready" type="area" height="650" ref="chart" :options="options" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>

let moment = require('moment')

export default {
  name: 'OverallStatsChart',
  data() {
    return {
        series:[],
        options: {
          chart: {
              id: 'area-datetime',
              type: 'area',
              height: 350,
              zoom: {
              autoScaleYaxis: true
              }
          },
          annotations: {
              
          },
          dataLabels: {
              enabled: false
          },
          markers: {
              size: 0,
              style: 'hollow',
          },
          xaxis: {
              type: 'datetime',
              min: undefined,
              tickAmount: 6,
          },
          tooltip: {
              x: {
              format: 'dd MMM yyyy'
              }
          },
          fill: {
              type: 'gradient',
              gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
              }
          },
        },
        ready:false,
    }
  },
  props: {
    from: String,
    stats: {
      type: Object,
    }
  },
  watch: {
    stats() {

      if ( this.stats ) {

        try {

          this.stats.data.forEach((arr,idx)=>{
            let start = moment(this.from,'YYYY-MM-DD').startOf('day')      
            let name = this.stats.series[idx]   
            let data =  arr.map((e)=>{
              let entry = []
              entry.push(start.valueOf())
              entry.push(e[0])
              start.add(1,'day')
              return entry
            },[])

            // console.log('composed series',{name,data})
            this.series.push({
              name,
              data
            })
          })

          // setup x axis from query 
          this.options.xaxis.min = moment(this.from).valueOf()
          this.ready = true                 

        } catch ( err ) {
          console.error(err)
        }

      } else {
        this.ready = false        
        this.series = []
      }

    }
  }
}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
