<template>
  <div class="no-results-view">
    <div class="row">
      <div class="col-md-12">
        <h3><b>Oops! Looks like there's a problem with that link.</b></h3>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6">
        <h5><b>Common Problems:</b></h5>
        <ul class="no-results-list">
          <li v-for="(row,idx) in msgs" :key="idx">
            {{row.p}}
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <h5><b>Try:</b></h5>
        <ul class="no-results-list">
          <li v-for="(row,idx) in msgs" :key="idx" v-if="row.s">
            {{row.s}}
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="float-right" @click="back()">Go Back</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResultsUrl',
  data() {
    return {
      msgs: [{
        p:'The link is broken.',
        s:`Copy and past the full link again - don't just type it in manually.`
      },{
        p:'The link is behind a paywall/not public and requires a subscription or login.',
        s:'Highlight the text on the page and copy and paste that instead of the link.'
      },{
        p:'There are not enough characters. There must be a mimimum of 1,000 characters.',
        s:''
      },{
        p:'The text is embedded on images rather than plain text.',
        s:''
      }]
    }
  },
  methods: {
    back() {
      history.go(-1)
    }
  }
}
</script>

<style lang="scss" >



</style>
