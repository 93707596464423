<template>
  <div class="writing-tip" v-if="tip">
    <h4>
        <img src="@/assets/tip.png" height="60px"/>
        Writing Tip!
    </h4>
    <p>{{ tip }}</p>      
    <!--
    <p class="next">
        <small>
            <a @click="nextTip()" class="float-right">Next Tip</a>
        </small>
    </p>
    -->
  </div>
</template>

<style lang="scss" >
.writing-tip {

    padding: 15px;
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 10px;
    box-shadow: 2px 2px 2px lightgrey;

    img {
        margin-right: 0px;
    }

    h4 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px;
    }

    p {
        padding-left: 5px;
    }

    .next {
        margin-bottom: 30px!important;

        a {
            color: 008CFF;
            cursor: hand;
        }
    }
}
</style>


<script>

import WritingTips from '@/services/writing-tips'

export default {
  name: 'Tips',
  data() {
    return {
        tip:''
    }
  },
  async created() {
    this.nextTip()
  },
  methods: {
    async nextTip() {
        let dashboardId = this.$router.currentRoute.params.dashboardId
        this.tip = await WritingTips.randomTip(dashboardId)
    }
  }
}
</script>

