<template>
  <div v-if="ready">
    <div class="row text-left summary-section" v-for="summary in populated">
        <div class="col summary-header">
            <b><i :class="summary.type|channelIcon" /> <span>Top Posts for {{ summary.name }}</span></b>
        </div>
        <b-list-group class="summary-list">
            <b-list-group-item class="summary-item" v-for="post in summary.highlights">
                <div class="summary-item">
                    <div class="summary-post">
                        <div class="summary-post-header">
                            <img :src="post.channel_meta.logo" width="35" />
                            <b class="summary-author">{{ summary.name }}</b>
                            <p class="summary-content">{{ post.content }}</p>

                            <div class="attachments">

                                <span class="list-view-attachment" v-for="attachment in attachments(post)">
                                    <span v-if="attachment.mimetype.startsWith('image/')" >
                                    <img fluid :src="attachment|thumbnail(policy)" height="100px" >
                                    </span> 
                                    <span v-if="attachment.mimetype.startsWith('audio') || attachment.mimetype.startsWith('video')">
                                    <div :class="attachment.mimetype.startsWith('audio')? 'audio-mask':'video-mask'">
                                        <video controls>
                                        <source :src="attachment|preview(policy)" :type="attachment.mimetype.startsWith('audio')? 'audio/mp3':'video/mp4'" />
                                        </video>
                                    </div>
                                    <p v-if="attachment.clip && attachment.clip.tStart && attachment.status !== 'completed' && attachment.status !== 'failed' && attachment.status !== 'ready'" class="text-info">
                                        <span>{{attachment|attachmentStatus|titlecase}} <i class="fa fa-yin-yang fa-spin" /></span>
                                    </p>
                                    <p v-if="attachment.status === 'failed'" class="text-danger errors">
                                        <span> {{attachment|attachmentStatus|titlecase}}  <a href="_" @click="onAction('reprocess-video',$event)" class="text-success">RETRY</A></span>
                                    </p>
                                    </span>
                                </span>                  
                            
                            </div>
                            
                        </div>
                    </div>
                    <div class="summary-analytics">
                      <p>
                        <i>Published {{ post.publishAt | date('YYYY-MM-DD h:mm a') }}</i>
                        <i class="float-right" :class="summary.type|channelIcon" />
                      </p>
                      <table class="table small">
                        <tr v-if="post.post_meta.reach">
                          <td>{{ post.post_meta.reach | magnitude }}</td>
                          <td>Reach (at least)</td>
                        </tr>
                        <tr v-if="post.post_meta.impressions">
                          <td>{{ post.post_meta.impressions }}</td>
                          <td>Impressions</td>
                        </tr>
                        <tr v-if="post.post_meta.sessions">
                          <td>{{ post.post_meta.sessions || 0 }}</td>
                          <td>Engagements</td>
                        </tr>
                        <tr v-if="post.contentType === 'linkedin' && !post.post_meta.sessions">
                          <td>{{((post.post_meta.like_count||0)+(post.post_meta.likeCount||0)+(post.post_meta.share_count||0)+(post.post_meta.shareCount||0)) | magnitude(1) }}</td>
                          <td>Engagements</td>
                        </tr>                        
                        <tr v-if="post.post_meta.share_count">
                          <td>{{ post.post_meta.share_count || 0 }}</td>
                          <td>Shares</td>
                        </tr>
                        <tr v-if="post.post_meta.comment_count">
                          <td>{{ post.post_meta.comment_count || 0 }}</td>
                          <td>Comments</td>
                        </tr>
                        <tr v-if="post.post_meta.retweet_count">
                          <td>{{ post.post_meta.retweet_count || 0 }}</td>
                          <td>Retweets</td>
                        </tr>                            
                        <tr v-if="post.post_meta.like_count">
                          <td>{{ post.post_meta.like_count || 0 }}</td>
                          <td>Likes</td>
                        </tr>                                                                                                                        
                      </table>
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>    
  </div>
</template>

<style lang="scss" >
.summary-section {
    margin-bottom: 20px;
    .summary-header {
        padding: 10px
    }
    .summary-item {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width: 100%;
    }
    .summary-post {
      width: 50%;
      .summary-author {
        margin-left:25px;
      }
      p {
        margin:15px 15px 0px 60px;
      }
    }
    .summary-analytics {
      width: 50%;
      table {
        tr {
          height: 0.5em;
        }
      }
    }    

    .attachments {
        margin: 15px 0px 0px 60px;
    }
}
</style>


<script>

import { getters, actions } from '@/services/store'

import ListItem from '@/components/Content/ListView/ListItem'

export default {
  name: 'TopPosts',
  data() {
    return {
        summaries:undefined,
        dashboard:undefined,
        policy: false,
        ready: false,        
    }
  },
  props: {
    from: String,
    stats: {
      type: Object,
    }
  },  
  async created() {
    this.dashboard = getters.dashboard()
    this.policy = await actions.fetchPolicy()
  },
  methods: {
    attachments(post) {
        return post.attachments.filter((a)=>{
            return a.mimetype
        }).map((a)=>{
            return {
                mimetype: a.url.includes.mp4? 'video/mp4' : a.mimetype,
                url: a.url
            }
        })
    }
  },
  watch: {
    stats() {
        if ( this.stats ) {
        this.summaries = this.stats.summaries
        this.ready = true
        } else {
            this.ready = false
            this.summaries = undefined
        }
    }
  },
  computed: {
    populated() {
      return this.summaries.filter((s)=>{
        return s.highlights.length
      })
    }
  },
  components: {
    ListItem
  }
}
</script>

