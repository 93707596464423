
<template>

    <div class="tag-input">

      <div v-for="(chip,idx) in chips" :key="(chip._id||idx)" class="tag-input__tag" v-b-tooltip.hover :title="tooltip(chip)">

        <!-- chip content -->
        <span v-if="!chip.mimetype">{{ format(chip) }}</span>
        <span v-if="chip.mimetype && !chip.mimetype.startsWith('image')">{{ chip.filename }}</span>
        <span class="attachment-thumbnail" v-if="chip.mimetype && chip.mimetype.startsWith('image')" >
          <b-avatar square size="72px" :src="chip|thumbnail(policy)"></b-avatar>
        </span>

        <!-- close menu -->
        <span @click="onRemove(chip,idx)" v-if="editable" class="chip-close fa fa-times"></span>

      </div>

      <!-- input text -->
      <input v-if="editable"
          type='text'
          :placeholder="prompt"
          class='tag-input__text'
          @keydown.enter='addChip'
          @keydown.188='addChip'
          @keydown.delete='removeLast'
      />

    </div>

</template>

<script>

'use strict'

import { store, actions } from '@/services/store'

export default {

  name: 'ChipEditor',

  props: {
    chips: {
      type: Array,
      required:true
    },
    label: {
        type: String,
    },
    prompt: {
      type: String,
    },
    editable: {
        type: Boolean,
        default: false
    },
    onRemove: {
        type: Function,
    },
    onCreate: {
        type: Function,
        default: ()=>{}
    },
    format: {
      type: Function,
      default: (msg)=>msg
    },
    policy: {
      type: Object
    },
    tooltip: {
      type: Function,
      default: (chip)=>{return ``}
    }
  },

  methods: {

    async addChip(event) {
        event.preventDefault()
        var chip = event.target.value.trim()
        event.target.value = '';
        this.onCreate(chip);
    },

    async removeLast() {
      const last = this.chips.pop()
      await this.onRemove(last,this.chips.length)
    }

  }

}
</script>

<style lang="scss" >

 .tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 1.1em;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .tag-input__tag {
    min-height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
  }

  .tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .tag-input__text {
    border: none;
    outline: none;
    font-size: 1.1em;
    line-height: 50px;
    background: none;
  }

  .chip-close {
    padding:2px;
  }

</style>
