<template>

  <div class="container-fluid drafts-view nomargin nopad">
    <Content :config="config" />
  </div>

</template>

<script>

import Content from '@/components/Content'
import { getters } from '@/services/store'

export default {

  name: 'Calendar',

  data() {
    return {
      config: {
        header:{
          title: 'Calendar',
          refreshTitle: "", 
          views: [{
            id:'list',
            title:'List'
          },{
            id:'calendar',
            title:'Calendar'
          }]
        },
        actions: [{
          id:'remove',
          partner: '*',
          float:'right',
          title: {
            prefix: 'CLEAR',
            suffix: 'POSTS',
          },
          variant:'outline-secondary'
        }],
        filter: {      
          channels: {
            value:'*',
            editable:true
          },
          status:{
            value: (getters.partner() === 'lately'? 'P,T,S,F' : 'P,T,F'),
            editable:true
          }
        }
      }
    }
  },

  created() {
    this.config.filter.status.initial = this.$route.query.status || ''
  },

  components: {
    Content
  }

}
</script>

