<template>
  <div class="time-of-day">
    <b-form-select
      v-model="model.hour"
      :options="hours"
    ></b-form-select>
    :
    <b-form-select
      v-model="model.minute"
      :options="minutes"
    ></b-form-select>
    <b-form-select class="meridian"
      v-model="model.meridian"
      :options="meridians"
    ></b-form-select>
  </div>
</template>

<script>

import moment from 'moment'

function populate(list,min,max,pad) {
  for ( var i = min; i <= max; i++ ) {
    if ( pad && i.toString().length==1 ) {
      list.push('0'+i)
    } else list.push(i.toString())
  } return list;
}

export default {
  name: 'HelloWorld',
  data() {
    return {
      meridians: ['AM','PM'],
      hours: populate([],1,12),
      minutes: populate([],0,59,true)
    }
  },
  props: {
    model: {
      type: Object
    }
  }
}
</script>

<style lang="scss" >
.time-of-day {
  select {
    display: inline;
    width: 76px;
  }
  select.meridian {
    padding-left: 10px;
    margin-left: 10px;
  }
}
</style>
