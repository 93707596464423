<template>
  <div class="container-fluid">
    <div class="row admin-analytics">
      <div class="col-md-12 header">
        <h3>Analytics
          <small>
            <b-form inline class="float-right">
              <div class="search">
                <b-input-group>
                  <b-form-input type="search" v-model="search" placeholder="Search" ></b-form-input>
                </b-input-group>
              </div>
              <div class="date">
                <b-form-datepicker id="to-datepicker" v-model="formatted" class="mb-2"></b-form-datepicker>
              </div>
            </b-form>
          </small>
        </h3>
        <hr>
        <b-tabs pills >
          <b-tab title="Users" lazy>
            <Users active :date="date" :search="search" />
          </b-tab>
          <b-tab title="Sources" lazy>
            <Sources active :date="date" :search="search" />
          </b-tab>
          <b-tab title="Content" lazy>
            <Content :date="date"  :search="search" />
          </b-tab>
          <b-tab title="Publisher" lazy>
            <Publisher :date="date"  :search="search" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>

'use strict'

import Publisher from './Publisher';
import Content from './Content';
import Sources from './Sources';
import Users from './Users';

import { actions } from '@/services/store';
import moment from 'moment'

export default {
  name: 'AdminPanel',
  data() {
    return {
      formatted: new Date(),
      date: new Date(),
      search:''
    }
  },
  components: {
    Content,
    Publisher,
    Sources,
    Users
  },
  watch: {
    formatted() {
      this.date = moment(this.formatted,'YYYY-MM-DD').toDate()
    }
  }
}
</script>

<style lang="scss" >
.admin-analytics {

  .header {
    span {
      font-size: 24px;
      margin-left: 5px;
    }
  }

  .search {
    margin: -8px 10px 0px 10px;
  }

  .date {

  }

}
</style>
