<template>

  <div class="content-analytics">
    <h4>{{stats.all}} Posts
      <small class="float-right">
        {{stats.edited}} edited, {{stats.drafts}} draft
      </small>
    </h4>
    <b-table striped hover :items="users" :fields="fields" :filter="search" />
  </div>

</template>

<script>

import { actions } from '@/services/store'
import moment from 'moment'

export default {

  name: 'UserAnalytics',
  data() {
    return {
      users:[],
      stats:{},
      fields:[{
        key:'fullname',
        label:'Name',
        sortable:true
      },{
        key:'email',
        label:'Email',
        sortable:true
      },{
        key:'stats.drafts',
        label:'Drafts',
        sortable:true
      },{
        key:'stats.edited',
        label:'Edited',
        sortable:true
      },{
        key:'stats.all',
        label:'Total',
        sortable:true
      }]
    }
  },

  props: {
    date: Date,
    search: String
  },

  async created() {
    this.query()
  },

  methods: {
    async query() {
      try {
        const date = moment(this.date)
        const resp = await actions.fetchAdminAnalytics('content',this.date,this.date)
        this.users = resp.users
        this.stats = resp.stats
      } catch(err) {
        console.error(err)
        this.$toasted.error('Unable to fetch Content analytics')
      }
    }
  },

  watch: {
    date() {
      this.query()
    }
  }
}
</script>

<style lang="scss" >

.content-analytics {
  h4 {
    margin: 20px 0px 20px 0px;
  }
}
</style>
