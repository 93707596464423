<template>
  <div class="no-results-view">
    <div class="row">
      <div class="col-md-12">
        <h3><b>Oops! Looks like there's a problem with that video.</b></h3>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6">
        <h5><b>Common Problems:</b></h5>
        <ul class="no-results-list">
          <li v-for="(row,idx) in msgs" :key="idx">
            {{row.p}}
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <h5><b>Try:</b></h5>
        <ul class="no-results-list">
          <li v-for="(row,idx) in msgs" :key="idx" v-if="row.s">
            {{row.s}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResultsVideo',
  data() {
    return {
      msgs: [{
        p:`The video doesn't contain any dialogue.`,
        s:`Check to make sure the video has clear, undistorted dialogue.`
      },{
        p:`The dialogue is muffled and couldn't be transcribed.`,
        s:`If the dialogue is clear, try running it thru the generator again.`
      },{
        p:`Music in the video is competing with the dialogue.`,
        s:''
      }]
    }
  }
}
</script>

<style lang="scss" >

</style>
