<template>
  <div class="default-page-wrapper" v-if="store.initialized">
    <default-nav class="nav"/>
    <div class="slot">
      <slot />
      <div class="help-container" v-if="($mq === 'lg'||$mq==='xl') && showHelp">
        <Help class="help" />
      </div>            
    </div>
    <default-footer />
  </div>
</template>

<script>

import DefaultFooter from './DefaultFooter'
import DefaultNav from './DefaultNav'
import Help from '@/components/Help'
import Vue from 'vue'

import Onboarding from '@/services/onboarding'
import { store, events } from '@/services/store'

export default {
  data() {
    return {
      store,
      showHelp: ''
    }
  },
  created() {
    events.$on('help',()=>{
      this.showHelp = ''
      Vue.nextTick(()=>{
        this.showHelp = Onboarding.getHelpText()
      },100)
    })

  },
  components: {
    Help,
    DefaultNav,
    DefaultFooter
  }
}
</script>

<style lang="scss">
/* Sticky footer */
html {
  position: relative;
  min-height: 100%;
}
body {
 margin-bottom: 60px; 
}
.default-page-wrapper {
  padding:  0px; // // em 0em 3em 0em;
 .nav {
   height: 15px;
 }
 .slot {
  height: 100vh;

 }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0px; /* Footer height */
  line-height: 60px; /* Vertically center the text */
  background-color: $gray-100;
  text-align: center;
}
</style>
