<template>

  <div class="generate-from-media">

    <div class="row nomargin nopad" v-if="model">

        <!-- source selection -->
        <div class="col-md-7 source">

          <h3 class="title"><strong>Add {{session.config.actualSource|titlecase}} and Link</strong></h3>
          <p class="description">Upload your {{session.config.actualSource}} and add a link you want people to click in your results.</p>

            <!-- source selection  -->
            <div class="row select" v-if="!model.source">
              <div class="col-md-12">
                <div class="text-center">
                  <b-button variant="primary" @click="uploadFile($event,'source')" >UPLOAD {{session.config.actualSource|upperCase}}</b-button>
                </div>
              </div>
            </div>

            <!-- source preview -->
            <div class="row" v-else >

              <div class="col-md-7 text-left preview" >
                <div class="media">
                  <MediaView :policy="policy" :source="model.source" :links="model.links" :actions="[{source:'source',label:'Remove',action:'remove'}]" @on-media-action="onMediaAction" />
                </div>
              </div>

              <div class="col-md-5 actions">

                <LanguagePicker :model="model" @on-language-selected="onLanguageSelected" />

                <!--
                <div class="d-flex justify-content-between video-caption-switch" v-if="includes('video-caption-edit')">
                  <span>Edit transcript before generating:</span>
                  <b-form-checkbox size="md" v-model="model.editTranscript" name="check-button" switch></b-form-checkbox>
                </div>

                <p v-if="includes('video-caption-edit')">
                  <label>Edit transcript before generating</label>
                  <b-form-checkbox class="video-caption-switch float-right" size="md" v-model="model.editTranscript" name="check-button" switch></b-form-checkbox>
                </p>
                -->

                <div class="d-flex justify-content-between video-caption-switch">
                  <span>Include Captions:</span>
                  <b-form-checkbox size="md" v-model="model.caption" name="check-button" switch></b-form-checkbox>
                </div>

                <p class="text-center">
                  <b-button @click="submit()" class="generate" :disabled="!model.valid">GENERATE</b-button>              
                </p>

              </div>

            </div>

            <div class="text-left link">
              <label>Link URL</label>
              <b-input block placeholder="Link URL" v-model="links.links.all"></b-input>
              <p class="text-danger" v-if="!links.valid"><small>Please enter a valid link</small></p>
            </div>

        </div>

        <!-- intros outros -->
        <div class="col-md-5 tros">
          <div class="content">
            <h3>Add optional Intros and Outros</h3>
            <p>Upload an image or video to the front and/or back of each generated video clip, like your company logo, a sponsor promo or a disclaimer. </p>
            <div class="row">
              <div class="col-md-6 text-center">
                <b-button v-if="!model.intro" variant="outline-primary" @click="uploadFile($event,'intro',['image','video'])">UPLOAD INTRO</b-button>
                <MediaView v-else :policy="policy" :source="model.intro" :actions="[{source:'intro',label:'Remove',action:'remove'}]" @on-media-action="onMediaAction" />
              </div>
              <div class="col-md-6 text-center">
                <b-button v-if="!model.outro" variant="outline-primary" @click="uploadFile($event,'outro',['image','video'])">UPLOAD OUTRO</b-button>
                <MediaView v-else :policy="policy" :source="model.outro" :actions="[{source:'outro',label:'Remove',action:'remove'}]" @on-media-action="onMediaAction" />
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>

import LanguagePicker from '@/components/LanguagePicker'
import MediaView from '@/components/MediaView'

import { includes } from '@/services/product'
import { actions } from '@/services/store'
import { picker } from '@/services/files'

import * as linkify from 'linkifyjs'

export default {

  name: 'GenerateFromMedia',

  data() {
    return {
      busy: false,
      sourceType: '',
      includes: includes,
      allowCaptions: false,
      validLink: true,
    }
  },

  props: {
    session: {
      type: Object,
      required:true
    },
    model: {
      type: Object,
      required:true
    },
    links: {
      type: Object,
      required:true
    },
    policy: {
      type: Object,
      required:true
    }
  },

  watch: {
    'links.all' () {
      const matches = linkify.find(this.links.all).filter((m)=>{return m.type === 'url'})
      this.links.valid = matches.length
      this.validLink = this.links.valid
    }
  },

  components: {
    LanguagePicker,
    MediaView
  },

  created() {
    this.allowCaptions = includes('video-caption-add')
    document.body.style.backgroundColor = '#F7F7F7';
  },

  methods: {

    submit() {
      this.busy = true
      this.$emit('on-generate')
    },

    onMediaAction(evt) {
      switch( evt.action ) {
        case 'remove': this.remove(evt.source)
      }
    },

    remove(aspect) {
      this.model[aspect] = false
    },

    onLanguageSelected(language) {
      this.model.language = language
    },

    async uploadFile(ev,aspect,accept) {

      ev.stopPropagation()
      ev.preventDefault()

      accept = accept? accept : [this.session.config.actualSource]
      accept = accept.map((key)=>{return `${key}/*`})

      // default config goes to cdnlately-v3 bucket
      const options = {
        fromSources: ['local_file_system','googledrive'],
        maxSize: 500 * 1024 * 1024,
        accept: accept,
        minFiles: 1,
        maxFiles: 1,
      }

      const results = await picker.pickFromCloud( this.session, options, this.policy )
      //console.log('picked',JSON.stringify(results,0,1))

      this.model[aspect] = results.filesUploaded.length? results.filesUploaded[0]:false
      this.model.valid = (this.model.source!=null)
      await actions.updateSession(this.session)

    }

  }
}
</script>

<style lang="scss" >

.generate-from-media {

  background-color: #F7F7F7;
  font-family: Noto sans;
  position: relative;
  top: -40px;
  h3 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 16px;
  }

  .source {

    box-shadow: 2px 2px 2px lightgrey;
    border: 1px solid lightgrey;
    background-color: white;
    border-radius: 10px;
    padding: 20px;

    .select {
      background-color: #f5f5f5;
      color: grey;
      padding: 60px;
      margin: 10px 0px 0px 0px;
      border-radius: 10px;
      button {
        padding: 10px 40px 10px 40px;
      }
    }

    .preview {

      background-color: white;

      input {
        background-color: lightgrey;
        position: relative;
        top: -5px;
      }

    }

    .actions {

      .video-caption-switch {
        margin-top: 5px;
        span {
          font-size: 14px;
        }
        border: 0px solid lightgrey!important;
        margin-right:0px;
      }

      button.generate {
        margin-top: 20px;
        border: 0px;
        width: 150px;
        border-radius: 20px;
        background-color: #20C763;
      }

    }

    .link {
      margin-top: 20px;
      label {
        font-size: 16px;
        font-weight: bold;
      }
      input {
        background-color: #f5f5f5;
        box-shadow: 2px 2px 2px lightgrey;
      }
    }

  }

  .tros .content {
    background-color: white;
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    box-shadow: 2px 2px 2px lightgrey;
    button {
      color: #20C763;
      width: 170px;
      padding: 10px 20px 10px 20px;
      margin: 5px;
    }

    button:hover {
      color:white;
    }
  }
}
</style>
