<template>
    <div class="onboarding-generator">
        <Generator mode="onboard" @on-action="onAction" />
    </div>
</template>

<script>

import Generator from '@/components/Generator/v2'

export default {
  name: 'onboarding-generate',
  methods: {
    next() {
      this.$emit('onboarding-next',1)
    }
  },
  props: {
    sessionId:{
        type:String,
        default:''
    }
  },
  components: {
    Generator
  },
  methods: {
    onAction(action) {
      this.$emit('on-action',action)
    }
  }
}
</script>

<style lang="scss" >

.onboarding-generator {
    margin-top: 30px;
}

</style>
