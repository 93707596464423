
<template>

  <div class="v2-addon-plan" v-bind:class="{ selected:plan.selected }" @click="select()">

    <!-- derived from Stripe -->
    <div class="header" v-if="plan.id!=='enterprise'">

      <h2 class="name">
        {{plan.name}}
        <span class="float-right" v-if="!plan.demo">
          <i :class="plan.selected? 'fas fa-check-circle checked':'far fa-check-circle'" />
        </span>
      </h2>

      <div class="row demo-link" v-if="sections.link && sections.link.length">
        <div class="col">
          <a target="new" :href="sections.link[0]">
            <i class="fas fa-play-circle" /> Watch {{plan.name}} in action
          </a>
        </div>
      </div>

      <h2 class="price" >
        ${{price()}}/mo
      </h2>

      <p class="description" v-if="sections.description && sections.description.length">{{sections.description[0]}}</p>
      <!--
      <p class="trial">7 Day Free Trial!</p>
      -->

    </div>

    <!-- specifically for Enterprise, not in stripe -->
    <div class="header enterprise" v-else>

      <h2 class="name">
        {{plan.name}}
      </h2>

      <div class="cta text-center" >
        <b-button @click="open('https://www.lately.ai/demo')" variant="success">Request Demo</b-button>
      </div>

    </div>

    <!-- plan features -->
    <div class="addon-plan-footer">
      <div class="row feature" v-for="(feature,idx) in sections.features" :key="feature">
        <div class="col-md-1" v-if="true || idx">
          <i class="text-success fa fa-fw fa-check-circle" />
        </div>
        <div class="col-md-10" v-if="true || idx">
          <small class="plan-includes" v-html="feature"></small>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import { getters } from '@/services/store';

export default {

  name: 'PlanInfo',

  data() {
    return {
      title:'',
      prices:false,
      user:false,
      sections:[],
    }
  },
  props: {
    plan: {
      type: Object,
      default: ''
    },
    cycle: {
      type: String,
      default: 'month'
    }
  },
  created() {

    /**
    plan description encoded as follows

    (description)
    text

    (features)
    title
    text
    ..
    text

    (publishers)
    title
    text
    ..
    text

    (link)
    href
    **/

    this.user = getters.user()
    this.prices = this.plan.prices.reduce((acc,price)=>{
      if (price.active) {
        acc[price.interval] = price
      }
      return acc
    },{})
    this.title = this.plan.title
    let frags = this.plan.description.split('\n')

    // parse description into sections
    this.sections = frags.reduce((acc,line)=>{
      if ( line.startsWith('(') && line.endsWith(')') ) {
        acc.push({
          id:line.substring(1,line.length-1),
          content:[]
        })
      } else if ( line.trim().length ) {
        const s = acc[acc.length-1]
        if ( s ) {
          s.content.push(line)
        }
      }
      return acc
    },[]).reduce((acc,e)=>{
      acc[e.id] = e.content
      return acc
    },{})

    this.isIframe = getters.isIframe('hootsuite')

  },

  methods: {
    open(url) {
      window.open(url)
    },
    price() {
      if ( this.cycle === 'year' && this.prices[this.cycle] ) {
        return Number(this.prices[this.cycle].amount / 12).toLocaleString()
      } else if ( this.prices[this.cycle] ) {
        return Number(this.prices[this.cycle].amount).toLocaleString()
      } else return ''
    },
    select() {
      if ( !this.plan.demo ) {
        this.$emit('plan-selected', this.plan)
      }
    }
  },
  components: {
    ChannelIcon
  }
}
</script>

<style lang="scss" >

 .v2-addon-plan {

    background-color: white;
    border: 3px solid #E6E6E6;
    border-radius: 10px;
    min-height: 700px;
    padding: 20px;

  .header {

    margin-bottom: 8px;

    h2.name {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .price {
      font-size: 32px;
      font-weight: bolder;
      margin-bottom: 8px;
    }

    .demo-link {
      margin-bottom: 20px;
      i {
        color: #F65756
      }
      a {
        font-size: 16px;
        font-weight: regular;
        color: #F65756
      }
    }

    p.description {
      font-size: 16px;
      font-weight: regular;
      margin-bottom: 8px;
    }

    p.trial {
      font-size: 16px;
      font-weight: regular;
      font-style: italic;
      margin-bottom: 0px;
    }

  }

  .header.enterprise {
    margin-bottom: 15px!important;    
    .cta {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }        

  .addon-plan-footer {

    background:white;

    margin-top: 30px;
    min-height: 275px;

    .plan-subtext {
      font-size: 16px;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .plan-includes {
      font-size: 16px;
      font-weight: medium;
      margin-bottom: 12px;
    }

  }

  i {
    color: #E6E6E6
  }

}

.v2-addon-plan.selected {
  border: 3px solid #008CFF;
  i {
    color: #008CFF
  }
}


</style>
