
import { actions } from "@/services/store.js";
import moment from 'moment'

const sha512 = require('js-sha512').sha512;

const SECRET = process.env.VUE_APP_HOOTSUITE_IFRAME_SECRET

let initialized = false

// HootSuite SDK
class Hootsuite {

  static initialized = false

  static async init() {

    return new Promise((resolve,reject)=>{

      if ( initialized ) return resolve()

      if ( typeof hsp === 'undefined' ) {

        const script = document.createElement("script");
        const src = 'https://assets.hootsuite.com/jsapi/4-1/hsp.js'
        script.setAttribute("src", src);
        document.getElementsByTagName("head")[0].appendChild(script);

        const doInit = () => {

          if ( typeof hsp !== 'undefined') {
            hsp.init({
              callback: async (data)=>{
                const member = await Hootsuite.getMemberInfo()
                initialized=true
                resolve()
              }
            })
          } else {
            setTimeout( doInit, 500 )
          }
        }

        setTimeout( doInit, 1000 )

      } else {
        hsp.init({
          callback: async (data)=>{
            const member = await Hootsuite.getMemberInfo()
            initialized=true;
            resolve()
          }
        })
      }

    })

  }

  static async attachMediaToMessage(post) {
    await Hootsuite.init()
    const member = await Hootsuite.getMemberInfo()
    post.attachments.forEach(async (attachment,idx)=>{
      if ( attachment.mimetype.startsWith('video') ) {
        await Hootsuite.attachMedia(member,attachment)
      } else {
        await Hootsuite.attachFileToMessage(member,post,idx)
      }
    })
  }

  // for video content - server side upload then attach
  static async attachMedia(member,attachment) {

    await Hootsuite.init()

    // compose an access token
    const mediaId = attachment.partnerInfo.mediaId
    const timestamp = moment().unix()
    const token = `${member.userId}${timestamp}${mediaId}${SECRET}`

    const args = {
      mediaId: mediaId,
      token:sha512(token),
      timestamp:timestamp,
    }

    hsp.attachMediaV2(args)

  }

  // for images or files - make attachment public then attach
  static async attachFileToMessage(member,post,idx) {

    // make the url public
    const url = await actions.attachmentToPublicUrl(post.dashboard,post._id,idx)

    // compose a token
    const attachment = post.attachments[idx]
    const timestamp = moment().unix()
    const token = `${member.userId}${timestamp}${url}${SECRET}`

    const args = {
      url:url,
      name:attachment.filename,
      extension:attachment.filename.split('.').pop(),
      timestamp:timestamp,
      token: sha512(token)
    }

    hsp.attachFileToMessageV2(args)

  }

  static composeMessage(msg) {
    return new Promise((resolve,reject)=>{
      const resp = hsp.composeMessageV2( msg, {}, function() {
          // console.log('composeMessage callback',arguments)
      })
      resolve(resp)
    })
  }

  static fetchTwitterAccounts() {
    return new Promise((resolve,reject)=>{
      hsp.getTwitterAccounts((data)=>{
        resolve(data)
      },(err)=>{
        console.error('fetchTwitterAccounts failed',err)
        reject(err)
      })
    })
  }

  static fetchAuth() {
    return new Promise((resolve,reject)=>{
      hsp.getAuth((data)=>{
        resolve(data)
      },(err)=>{
        console.error('fetchAuth failed',err)
        reject(err)
      })
    })
  }

  static getMemberInfo() {
    return new Promise((resolve,reject)=>{
      hsp.getMemberInfo((data)=>{
        resolve(data)
      },(err)=>{
        console.error('getMemberInfo failed',err)
        reject(err)
      })
    })
  }

  /**
   * attempts to use getAuth() token server
   * side to retrieve social channels
   * **/
  static async syncSocial(dashboardId) {

    console.log('syncSocial')

    // ensure we're initialized
    await Hootsuite.init()

    const member = await Hootsuite.getMemberInfo()

    const auth = await Hootsuite.fetchAuth()

    const args = { member, auth }

    // sync channels
    await actions.syncHootsuiteSocial( dashboardId, args )

    // refresh dashboard
    // await actions.fetchDashboard(dashboardId)

  }

  static async composeAndAttachFor(post) {

    window.scrollTo(0,0);

    await Hootsuite.init()

    // upload attachments where necessary
    await Hootsuite.uploadIfNecessary(post)

    // open compose dialog with content
    await Hootsuite.composeMessage(post.content)

    // attach media to composer
    if ( post.attachments.length ) {
      await Hootsuite.attachMediaToMessage(post)
    }

    // and remove content
    // this.traverse(1,true)

  }

  static async uploadIfNecessary(post) {

    return new Promise( async (resolve,reject)=>{

      const filterPending = (attachments) => {
        return attachments.filter((a)=>{ return a.mimetype && a.mimetype.startsWith('video') && (!a.partnerInfo || !a.partnerInfo.mediaId) })
      }

      const poll = async ()=>{

        // fetch post with just attachments, find pending files
        const resp = await actions.fetchPost( post.dashboard, post, 'attachments' )
        let pending = filterPending((resp.attachments||[]))

        // poll while incomplete
        if ( pending.length ) {
          setTimeout(poll,5000)
        } else {
          post.attachments = resp.attachments
          resolve()
        }

      }

      try {

        // fetch member info for auth
        await Hootsuite.init()
        const member = await Hootsuite.getMemberInfo()

        // upload job also tracks analytics so submit unconditionally
        await actions.uploadTo( post.dashboard, post._id, 'hootsuite', member.userId )

        // await results
        poll()

      } catch( err ) {
        console.error(err.message)
      }

    })

  }

}

export default Hootsuite
