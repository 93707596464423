<template>

	<div class="row">
		<div class="col-md-12">
			<div class="row mt-5">
				<div class="col-md-12 ">

          <!-- voice picker modal -->
          <b-modal body-class="voice-picker" v-model="showVoicePicker">

            <template #modal-header="{}">
              <div class="voice-picker-header">
                <h3>What tone would you like your generated posts to be in?</h3>              
              </div>
            </template>


            <div class="voice-options">
              <p v-for="voice in voices" @click="selectedVoice=voice.id">
                <i :class="voice.id===selectedVoice? 'far fa-dot-circle':'far fa-circle'" /> {{voice.text}}
              </p>
            </div>

            <template #modal-footer="{ }">
              <div class="voice-picker-footer text-right">        
                <b-button @click="showVoicePicker=false">Cancel</b-button>
                <b-button @click="nav(1)" variant="primary">Generate</b-button>                
              </div>
            </template>

          </b-modal>          
					<Transition name="fade" mode="out-in">
            <GeneratorProgress v-if="session.config.step==='generating'" :session="session" :nav="nav" />
            <GenerateFromMedia v-else-if="session.config.source!=='text'" :session="session" @on-generate="onGenerate" :model="session.config.steps.source" :links="session.config.steps.links" :policy="policy" />
            <GenerateFromText v-else :session="session" @on-text="onText" @on-generate="onGenerate"/>
					</Transition>
				</div>
			</div>
		</div>
	</div>

</template>

<style lang="scss">

.voice-picker-header {
  padding: 10px!important;
}

.voice-picker {

  padding: 20px 40px 20px 40px;

  .voice-options {
    i {
      margin-right: 10px;
    }
  }

}

.voice-picker-footer {
  button {
    margin-left: 10px!important;
  }
}

.generate-header {
	width:  100vw;
	background-color:  #fff;
	position: fixed;
	top:  0;
	left: 0; /* ideally dynamically set this to gen header height */
  z-index: 1000;
}

.header-button {
	margin-left:10px;
	border-radius:0px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>


<script>

'use strict'

import GeneratorProgress from './GeneratorProgress'
import GenerateFromMedia from './GenerateFromMedia'
import GenerateFromText from './GenerateFromText'

import { includes } from '@/services/product'
import { actions } from '@/services/store'

export default {

  name: 'GeneratorWizard',

  // hide toolbar on mounted
  mounted () {
    // document.body.classList.add('no-nav')
  },

  data() {
    return {
      dashboard:false,
      generating:false,
      includes:includes,
      allowAdvanced:false,
      showVoicePicker:false,
      selectedVoice:'natural',
      voices: [{id:'natural',text:'My tone'},
      {id:'professional',text:'Official and professional'},
      {id:'conversational',text:'Relaxed and conversational'},
      {id:'humorous',text:'Light and humorous'},
      {id:'empathetic',text:'Personal, real and empathetic'},
      {id:'simple',text:'Simple words and simple structure'},
      {id:'academic',text:'Formal and academic'},
      {id:'creative',text:'Jazzy and creative'}]
    }
  },

  props: {
    session:{
      type:Object,
      required:true
    },
    policy:{
      type:Object,
      required:true
    }
  },

  created() {
    const isAdvanced = Object.keys(this.session.config.steps).reduce((acc,key)=>{
      const step = this.session.config.steps[key]
      if ( step.advanced ) acc.push(step)
      return acc
    },[])
    this.selectedVoice = this.session.tone || 'natural'
    this.allowAdvanced = isAdvanced.length && (includes('video-caption-add') || includes('video-intro-outro'))
  },

  computed: {
    canGenerate() {
      const invalid = Object.keys(this.session.config.steps).reduce((acc,key)=>{
        const step = this.session.config.steps[key]
        if ( !step.valid ) {
          acc.push(step)
        }
        return acc
      },[])
      return ( invalid.length === 0 )
    }
  },

	methods: {

    onGenerate() {
      this.nav(1)
    },

    onText(ev) {
      const source = this.session.config.steps.source    
      const links = this.session.config.steps.links  
      // console.log('onText',JSON.stringify(links,0,1))
      if ( ev.valid ) {
        source.type = ev.type
        if ( ev.type === 'url' ) {
          source.url = ev.text
        } else source.text = ev.text
        source.valid = true
        // source.links
      } else source.valid = false
      if ( ev.link ) {
        links.links.all = ev.link
      }
    },

    toggleShowAdvanced() {
      this.session.config.showAdvanced = !this.session.config.showAdvanced
    },

		async cancel() {
			if ( this.session.status.bucket === 'open' ) {
        await actions.removeSession(this.session)
      }
			this.$router.push(`/${this.session.dashboard}/sources`).catch(()=>{})
		},

    async nav(dir) {

      // console.log('nav',dir,JSON.stringify(this.session.config.steps.links,0,1))
      if ( dir < 0 ) return this.$router.back()

      // show tone selector only for non media sources
      let source = this.session.config.source
      let showPicker = (source !== 'video' && source !== 'audio')

      // set tone to natural for media sources      
      if ( !showPicker ) {
        this.selectedVoice = 'natural'
      }

      if ( showPicker && !this.showVoicePicker ) {
        this.showVoicePicker = true
      } else {
        this.showVoicePicker = false
        try {
          this.generating = true;
          this.session.tone = this.selectedVoice;               
          await actions.updateSession( this.session )
          await actions.generate( this.session, this.selectedVoice )
          this.$toasted.success(`Submitted!`)
          this.$router.push('/'+this.session.dashboard+'/results/'+this.session._id)
        } catch( err ) {
          console.error(err)
          this.$toasted.error(`Nope, that didn't work. We may be experiencing technical issues - please try again in a few minutes.`)
        }
      }

    }

	},

  components:{
    GenerateFromText,
    GenerateFromMedia,
    GeneratorProgress,
    GenerateFromText
  }

}

</script>

