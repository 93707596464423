<template>
  <b-form inline>
    <p v-if="!showInvite">
      <span class="fa-stack fa-lg circle-icon">
        <i class="fa fa-solid fa-circle fa-stack-2x stacked-blue"></i>
        <i class="fa fa-solid fa-inverse fa-stack-1x fa-plus" style="color:white;opacity:1.0;"></i>
      </span>
      <a href="#" @click="toggleInvite($event)" v-if="4-participants.length>0">Add a Team Member <small>({{4-participants.length}} remaining)</small></a>
      <span v-if="4-participants.length===0">To add more collaborators please contact lauren@lately.ai</span>
    </p>
    <p v-if="showInvite">
      <span class="fa-stack fa-lg circle-icon">
        <i class="fa fa-solid fa-circle fa-stack-2x stacked-purple"></i>
        <i class="fa fa-solid fa-inverse fa-stack-1x fa-envelope" style="color:white;opacity:1.0;"></i>
      </span>
      <span class="invite-actions">
        <b-input placeholder="Enter an email address" class="member-email" v-model="email" />
        <b-button size="sm" @click="toggleInvite($event,true)" variant="success" :disabled="!valid">Send Invite</b-button>
        <b-button size="sm" @click="toggleInvite($event)" variant="light">Cancel</b-button>
      </span>
    </p>
  </b-form>
</template>

<script>

import { actions, getters } from '@/services/store'
import * as linkify from 'linkifyjs'

export default {

  name: 'InviteForm',

  data() {
    return {
      email: '',
      valid:false,
      showInvite:false
    }
  },

  props: {
    participants: {
      type: Array,
      required:true
    }
  },

  watch: {
    email() {
      const matches = linkify.find(this.email).filter((m)=>{return m.type === 'email'})
      this.valid = (matches.length === 1 && matches[0].value === this.email.trim())
    }
  },

  methods: {

    async toggleInvite(ev,submit) {
      ev.stopPropagation()
      ev.preventDefault()
      try {
        if ( submit ) {
          const dashboard = getters.dashboard()
          const member = await actions.addMember(dashboard._id, { email: this.email })
          this.$emit('member-added', member)
        }
        this.showInvite = !this.showInvite
        this.email = ''
      } catch( err ) {
        console.error(err)
        this.$toasted.error(`${err.message}`)
      }
    }

  }

}
</script>

<style lang="scss" >

.stacked-blue {
  color: #0d6efd;
}

.stacked-purple {
  color: purple;
}

.circle-icon {
  transform: scale(0.7);
}

</style>
