<template>

  <b-container>
    <h3>Goodbye!</h3>
  </b-container>

</template>

<script>

  'use strict'

  export default {
    name: 'Close',
    created() {
      setTimeout(()=>{
        close()
      },100);
    }
  }
</script>
