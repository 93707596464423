<template>
  <tr>
    <td>
      <p>{{feature.label}}</p>
    </td>

    <!--
    <td>
      <textarea :disabled="!editing" class="form-control" type="number" v-model="feature.description"></textarea>
    </td>
    -->

    <td>
      <input v-if="feature.units.length" :disabled="!editing"  class="form-control" type="number" v-model="feature.limit" />
    </td>
    <td>
      <b-form-select v-if="feature.units.length" :disabled="!editing" :options="feature.units" text-field="val" value-field="key" class="form-control" type="text" v-model="feature.unit" />
    </td>

    <td>
      <b-button v-if="editing" variant="outline-primary" @click="onAction('remove')"><i class="fa fa-trash" /></b-button>
    </td>
  </tr>
</template>

<script>

import { features } from '@/services/product'

export default {

  name: 'Feature',

  props: {
    feature: {
      type: Object,
      default: ''
    },
    editing: {
      type: Boolean
    }
  },

  created() {
    this.feature.unit = this.feature.unit || ''
    this.feature.units = features[this.feature.id].units.slice()
  },

  methods: {
    onAction(id) {
      this.$emit('on-action',{
        id:id,
        kind:'feature',
        subject:this.feature
      })
    }
  }

}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
