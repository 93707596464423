

<template>

  <b-container class="vh-100">

    <b-row class="h-75" align-v="center" >

      <div class="col-md-6 offset-md-3">

        <h1>Welcome back to Lately!</h1>
        <p class="lead">Hi there, superstar. We missed ya.</p>

        <b-form @submit="login($event)">

          <b-form-group label="Email Address:" label-for="login_email">
            <b-form-input
              id="login_email"
              autocomplete="email"
              autofocus
              v-model="email"
              type="email"
              placeholder=""
              required
            ></b-form-input>

          </b-form-group>

          <b-form-group label="Password:" label-for="login_pasword">
            <b-form-input
              id="login_pasword"
              autocomplete="password"
              v-model="psswd"
              type="password"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>

          <div class="mt-4">
            <b-button :disabled="!email||!psswd" type="submit"  variant="primary">LOG IN</b-button>
            <b-button @click="resetPassword" type="button" variant="outline"><small>Forgot Password</small></b-button>
          </div>

          <br>
          <p class="mt-4 signup-link">
            <span>
              Don't have an account yet?
              <a href='#/app/signup'> Sign up now!</a>
            </span>
          </p>

          <p class="text-danger" v-if="msg" v-html="msg"></p>

        </b-form>

      </div>

    </b-row>

  </b-container>

</template>

<script>

  'use strict'

  import { store, actions, getters, setters } from "@/services/store.js";

  // todo - inline this into data
  const model = {
    showModal: true,
    email:'',
    psswd:'',
    msg:'',
  }

  export default {

    name: 'Login',

    data() {
      return model
    },

    async created() {
      setters.clear()
      model.email =  this.$route.query.email || ''
      model.psswd = ''
    },

    methods:{

      signup() {
        this.$router.push('/app/signup')
      },

      async login(ev) {

        if ( ev ) {
          ev.stopPropagation()
          ev.preventDefault()
        }

        model.msg = '';event

        // throws on failure or error
        actions.login(model)
        .then((resp)=>{

            const dashboard = getters.dashboard()
            if ( dashboard ) {
              this.$router.push( `/${store.dashboard._id}/onboarding`).catch(()=>{})
            } else this.signup()

          },(err)=>{

          console.error('login failed with', err)
          if ( err.status === 409 ) {
            model.msg = `Sorry, this is not a hootsuite connected account!
            If you'd like to switch your account to Hootsuite,
            please <a href="https://app.lately.ai/#/app/login" target="new">Login</a> and switch your Publisher to Hootsuite.`
          } else model.msg = 'Sorry, we were unable to identify you with that info'

        })

        return false

      },

      resetPassword() {
        this.$router.push('/app/password')
      },

      resetForm() {
        model.email='';
        model.psswd='';
        model.msg='';
      }
    }

  }
</script>

<style>

.signup-link {
  font-size:22px!important;
}

.signup-link a {
  margin-left:5px;
}

</style>
