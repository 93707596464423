<template>
  <b-overlay :show="busy" rounded="sm">
    <div class="row">
      <div class="col-md-12">

        <!-- Detail -->
        <div v-if="product">
          <Detail :product="product" @on-action="onAction" />
        </div>

        <!-- Master -->
        <div v-else>

          <div class="col-md-12 admin-section-heading">
            <h3>Manage Products
              <b-button v-if="!product" @click="addProduct()" class="float-right" variant="primary">Add Product</b-button>
              <b-button v-if="!product" @click="syncProducts()" class="float-right" variant="primary">Sync Products</b-button>
            </h3>
          </div>

          <table class="admin-table table table-striped table-hover">
            <thead>
              <tr>
                <td>Title</td>
                <td>Description</td>
                <td>Active</td>
                <td>Live</td>
                <td>CanSignup</td>                
              </tr>
            </thead>
            <tbody>
              <ListItem v-for="product in products" :product="product" :key="product._id" @on-action="onAction" />
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>

'use strict'

import ListItem from './Product/ListItem'
import Detail from './Product/Detail'

import { features,buildAllLimits } from '@/services/product'
import { actions } from '@/services/store'

export default {

  name: 'Products',

  data() {
    return {
      busy:false,
      products:[],
      product:false
    }
  },

  async created() {
    try {
      const products = await actions.fetchProducts()
      products.sort((p1,p2)=>{return p1.name.localeCompare(p2.name)})
      this.products = products
    } catch(err){
      this.errorMessage = err.toString()
    }
  },

  methods: {

    onAction(action) {

      switch( action.id ) {

        case 'select' : {
          this.product = JSON.parse(JSON.stringify(action.product))
          break;
        }

        case 'unselect' : {
          this.product = undefined;
          break;
        }

        case 'removed' : {
          this.product = false
          const idx = this.products.findIndex((p)=>{return p._id === action.product._id})
          if ( idx !== -1 ) {
            this.products.splice(idx,1)
            this.product = false
          }
        }

        case 'created' : {
          this.products.push(action.product)
        }

        case 'updated' : {
          const idx = this.products.findIndex((p)=>{return p._id === action.product._id})
          if ( idx !== -1 ) {
            const products = this.products.slice()
            products.splice(idx,1,action.product)
            this.products = products
            this.product = false
          }
        }

      }
    },

    /**
     * Product creation details/workflow
     * Mental model is that Stripe is master; admin panel can create and edit product details 
     * to stripe but cannot edit prices. Sync Products function deletes Product records from    
     * Lately and rebuilds these from Stripe. 
     * Create product workflow 
     * 1. Admin console - create a new inactive product entry with description and desired limits. 
     *    Product description is litte language with each (section) block producing a separated list.
     *    On submit, a product entry is created in Stripe with metadata describing limits, but no prices. 
     * 2. Stripe - add prices for product, each price should include a trial period typically 8d
     * 3. Admin console - sync products
     * 4. Admin console - edit product to activate product - product is live. 
     **/

    addProduct() {

      this.product = {
        name:'',
        limits:buildAllLimits(),
        prices:[],
        id:'',
        active: false,
        description:''
      }

    },


    async syncProducts() {
      try {
        this.busy = true;
        // todo - add a confirm step here
        const resp = await actions.syncProducts()
        this.products.length = 0;
        this.products.push.apply(this.products,resp.data)
        this.$toasted.success(`Retrieved ${this.products.length} products`)
        this.busy = false;
      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      }
    }

  },

  components: {
    ListItem,
    Detail
  }

}
</script>

<style lang="scss" >

.product-add-button {
  min-width:85px;
  min-height:30px;
}

.product-actions i {
  padding:5px;
  float:right;
}

.admin-table thead tr td {
  border-top-width:0px;
}

</style>
