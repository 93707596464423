

<template>

<div class="container twitter-preview">

  <div class="row">
    <div class="col-md-12 author" v-if="false && post.channel_meta">
      <!-- todo add account profile -->
    </div>
    <div class="col-md-12 author" v-else>
      <i class="fa-solid fa-circle-user fa-3x" />
      <div class="account">
        <span>{{ user.fullname }}</span>
        <p>@{{ user.lastname }}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 content">
      <p v-html="annotated"></p>
    </div>
  </div>

  <div class="row" v-if="post.attachments.length">
    <div class="col-md-12 attachments text-center">
      <div class="attachments" v-if="post.attachments.length===1">
        <Attachment :post="post" :policy="policy" :attachment="post.attachments[0]" width="100%"/>
      </div>
      <div v-else>
        <Attachment v-for="attachment in post.attachments" :post="post" :policy="policy" :attachment="attachment" width="40%"/>
      </div>
    </div>
  </div>

  <div class="row" v-else-if="preview && preview.images && preview.images.length">
    <div class="col-md-12 preview">
      <img :src="preview.images[0]" width="100%" />
    </div>
  </div>  

  <div class="row">
    <div class="col-md-12 timestamp">
      <span>{{ now | date('hh:mm a MMM D, YYYY') }} · {{ views|magnitude }} Views</span>

    </div>
  </div>

  <div class="row">
    <div class="col-md-11 actions">
      <i class="fa fa-comment" />
      <i class="fa fa-share" />
      <i class="fa fa-heart" />
      <i class="fa fa-bookmark" />
      <i class="fa fa-upload" />                  
    </div>
  </div>

</div>

</template>

<style lang="scss">

.twitter-preview {

  padding: 10px 10px 10px 10px;

  .author {
    display: flex;
    flex-direction: row;
    .account {
      margin-left: 15px;
      font-weight: bold;
    }
  }
  .content {
    font-size: 18px;
    font-weight: bold;
  }

  .actions {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.4em;
  }

  .attachment {
    width: 100%;
    border-radius: 10px;
  }

  .preview {
    img {
      border-radius: 20px;
    }
  }

  .timestamp {
    margin-top: 10px;
  }
}
</style>

<script>

'use strict'

import Attachment from './Attachment'

import { getters } from '@/services/store'

export default {

  name: 'TwitterPreview',

  data() {
    return {
        type:'',
        user: getters.user(),
        now: new Date(),
        views: Math.random()*10000000
    }
  },

  computed: {
    annotated() {
      let refs = {
        hashtag:'https://x.com/hashtag',
        mention:'https://x.com/mention'
      }
      return this.$options.filters.links((this.post? this.post.content : ''),refs);        
    }
  },

  created() {
    let user = getters.user()
    this.type = this.post.contentType === 'temporary'? this.post.channel : this.post.contentType
  },

  props: {
    preview: Object,    
    policy: Object,
    post: Object    
  },

  components: {
    Attachment
  }

}

</script>








