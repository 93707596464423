<template>
  <div class="container onboard-connect">
  <Publisher @onboarding-next="next" />
  </div>
</template>

<script>

import Publisher from '@/components/PublisherView'
export default {
  name: 'Connect',
  components: {
    Publisher
  },
  methods: {
    next(dir) {
      this.$emit('onboarding-next',dir)
    }
  }
}
</script>

<style lang="scss" >
.onboard-connect {
  margin-top: 48px;
}

</style>
