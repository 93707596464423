

<template>

<div class="container nopad linkedin-preview">

  <div class="row">
    <div class="col-md-12 author" v-if="false && post.channel_meta">
      <!-- todo add account profile -->
    </div>
    <div class="col-md-12 author" v-else>
      <i class="fa-solid fa-circle-user fa-3x" />
      <div class="account">
        <span>{{ user.fullname }}</span>
        <p>{{followers|magnitude}} followers</p>		
        <p>Just now · <i class="fa fa-earth-americas" /></p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-11 content">
      <p v-html="annotated"></p>
    </div>
  </div>

  <div class="row" v-if="post.attachments.length">
    <div class="col-md-12 attachments text-center">
      <div class="attachments" v-if="post.attachments.length===1">
        <Attachment :post="post" :policy="policy" :attachment="post.attachments[0]" width="100%"/>
      </div>
      <div v-else>
        <Attachment v-for="attachment in post.attachments" :post="post" :policy="policy" :attachment="attachment" width="40%"/>
      </div>
    </div>
  </div>

  <div class="row" v-else-if="preview && preview.images && preview.images.length">
    <div class="col-md-11 nopad preview">
      <img :src="preview.images[0]" width="100%" />
	  <div class="title">
		<h5>{{ preview.title }}</h5>
		<p>{{ preview.url }}</p>
	  </div>
    </div>
  </div>  

  <div class="row">
    <div class="col-md-11 actions">
		<span><i class="fa fa-user-circle fa-lg"></i> </span>		
		<span><i class="fa-regular fa-thumbs-up"></i> Like</span>
		<span><i class="fa-regular fa-comment"></i> Comment</span>
		<span><i class="fa-solid fa-retweet"></i> Repost</span>
		<span><i class="fa-regular fa-paper-plane"></i> Send</span>						            
    </div>
  </div>

</div>

</template>

<style lang="scss">

.linkedin-preview {

  margin: 15px;

  .author {
    display: flex;
    flex-direction: row;
    .account {
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .account {
    p {
      margin: 0px;
    }
  }
  .content {
	  margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .actions {
	  margin: 0px 0px 0px 20px;
	  padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.4em;
	  font-weight: bold;
  }

  .attachment {
    width: 100%;
    border-radius: 10px;
  }

  .preview {
    padding: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    background-color: #edf3f8;
    img {
      margin: 15px 20px 10px 15px;
      border: 1px solid lightgrey;
      border-radius: 10px;
    }
    .title {
      margin: 40px 20px 20px 0px;
      h5 {
        font-weight: bold;
      }
      p {
        font-size: 12px; 
      }
    }
  }

  .timestamp {
    margin-top: 10px;
  }
}
</style>

<script>

'use strict'

import Attachment from './Attachment'

import { getters } from '@/services/store'

export default {

  name: 'TwitterPreview',

  data() {
    return {
      now: new Date(),      
      user: getters.user(),
      views: Math.random()*10000000,
		  followers: Math.random()*1000000
    }
  },

  computed: {
    annotated() {
      let hrefs = {
        hashtag:'https://www.linkedin.com/feed/hashtag?keywords=',
        mention:'https://www.linkedin.com/feed/mention?keywords=/'
      }
      return this.$options.filters.links((this.post? this.post.content : ''),hrefs);        
    }
  },

  created() {
    let user = getters.user()
  },

  props: {
    preview: Object,    
    policy: Object,
    post: Object    
  },

  components: {
    Attachment
  }

}

</script>








