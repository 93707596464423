<template>

  <b-modal content-class="post-preview" v-model="show" :size="size" centered hide-header hide-footer @hide="close">

    <b-overlay :show="busy">

      <div class="title text-center" v-if="state==='preview'">
        <h4 class="title">Here's what your {{type|channelname}} post would look like in the wild!</h4>
      </div>

      <div class="post" :class="state==='preview'? 'preview':''">
        <Instagram v-if="type==='instagram'" :post="post" :policy="policy" :preview="preview" />
        <Facebook v-if="type==='facebook'" :post="post" :policy="policy" :preview="preview" />
        <Linkedin v-if="type==='linkedin'" :post="post" :policy="policy" :preview="preview" />
        <Twitter v-if="type==='twitter'" :post="post" :policy="policy" :preview="preview" />
      </div>

      <p class="actions text-center" v-if="state==='preview'">
        <b-button @click="close($event,true)" variant="primary">FINISH DEMO</b-button>
      </p>

    </b-overlay>

  </b-modal>

</template>

<script>

'use strict'

import { actions, getters } from '@/services/store'
import Onboarding from '@/services/onboarding'

import Instagram from './instagram'
import Facebook from './facebook'
import Linkedin from './linkedin'
import Twitter from './twitter'

export default {

  name: 'PreviewDialog',

  data() {
    return {  
        type:'',
        size:'sm',            
        busy:false,
        show:false,
        state: '',
        preview : undefined,
        dashboard: undefined
    }
  },

  props: {
    post: {
      type: Object,
      required: false
    },
    policy: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default:''
    }
  },

  created() {
    this.dashboard = getters.dashboard()
  },

  watch: {
    async post() {
        this.type = this.post? this.post.contentType==='temporary'? this.post.channel : this.post? this.channel : undefined : undefined
        this.size = this.type === 'twitter'? 'md' : 'lg'
        this.state = Onboarding.getState()
        console.log('PreviewDialog.beforeShow',{state:this.state})
        this.show = this.post !== undefined
        if ( this.show ) {
          this.busy = true
          try {
            this.preview = await actions.fetchPreview(this.dashboard,this.post)
          } catch ( err ) {
            console.error(err)
          } finally {
            this.busy = false
          }
        }

    }
  },

  methods: {
    close(evt,finish) {
      console.log('preview.close',finish)
        this.$emit('on-action',{
            action:'preview-closed',
            post: this.post,
            finish
        })
    }
  },

  computed: {
    annotated() {
        return this.$options.filters.links(this.post? this.post.content : '');        
    }
  },

  components: {
    Instagram,
    Facebook,
    Linkedin,
    Twitter
  }

}

</script>

<style>

.post-preview {

  border-radius: 15px;
  margin: 20px;

  .title {
    margin: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .post {
    padding: 0px;
  }

  .post.preview {
    margin: 20px;
    border: 1px solid lightgrey;
  }

  /**
  .post {
    padding: 20px 10px 20px 10px;    
    border: 1px solid lightgrey;
    border-radius: 15px;
  }
  **/

  .actions {
    margin: 20px;
  }
  
}

</style>
