
<template>

<div class="container" v-if="ready" >
  <div class="row">
    <div class="col-md-12">
        <h2 class="msg"> Keyword Sets</h2>
        <p>These gather key messages and hashtags for a domain or campaign; we'll use them to augment and refine your generated content.</p>
    </div>
  </div>
  <br>
  <!-- for confirmation of session removal -->
  <ConfirmDialog :open="dialogOpen" title="Remove Keyword set?" :click="removeSetResponse" />
  <div class="row" >
    <div class="form-group col-md-12" >
      <label>
      <b>Set Name</b>
      </label>
      <b-form-select class="form-control" v-model="group" :options="groups" aria-describedby="keyword-groups"></b-form-select>
      <p class="text-danger" v-if="!group">Please select a message group</p>
    </div>
    <div class="col-md-12" v-if="ready">
      <b-tabs>
          <b-tab title="Keywords">
            <div class="keywords-section">
                <label><b>Keywords</b></label>
                <Messages :editable="true" :group="group" type="Keywords" :words="keywords" @add-message="onAddMessage"  @remove-message="onRemoveMessage" />
            </div>
            <div class="keywords-section">
                <label><b>Recommendations</b></label>
                <WordCloud ref="keywordsCloud" type="Keywords" :analysis="analysis.keywords" @on-wordcloud-action="onWordcloudAction" />
            </div>
          </b-tab>
          <b-tab title="Hashtags">
            <div class="keywords-section">
                <label><b>Hashtags</b></label>
                <Messages :editable="true" :group="group" type="Hashtags" :words="hashtags" @add-message="onAddMessage"  @remove-message="onRemoveMessage" />
            </div>
            <div class="keywords-section">
                <label><b>Recommendations</b></label>
                <WordCloud ref="hashtagsCloud" type="Hashtags" :analysis="analysis.hashtags" @on-wordcloud-action="onWordcloudAction" />
            </div>
          </b-tab>
      </b-tabs>
    </div>
  </div>
</div>
</template>

<script>

import { store, actions, admin } from '@/services/store'

import ConfirmDialog from '@/components/ConfirmDialog'
import WordCloud from './WordCloud'
import Messages from './Messages'

/**
 * Overall approach
 * 1. api returns recommendations + existing keywords & hashtags
 * 2. client injects words into analysis, with found status
 **/

const RECOMMENDATION = 0
const EXISTING_FOUND = 1
 const EXISTING_NOT_FOUND = 2

 export default {

  name: 'KeyMessages',

  data() {
    return {
        ready:false,
        group:false,
        analysis:false,
        keywords: false,
        hashtags: false,
        dialogOpen:false,
        showing:'messages',
        groups: store.keyMessages.groups,
        selection: {
          current:'',
          type:''
        },
    }
  },

  async created() {
    const resp = await actions.fetchKeyMessages()
    // console.log('fetched analysis', JSON.stringify(resp.analysis,0,1))
    this.keywords = resp.keywords
    this.hashtags = resp.hashtags
    this.analysis = resp.analysis
    this.groups = ['Main']
    this.group = this.groups[0]
    this.syncAnalysis('keywords')
    this.syncAnalysis('hashtags')
    this.ready = true
  },

  methods: {

      noop() {
        return false;
      },

      removeSetResponse() {

      },

      async onAddMessage(message) {
        const resp = await actions.createKeyMessage(message.type,this.group,message.message)
        const type = message.type.toLowerCase()
        this[type].push(resp.data)
        this.syncAnalysis(type)
      },

      async onRemoveMessage(message,type) {
        type = type.toLowerCase()
        message = message._id? message : this[type].find((km)=>{return km.message === message})
        await actions.removeKeyMessage(message)
        const idx = this[type].indexOf(message)
        if ( idx != -1 ) this[type].splice(idx,1)
        this.syncAnalysis(type)
      },

      syncAnalysis(type) {

        type = type.toLowerCase()
        const klist = this[type]
        const alist = this.analysis[type]

        // ensure that existing keywords are found in analysis
        klist.forEach((kw)=>{
          const e = alist.find((aw)=>{return aw.kw && kw.message && kw.message.toLowerCase() === aw.kw.toLowerCase() })
          if ( !e ) {
            alist.push({
              kw:kw.message,
              rank: 0,
              found: EXISTING_NOT_FOUND,
              impact:[]
            })
          }
        })

        // update status of existing in analysis
        const removals = alist.reduce((acc,aw)=>{

          // find an existing keyword
          const found = klist.find((kw)=>{return aw.kw && kw.message && kw.message.toLowerCase() === aw.kw.toLowerCase() })

          // conditionally evict it
          if ( !found ) {
            if ( !aw.impact.length ) {
              acc.push(aw)
            } else aw.found = RECOMMENDATION
          } else aw.found = aw.impact.length? EXISTING_FOUND : EXISTING_NOT_FOUND

          return acc
        },[])

        // evict removals
        removals.forEach((r)=>{
          const idx = alist.indexOf(r)
          if ( idx !== -1 ) alist.splice(idx,1)
        })

        const cloud = this.$refs[`${type}Cloud`]
        if ( cloud ) cloud.onAnalysisUpdated()

      },

      onWordcloudAction(action) {
        if ( action.action === 'add' ) {
          const list = this[action.type.toLowerCase()]
          if ( !list.find((kw)=>{return kw.message === action.message}) ) {
            this.onAddMessage( {message:action.message,type:action.type})
          }
        } else if ( action.action === 'remove' ) {
          this.onRemoveMessage(action.message,action.type)
        }
      }

  },

  components: {
      ConfirmDialog,
      WordCloud,
      Messages
  }

}

</script>

<style>

.keywords-section {
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

</style>
