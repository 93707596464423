<template>
  <tr>
    <td>
      <input :disabled="true || !editing || (price.id!='')" class="form-control" type="number" v-model="price.amount">
    </td>
    <td>
      <input :disabled="true || !editing || (price.id!='')" class="form-control" type="text" v-model="price.currency">
    </td>
    <td>
      <b-form-select  :disabled="true || !editing|| (price.id!='')" v-model="price.interval" class="mb-3">
        <b-form-select-option value="day">Daily</b-form-select-option>
        <b-form-select-option value="week">Weekly</b-form-select-option>
        <b-form-select-option value="month">Monthly</b-form-select-option>
        <b-form-select-option value="year">Annually</b-form-select-option>
      </b-form-select>
    </td>
    <td>
      <input  :disabled="true || !editing || (price.id!='')" class="form-control" type="number" v-model="price.trial_period_days">
    </td>
    <td>
      <input disabled class="form-control" type="text" v-model="price.id">
    </td>
    <td>
      <b-form-checkbox  :disabled="true || !editing" size="sm" v-model="price.active" name="check-button" switch>
      </b-form-checkbox>
    </td>
    <td>
      <!--
      <span class="pull-right">
        <b-button :disabled="!editing || price.subscribers>0" variant="outline-primary" @click="onAction('remove')"><i class="fa fa-trash pull-right" /></b-button>
      </span>
      -->
    </td>
  </tr>
</template>

<script>

export default {

  name: 'Price',

  props: {
    product: {
      type: Object
    },
    price: {
      type: Object
    }
  },

  methods: {
    onAction(id) {
      this.emit('on-action',{
        id:id,
        kind:'price',
        subject:this.price
      })
    }
  }

}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
