
let TIPS = [], pending

function randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}            

import { actions } from '@/services/store'

class WritingTips {

    static async init(dashboardId) {
        if ( TIPS.length ) return 
        TIPS = await actions.fetchTips(dashboardId)
    }

    static async randomTip(dashboardId) {

        if ( !TIPS.length ) {
            await WritingTips.init(dashboardId)
        }

        if ( TIPS.length ) {
            let idx = randomInt(0,TIPS.length-1)
            return TIPS[idx]
        } else return ''

    }

}

export default WritingTips
