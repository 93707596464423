<template>

  <div class="source-analytics">
    <h4>{{sessions.all}} sources
      <small class="float-right">
        {{sessions.text}} Text
        {{sessions.url}} URL
        {{sessions.video}} Video
      </small>
    </h4>
    <b-table striped hover :items="users" :fields="fields" :filter="search" />
  </div>

</template>

<script>

import { actions } from '@/services/store'
import moment from 'moment'

export default {

  name: 'UserAnalytics',
  data() {
    return {
      users:[],
      sessions:{
        all:0,
        text:0,
        url:0,
        video:0
      },
      fields:[{
        key:'fullname',
        label:'Name',
        sortable:true
      },{
        key:'email',
        label:'Email',
        sortable:true
      },{
        key:'text',
        label:'Text',
        sortable:true
      },{
        key:'url',
        label:'URL',
        sortable:true
      },{
        key:'video',
        label:'Video',
        sortable:true
      },{
        key:'all',
        label:'Total',
        sortable:true
      }]
    }
  },

  props: {
    date: Date,
    search: String
  },

  async created() {
    this.query()
  },

  methods: {
    async query() {
      try{
        const date = moment(this.date)
        const resp = await actions.fetchAdminAnalytics('sources',this.date,this.date)
        this.users = resp.users
        this.sessions.all = resp.summary.sessions.all
        this.sessions.text = resp.summary.sessions.text
        this.sessions.url = resp.summary.sessions.url
        this.sessions.video = resp.summary.sessions.video
      } catch(err) {
        console.error(err)
        this.$toasted.error('Unable to fetch Source analytics')
      }
    }
  },

  watch: {
    date() {
      this.query()
    }
  }
}
</script>

<style lang="scss" >

.source-analytics {
  h4 {
    margin: 20px 0px 20px 0px;
    small {
      font-size: 18px!important;
    }
  }
}
</style>
