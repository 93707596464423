<template>
  <div>
    <Onboarding v-if="sessionCount <= 3" :dashboard="dashboard" :session="session" :sessionCount="sessionCount" @on-progress-complete="onProgressComplete" />
    <Onboarded v-else :dashboard="dashboard" :session="session" :sessionCount="sessionCount" @on-progress-complete="onProgressComplete" />
   </div>
</template>

<script>

import { getters } from '@/services/store'
import Onboarding from './Onboarding'
import Onboarded from './Onboarded'

export default {
  name: 'ProgressIndicator',
  data() {
    return {
      type:'text',
      sessionCount:0
    }
  },
  created() {
    this.sessionCount = getters.sessions().length
    document.body.style.backgroundColor = '#F9F9F9'    
  },
  props: {
    dashboard: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    }
  },
  methods: {
    onProgressComplete() {
      this.$emit('on-progress-complete')
    }
  },
  components: {
    Onboarding,
    Onboarded
  }
}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
