<template>

  <div class="row" v-if="source && policy && status==='transcribed'">

    <div class="col-md-4">
      <video ref="videoPlayer" controls="" width="100%">
        <source :src="source|preview(policy)" :type="source.mimetype"/>
        Your browser does not support HTML5 video.
      </video>
      <span>{{source.filename}}</span>
    </div>
    <div class="col-md-8">

      <b-textarea style="font-size: 18pt" ref="transcript_editor" v-if="transcript && !useGrammarly" debounce="1000"
        class="post-editor"
        v-model="transcript"
        @input="setTextIndex($event)"
        @keyup="setTextIndex($event)"
        @click="setTextIndex($event)"
        rows="12" />

      <Grammarly clientId="client_2uUW7bgYEuBKkdy4Q4f93P" v-if="transcript && useGrammarly">
        <GrammarlyEditorPlugin :config="config">
          <b-form-textarea ref="transcript_editor" debounce="1000"
            style="width:100%"
            class="grammarly-editor"
            v-model="transcript"
            @input="setTextIndex($event)"
            @keyup="setTextIndex($event)"
            @click="setTextIndex($event)"
            rows="12" />
        </GrammarlyEditorPlugin>
      </Grammarly>

    </div>
    <div class="col-md-12">
      <span class="float-right transcript-actions">
        <span class="float-right">
          <b-button :disabled="(diffs.length>0)" variant="primary" v-b-tooltip.hover title="Continue with this transcript" @click="resumeSession()">
            {{busy? 'Saving..' : 'Continue'}}
          </b-button>
        </span>
      </span>
    </div>
  </div>

</template>

<script>

import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-vue";
import { GrammarlyConfig } from '@/services/grammarly-config'
import { actions, getters } from '@/services/store'
import * as Diff from 'diff'
import Vue from 'vue'

export default {

  name: 'TranscriptEditor',

  data() {

    return {
      diffs:[],
      history:[],
      busy:false,
      timer:false,
      video:false,
      policy:false,
      status:false,
      source:false,
      merging:false,
      textArea: false,
      transcript:false,
      editorUrl:false,
      errMessage: false,
      monologues:false,
      useGrammarly:false,
      ignoreUpdate:false,
      selectionEnd:false,
      selectionStart:false,
      originalTranscript:false,
      config: GrammarlyConfig,
    }

  },

  props: {

    session: {
      type: Object,
      required:true
    }

  },

  async created() {

    console.log('TranscriptEditor.created',JSON.stringify(this.session.config.steps,0,1))

    try {
      this.policy = await actions.fetchPolicy()
      const dashboard = getters.dashboard()
      this.source = await actions.fetchSource( dashboard._id, 'file', this.session.config.steps.source.source._id )
      if ( this.source && this.source.transcription ) {
        this.status = this.source.transcription.status
        this.editorUrl = this.source.transcription.editor_url
        this.transcript = this.source.transcription.transcript
        this.monologues = this.source.transcription.monologues

        // setup initial annotation
        this.annotateMonologues()

        // get reference to video player
        this.getPlayer()

        // get reference to editor
        this.getEditor()

      } else {
        this.errMessage = 'We were not able to transcribe this source'
      }

    } catch ( err ) {
      console.error(err)
      this.$toasted.error(`Unable to locate session source`)
    }

  },

  watch: {

    // transcript change handler
    transcript(newV,oldV) {

      if ( !this.ignoreUpdate ) {

        this.originalTranscript = oldV
        if ( newV && oldV ) {

          // compute text diffs, return a subset
          let diffs = Diff.diffChars(oldV,newV).map((d)=>{
            if ( d.added || d.removed ) {
              return d
            } else return { count:d.count }
          })

          // the last diff is a count of remaining text so drop it
          diffs.pop()

          // store this round of updates
          this.diffs.push.apply(this.diffs,[diffs])

          // queue an update
          this.updateTranscript([diffs])

        }

      } else this.ignoreUpdate = false

    }

  },

  methods: {

    updateTranscript() {

      if ( this.timer ) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(this.updateTranscriptImpl,500)

    },

    async updateTranscriptImpl() {

      const diffs = this.diffs.slice()
      this.diffs.length = 0

      this.timer = false
      this.busy = true

      try {

        // const monologues = JSON.parse(JSON.stringify(this.monologues))

        const resp = await actions.updateSource( this.source.dashboard, 'file', this.source._id, {
          diffs: diffs,
        })

        // store results / clear history
        this.diffs.length = 0
        this.monologues = resp.monologues
        this.updated = this.annotateMonologues()

        // compare computed with textarea
        if ( this.transcript !== this.updated ) {
          this.$toasted.error(`Please retry your last edit`)
          this.ignoreUpdate = true
          this.transcript = this.updated
        }

      } catch ( err ) {
        console.error(err)
        this.$toasted.error(`Sorry - that didn't work. Please refresh the page to get an accurate view.`)
      } finally {

        this.busy = false
      }
    },

    getPlayer() {
      this.video = this.$refs.videoPlayer
      if ( this.video ) {
        // this.video.addEventListener('canplay', this.canPlay);
        this.video.addEventListener('play', this.play);
        this.video.addEventListener('pause', this.pause);
        // this.video.addEventListener('timeupdate',this.timeUpdate)
      } else {
        Vue.nextTick( this.getPlayer, 500 )
      }
    },

    getEditor() {
      this.textArea = this.$refs.transcript_editor
      if ( !this.textArea ) {
        Vue.nextTick(this.getEditor,500)
      }
    },

    setTextIndex(e) {
      this.selectionStart = this.textArea.selectionStart
      this.selectionEnd = this.textArea.selectionEnd
      this.setVideoPosition(this.selectionStart,this.selectionEnd)
    },

    setVideoPosition(start) {
      const range = this.findMonologuesInRange('idx',start,start)
      if ( this.video && range.length ) {
        this.video.currentTime = Math.max(0,(range[0].ts||0))
      } else console.error('Unable to set video time',start,range.length,this.transcript.length)
    },

    canPlay() {
      // console.log('canPlayHandler')
    },

    play() {
      // console.log('video playing', arguments)
    },

    pause() {
      // console.log('video paused', arguments)
    },

    timeUpdate(ev) {
      const frags = this.findMonologuesInRange('time', this.video.currentTime, this.video.currentTime)
      if ( frags.length && this.textArea ) {
        Vue.nextTick(()=>{
          this.textArea.focus()
          this.textArea.setSelectionRange(frags[0].start, frags[0].end+1)
        })
      }
    },

    // annotate monologues with current start/end ranges
    annotateMonologues() {
      let current = 0
      let text=[]
      if (Array.isArray(this.monologues)) {
      this.monologues.forEach((m,midx)=>{
        m.elements.forEach((e)=>{
          e.midx = midx
          e.start = current;
          current += e.value.length
          e.end = current-1
          text.push(e.value)
        })
      })
      }
      return text.join('')
    },

    findMonologuesInRange(metric,sIdx,eIdx,withContainer) {
      sIdx = Math.max(0,sIdx)
      return this.monologues.reduce((acc,m)=>{
        const matches = (m.elements||[]).filter((e)=>{
          if ( metric === 'idx' ) {
            return (e.start <= sIdx && e.end >= eIdx)
          } else {
            return (e.ts && e.end_ts && e.ts <= sIdx && e.end_ts >= eIdx)
          }
        })
        if ( matches.length ) {
          matches.forEach((match)=>{
            if ( withContainer ) {
              acc.push({monologue:m,match:match})
            } else acc.push(match)
          })
        }
        return acc
      },[])
    },

    async resumeSession() {
      this.$emit('resume-session')
    }

  },

  components: {
    GrammarlyEditorPlugin,
    Grammarly
  }

}
</script>

<style lang="scss" >

.transcript-actions {
  padding-top:5px;
}

.transcript-actions a {
  margin:5px;
}

.transcript-actions button {
  margin:5px;
}


</style>
