<template>
  <div class="calendar container-fluid nopad" v-if="dashboard && policy">

    <div class="calendar-body container nopad">

      <FullCalendar :options="calendarOptions" ref="fullCalendar" />

      <!-- editor -->
      <div v-if="editing">
        <EditorModal ref="editorModal" v-model="isEditorModalOpen" :session="session" :policy="policy" :errors="errors"
        :post="editing" @close-modal="handleCloseModal" @save-post="toggleEdit(editing, true)"
        @update-schedule="handleUpdateSchedule" @post-deleted="confirmDeletePost" /> 
      </div>

    </div>
  </div>
</template>

<style scoped>
.calendar.container-fluid {

  font-family: Noto Sans;

  .calendar-header {

    margin-top: -40px !important;

    background-color: #20C763;

    h2 {
      font-size: 40px;
      font-weight: bold;
      margin: 40px 0px 40px 0px;
    }
    background-color: #20C763;
    color: white;
  }

  .fc-button-active {
    background-color: #42b983;
    border: 0px;
  }
}
</style>


<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import { actions, getters } from '@/services/store'
import moment from 'moment'
import EditorModal from './EditorModal.vue'

export default {
  components: {
    EditorModal,
    FullCalendar,
  },

  inject: [
    'busy',
    'sending',
    'show',
    'status',
    'paging',
  ],

  props: {

    config: {
      type: Object,
      required: true,
    },
    policy: Object,
    content: {
      type: Array,
      required: true,
    },
    editing: {
      type: [Object, Boolean],
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    selectedChannels: {
      type: Array,
      default: () => [],
    },
    selectedContent: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Object,
      required: true,
    },
    session: {
      type: [Object, Boolean],
      default: null,
    },

  },

  data() {
    return {
      calendarOptions: {
        height: 'auto',
        navLinks: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        editable: true,
        eventStartEditable: true,
        eventDurationEditable: false,
        eventResizableFromStart: false,
        headerToolbar: {
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay',
        },
        events: [],
        eventContent: this.renderEventContent,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        datesSet: this.handleDatesSet,
        allDaySlot: false,
        buttonText: {
          today: 'Today',
          month: 'Month',
          week: 'Week',
          day: 'Day',
        }
      },
      isEditorModalOpen: false,
    }
  },


  watch: {

    'content': {

      handler () {
        this.updateCalendarEvents()
      },

      deep: true
    },

},

  methods: {

    updateCalendarEvents() {
      // Clear the existing events from the calendar
      this.$refs.fullCalendar.getApi().removeAllEvents();

      // Re-fetch events from your content or backend
      const events = this.content.map((post) => {
        return {
          id: post._id,
          title: post.content,
          start: moment(post.publishAt).toISOString(),
          extendedProps: {
            logo: post.channel_meta ? post.channel_meta.logo : null,
            contentType: post.channel==='temporary'? post.contentType : post.channel,
            status: post.status
          },
        };
      });

      // Add the updated events to the calendar
      this.$refs.fullCalendar.getApi().addEventSource(events);
    },

    async handleEventDrop(info) {
      const event = info.event
      const newDate = moment(event.start).toDate();
      const post = this.content.find((item) => item._id === event.id)

      // prevent rescheduling of published content
      if (post.status === 'P') {
        this.$toasted.error(`Can't reschedule published content`)
        info.revert()
      }

      // prevent rescheduling into the past
      else if (newDate < new Date()) {
        this.$toasted.error('Schedule date is in the past')
        info.revert()
      }

      // otherwise
      else if (post) {

        // Get the updated date and time
        const newTimeRaw = moment(event.start).format('HH:mm:ss');
        const newTimeFormatted = moment(event.start).format('h:mm a');

        // Update the post's publishAt with the new date and time
        post.publishAt = moment(event.start).toISOString();

        // Update editing and updatedSchedule objects and save
        this.$emit('event-drop', {
          post,
          newSchedule: {
            date: newDate,
            time: {
              raw: newTimeRaw,
              formatted: newTimeFormatted,
            },
          },
        });
      }
    },

    /**
     * TODO - is there a more Vue centric way to do this that would allow
     * interpolation of properties etc? 
     * @param {} info 
     */

    renderEventContent(info) {

      const iconHTML = this.getIconHTML(info.event.extendedProps.contentType);
      const statusColor = this.getStatusColor(info.event.extendedProps.status);
      const timeFormatted = moment(info.event.start).format('h:mm A');

      // Conditionally add info icon if the status is 'F' (Failed)
      const failReason = info.event.extendedProps.publish_err || 'Unknown failure reason';
      const infoIcon = info.event.extendedProps.status === 'F' ? `
    <i class="fa fa-info-circle" style="
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #DC3545;
      margin-bottom: 3px;"
      v-b-tooltip.hover
      title="${failReason}"></i>` : '';

      return {
        html: `
              <div style="
                background: #999;
                border-color: #999;
                text-decoration: none;
                margin-top: 2px;
                padding: 5px 0px;
                color: #1c2d33 !important;
                width: 100%">
                <div style="
                  background-color: #f2f2f2;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  padding: 5px;
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);">
                  <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;">
                    <p style="
                      margin: 0 !important;
                      font-size: 12px;
                      font-weight:bold;
                      color: ${statusColor};">
                      ${timeFormatted}
                    </p>
                    <div style="display: flex;
                    align-items: center; gap:3px;">
                        <img src="${info.event.extendedProps.logo}" alt="" style="border-radius: 100%; width:20px; height:20px;">
                        ${iconHTML}
                    </div>
                  </div>
                  <h6 style="
                    font-size: 10px;
                    font-weight: normal;
                    color: #1c2d33; 
                    text-wrap:wrap;
                    margin-top:5px;">
                    ${info.event.title.substring(0, 70)}...
                  </h6>
                  ${infoIcon}
                </div>
              </div>
            `,
      };
    },

    getStatusColor(status) {
      switch (status) {
        case 'P': // Published
          return '#20C763';
        case 'F': // Failed
          return '#DC3545';
        case 'S': // Scheduled
          return 'blue';          
        default:
          return '#1c2d33';
      }
    },

    getIconHTML(contentType) {
      const icons = {
        twitter: '<i class="fab fa-square-x-twitter fa-lg"></i>',
        facebook: '<i class="fab fa-facebook fa-lg"></i>',
        linkedin: '<i class="fab fa-linkedin fa-lg"></i>',
        instagram: '<i class="fab fa-instagram fa-lg"></i>',
      };
      return icons[contentType] || '';
    },

    onSelect(id) {
      this.$emit('on-select', id)
    },

    confirmDeletePost() {
      this.onAction('remove', this.editing)
    },

    async completeRemove(confirm) {
      this.$emit('complete-remove', confirm)
    },

    async handleEventClick(info) {

      // Now match against `this.content` based on this ID
      const post = await this.content.find((item) => item._id === info.event.id)

      if (!post) return;

      this.onAction('edit', post)

    },

    openEditorModal() {
      this.isEditorModalOpen = true;
      this.$refs.editorModal.showModal();
    },

    onAction(action,post) {
      this.$emit('on-action', {
        action,
        post: post,
      })
    },

    handleUpdateSchedule(updatedSchedule) {
      this.$emit('handle-update-schedule', updatedSchedule)
    },

    handleCloseModal() {
      this.$emit('toggle-edit')
      this.isEditorModalOpen = false
    },

    handleDatesSet(arg) {
      const startDate = moment(arg.start).startOf('day').toISOString();
      const endDate = moment(arg.end).endOf('day').toISOString();

      // Emit event to parent (Content) with the selected date range
      this.$emit('dates-update', { startDate, endDate });
    },

    updateEvents(events) {
      this.calendarOptions.events = events
    },

  },
}
</script>
