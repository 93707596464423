<template>

   <b-modal id="mailer-modal" size="md" v-model="show">

    <template #modal-header="{}">
      <h3>{{ttitle}}</h3>
    </template>

    <template #default="{ }">
      <b-form-group
        id="subject"
        label="Subject"
        label-for="subject"
      >
        <b-form-input id="subject" v-model="subject" trim></b-form-input>
      </b-form-group>
      <b-form-group
        id="message"
        label="Message"
        label-for="message"
      >
        <b-form-textarea id="message" v-model="message" trim></b-form-textarea>
      </b-form-group>    
    </template>

    <template #modal-footer="{}">
      <span class="float-right">
        <b-button size="sm" variant="default" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="send()">
          Send Message
        </b-button>
      </span>
    </template>

  </b-modal>

</template>

<script>
export default {
  name: 'Mailer',
  data() {
    return {
      subject: '',
      message:'',
    }
  },
  props: {
    show: Boolean,
    ttitle: String,
    tsubject: String,
    tmessage: String
  },
  created() {
      this.subject = this.tsubject
      this.message = this.tmessage
  },  
  methods: {
  
    send() {
      console.log('send')
      this.$emit('on-mail-action','sent')      
    },
    cancel() {
      this.$emit('on-mail-action','cancel')
    }
  }
}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
