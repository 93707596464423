<template>

  <div>

    <h3>Let's review your transcript
      <small v-if="isAdmin" class="float-right editor-toggle"><a href="#" @click="toggleEditor($event)">Use {{useTemi? 'Native Editor' : 'Temi Editor'}}</a></small>
    </h3>
    <p>We've transcribed your video :) Now's your chance to make any changes before we generate your content!</p>

    <div class="transcript-editor" style="width:100%;height:100%" v-if="session.status.bucket==='transcribed' && useTemi">
      <TemiTranscriptView :session="session" @resume-session="resumeSession" />
    </div>

    <div class="transcript-editor" style="width:100%;height:100%" v-if="session.status.bucket==='transcribed' && !useTemi">
      <TranscriptEditor :session="session" @resume-session="resumeSession" />
    </div>

  </div>

</template>

<script>

import TemiTranscriptView from './TemiTranscriptView'
import TranscriptEditor from './TranscriptEditor'

import { getters } from '@/services/store'
import Vue from 'vue'

export default {
  name: 'TranscriptReview',
  data() {
    return {
      useTemi:true,
      isAdmin:true
    }
  },
  props: {
    session: {
      type: Object,
      required: true
    }
  },
  created() {
    this.isAdmin = true // getters.isAdmin()

  },
  methods: {
    resumeSession() {
      this.$emit('resume-session')
    },
    toggleEditor(ev) {
      ev.stopPropagation()
      ev.preventDefault()
      Vue.nextTick(()=>{
        this.useTemi = !this.useTemi
      })
    },
  },
  components: {
    TemiTranscriptView,
    TranscriptEditor
  }
}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
