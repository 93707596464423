import { events, getters, actions } from './store'

class Onboarding {
    state = ''
    helpText = ''

    static setHelpText(text = '') {
        Onboarding.helpText = text
        events.$emit('help')
    }

    static getHelpText() {
        return Onboarding.helpText
    }

    static getState() {
        let user = getters.user()
        return user.onboarding.state
    }

    static async setState(state, args = {}) {
        let user = getters.user()
        Object.assign(user.onboarding, args)
        await actions.patchUser(user._id, 'onboarding', user.onboarding)
        Onboarding.state = state
    }
}

export default Onboarding
