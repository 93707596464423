<template>
  <div class="text-center">
    <div class="onboarding-start d-flex align-items-center justify-content-center">
      <div class="container onboarding-body">
        <h3>{{session? 'Welcome Back!':'Welcome to Lately!'}}</h3>
        <p>Let's {{session?'finish':'get started by'}} running you through Lately...<br/> Let's jump right into the platform.. We'll show you how it works.. </p>
        <b-button @click="next(1)">LET'S GO!</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  methods: {
    next() {
      this.$emit('on-action',{action:'welcome'})
    }
  },
  props: {
    session:{
      type: Object,
      required:false
    }
  }
}
</script>

<style lang="scss" >
.onboarding-start {

  flex: 1;

  background-image: url('~@/assets/onboarding/v2/bg1.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;

  h3 {
    font-size: 32px;
    font-weight: bold;
  }

  button {
    color: white;
    background-color: #20C763;
  }

}
</style>
