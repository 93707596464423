

class Session {

  static get (key, dflt) {
    if (global.localStorage) {
      const val = localStorage.getItem(key)
      return val ? JSON.parse(val) : dflt
    } else return dflt
  }

  static set (key, val) {
    if (global.localStorage) {
      val = JSON.stringify(val)
      localStorage.setItem(key, val)
    }
  }

  static clear(key) {
    if (global.localStorage) {
      localStorage.removeItem(key)
    }
  }

}

export default Session
