<template>

  <div class="nopad" v-if="user && dashboard">
    <GeneratorLaunch v-if="onboarded" :numSessions="numSessions" :dashboard="dashboard" :user="user" />
    <GeneratorOnboard v-else :user="user" />
  </div>

</template>

<script>

import { getters, actions } from '@/services/store';

import GeneratorOnboard from './Launcher/GeneratorOnboard'
import GeneratorLaunch from './Launcher/GeneratorLaunch'

export default {

  name: 'Success',

  data() {
    return {
      dashboard: false,
      numSessions : 0,
      user: false
    }
  },

  // get a session count
  async created() {
    this.onboarded = true // User.getOnboardingKey('generator')
    this.dashboard = getters.dashboard()
    const sessions = getters.sessions()
    this.numSessions = sessions.length
    this.user = getters.user()
  },

  components: {
    GeneratorOnboard,
    GeneratorLaunch
  }

}
</script>

<style lang="scss" >

</style>
