<template>

  <div class="session-source" v-if="session && policy">

    <!-- video source -->
    <div v-if="session.config.source==='video'">
      <video controls="">
        <source :src="source|preview(policy)" :type="mimetype"/>
        Your browser does not support HTML5 video.
      </video>
    </div>

    <!-- text source -->
    <div v-else class="text-source">

      <!-- url source -->
      <div v-if="session.config.steps.source.type==='url'">

        <!-- with preview -->
        <div v-if="thumbnail">
          <b-img fluid :src="thumbnail" alt="Link thumbnail" />
        </div>

        <!-- without preview -->
        <div v-else>
          <b-img fluid :src="require('@/assets/sourcetypes/link.png')" />
        </div>
      </div>

      <!-- pasted text -->
      <div v-else>
        <b-img fluid :src="require('@/assets/sourcetypes/text.png')" />
      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'SessionSource',

  data() {
    return {
      thumbnail:'',
      source: false,
      mimetype: ''
    }
  },

  created() {
    const source = this.session.config.steps.source
    this.thumbnail = source && source.preview && source.preview.thumbnail && source.preview.thumbnail !== 'null'? source.preview.thumbnail : ''
    this.source = source? source.source : false
    this.mimetype = this.source? this.source.mimetype.startsWith('video')? 'video/mp4' : 'audio/mp3' : ''
  },

  props: {
    session: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" >

.session-source {

  height: 100%;
  display: inline-block;
  padding: 24px;

  video {
    border-radius:6px!important;
    width: 100%!important;
  }

  img {
    border-radius:6px!important;
    width: 100%!important;
  }

}


</style>
