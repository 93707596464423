<template>
  <span class="score-indicator-badge" :style="'background:'+color">{{icon}}</span>
</template>

<script>
export default {

  name: 'ScoreIndicator',

  data() {
    return {
      score:0,
      icon: '',
      color: ''
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  created() {

    this.score = this.post.rank? this.post.rank.successIndicator || 0 : 0
    if ( this.score < 50 ) {
      this.color = '#e66727'
      this.icon = '🙁'
    } else if ( this.score < 60 ) {
      this.color = '#f0b00e'
      this.icon = '😶'
    } else if ( this.score < 80 ) {
      this.icon = '🙂'
      this.color = '#f0b00e'
    } else {
      this.icon = '😄'
      this.color = '#6e933f'
    }

  }

}

</script>

<style lang="scss" >

.score-indicator-badge {
  padding: 4px;
  margin: 2px;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 20px;
}

</style>
