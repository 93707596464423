<template>
  <div class="generator-onboard nopad">
    <Transition name="fade" mode="out-in">
      <Intro v-if="step===0" @on-next="next" :stats="stats" />
      <ImportStats v-if="step===1" @on-next="next" :stats="stats" />
      <AverageTime v-if="step===2" @on-next="next" :stats="stats" />
      <WorkflowWelcome v-if="step===3" @on-next="next" :stats="stats" />
      <StartWithText v-if="step===4" @on-next="next" :stats="stats" />
      <Source v-if="step===5"  @on-next="next" @on-generate="generate" />
    </Transition>
  </div>
</template>

<script>

import { actions, getters } from '@/services/store'

import StartWithText from './StartWithText'
import ImportStats from './ImportStats'
import AverageTime from './AverageTime'
import Intro from './Intro'
import WorkflowWelcome from './WorkflowWelcome'
import Source from './Source'

// timeout for completion of import
const TIMEOUT = 30000;

export default {
  name: 'GeneratorOnboard',
  data() {
    return {
      step:0,
      url:'',
      stats:{},
      timeout: false,
      start: Date.now()
    }
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  async created() {
    document.body.style.backgroundColor = 'white';    
    this.poll()
  },
  destroyed() {
    if ( this.timeout ) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    next(dir) {
      this.step += dir
    },
    async poll() {

      // move things along if the import doesn't complete in a timely manner
      if ( ( Date.now() - this.start ) > TIMEOUT ) {
        return this.next(1)
      }

      try {
        const dashboard = getters.dashboard()
        this.stats = await actions.fetchOnboardStats(dashboard._id)
        // console.log('fetched stats', JSON.stringify(this.stats,0,1) )
      } catch( err ) {
        this.$toasted.error(err.message)
      } finally {
        if ( this.stats.status !== 'COMPLETED' && this.stats.status !== 'ABANDONED' ) {
          this.timeout = setTimeout(this.poll,3000)
        }
      }

    },
    async generate(url) {
      const dashboard = getters.dashboard()
      const resp = await actions.createSession( 'text', url, {}, true )
      console.log('createSession returned', resp)
      this.$router.push(`/${dashboard._id}/results/${resp.session._id}`)
    }
  },
  components: {
    StartWithText,
    ImportStats,
    AverageTime,
    Intro,
    WorkflowWelcome,
    Source,
  }
}
</script>

<style lang="scss" >

.generator-onboard {
  margin-top: -60px!important;
  background: white;
}

.onboard-page {

  height: 100%!important;
  font-family: Noto Sans;
  margin-top: 100px;

  .onboard-page-header {
    text-align: center;
    img {
      height: 199px;
      margin-bottom: 24px;
    }
    .title {
      font-size: 42px;
      font-weight: bold;
      margin-top: 24px;
    }
    .description {
      font-size: 26px;
      font-weight: regular;
      margin-top: 24px;
    }
  }

  .onboard-page-body {
    margin-top: 36px;
  }

  .onboard-page-footer {

    text-align: center;

    .onboard-continue {
      min-width: 200px;
      margin-top: 50px;
      margin-left: 10px;
      padding: 18px 18px 18px 30px;
      border-radius: 8px;
      border: 1px solid #20C763!important;
      font-size: 18px;
      font-weight: bold;
      background-color: #20C763
    }

    .onboard-back {
      min-width: 200px;
      padding: 18px 20px 18px 20px;
      color: black;
      margin-top: 50px;
      margin-right: 10px;
      border: 1px solid #D6D6D6;
      border-radius: 8px;
      font-size: 18px;
      font-weight: bold;
      background-color: white
    }

  }

}

</style>
