<template>

  <b-container>


  </b-container>

</template>

<script>

  'use strict'

  import router from '../services/router.js';

  export default {
    name: 'Logout',
    created() {
      setTimeout(()=>{
        router.push('/app/login')
      },100);        
    }
  }
</script>
