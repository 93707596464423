<template>
  <div class="content-filter-media-type">
    <b-dropdown id="status-dropdown" :text="label" class="m-2" @click="noop($event)">
      <a class="dropdown-item" @click="select($event,all)">
        <i class="fas fa-fw" :class="all.checked? 'fa-check':''" /> Show All
      </a>
      <b-dropdown-divider/>
      <a class="dropdown-item" v-for="(option,idx) in options" :key="idx" @click="select($event,option)">
        <i class="fas fa-fw" :class="option.checked? 'fa-check':''" /> {{option.val}}
      </a>
    </b-dropdown>
  </div>
</template>

<script>

import { getters } from '@/services/store'
import Session from '@/services/session'

const SESSION_KEY = 'list-mediatype-filter'

export default {
  name: 'MediatypePicker',
  data() {
    return {
      all: false,
      options: [],
      ready: false,
      label: 'Show All',
      partner: getters.partner()
    }
  },
  props: {
    dashboard: {
      type: Object,
      required:true
    },
    config: {
      type: Object,
      required:true
    }
  },

  created() {

    const partner = getters.partner()
    const options = this.config.value.split(',')

    // setup options array
    this.options = options.map((o)=>{
      return {
        key: o,
        checked: false,
        val: this.labelFor(o)
      }
    })

    // add all
    this.all = {
      key: '*',
      val: 'Select All',
      checked:true
    }

    const selections = Session.get(SESSION_KEY,'all')

    if ( selections === 'all' ) {
      this.select(false,this.all)
    } else {
      selections.forEach((key)=>{
        const option = this.options.find((o)=>{
          return o.key===key
        })
        if ( option ) {
          this.select(false,option)
        }
      })
    }

    this.ready = true

  },

  methods: {

    pull(list,value) {
      const idx = list.indexOf(value)
      list.splice(idx,1)
    },

    select(ev,option) {

      if ( ev ) {
        ev.stopPropagation()
        ev.preventDefault()
      }

      if ( option === this.all ) {
        this.options.forEach((o)=>{ o.checked = false })
        this.all.checked = true
        this.label = 'Show All'
        Session.set(SESSION_KEY,'all')
        this.$emit('on-filter',{key:'status',val:this.config.value.split(',')})
      } else {
        this.all.checked = false
        option.checked = !option.checked
        const checked = this.options.filter((o)=>{ return o.checked })
        this.label = checked.map((o)=>{return ' '+o.val}).join(',')
        const status = checked.map((o)=>{return o.key})
        Session.set(SESSION_KEY,status)
        this.$emit('on-filter',{ key:'status',val: status })
      }

    },

    labelFor(value) {
      switch( value ) {
        case 'text': return 'Text'
        case 'video': return 'Video'
      }
    }
  }
}
</script>

<style lang="scss" >

.content-filter-media-type {
  display: inline;
  .dropdown {
    margin: 0px!important;
    .dropdown-toggle {
      width: 300px;
      height:38px;
      padding: 5px 0px 5px 10px;
      display: inline;
      text-align: left;
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      font-size: 16px;
      font-weight: bold;
    }
    .dropdown-item {
      font-family: Noto Sans!important;
      font-weight: medium;
    }
  }

}

</style>
