<template>
    <div class="paperclip text-center" v-if="($mq === 'lg'||$mq==='xl')">
      <div class="speechBubble" :class="animate? 'animate':''">
        <img src="@/assets/help.png" />
        <p>{{ text }}</p>
      </div>
    </div>
</template>

<script>

import Onboarding from '@/services/onboarding'

export default {
  name: 'Help',
  data() {
    return {
      animate: false, 
      text: Onboarding.getHelpText()
    }
  },
  created() {
    setTimeout(()=>{
      this.animate = false
    },3000)
  }
}
</script>

<style lang="scss" >
.paperclip {
  background-color: #F8F7F4;
  position: fixed;
  bottom: 80px;
  width: 300px;
  right: 20px;

  // speech bubble styling from https://webspe.net/tools/en/speech-bubble/

  .speechBubble {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  padding: 16px;
  border: 2px solid #1bcd7d;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: center;
  img {
    margin-bottom:10px;
  }
}

.speechBubble::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 82%;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  border-color: #1bcd7d transparent transparent;
  translate: -50% 100%;
}

.speechBubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 82%;
  border-style: solid;
  border-width: 8.9px 7.4px 0 7.4px;
  border-color: #ffffff transparent transparent;
  translate: -50% 100%;
}
}


</style>
