<template>

  <div v-if="source" :key="source._id" class="text-center video-editor-v2">

    <div class="remove-attachment" v-b-tooltip.hover title="Remove attachment" @click="removeAttachment()">
      <span class="fa-stack fa-lg">
        <i class="fa fa-solid fa-circle fa-stack-2x"></i>
        <i class="fa fa-solid fa-inverse fa-stack-1x fa-times" style="color:white;opacity:1.0;"></i>
      </span>
    </div>
    
    <video ref="videoPlayer" controls="">
      <source :src="source.url" :type="source.mimetype.startsWith('video')? 'video/mp4':'audio/mp3'"/>
      Your browser does not support HTML5 video.
    </video>

    <!-- video extent editor, enabled for DT status only -->
    <div class="video-extent" v-if="extent">

      <!-- extent reset, caption editor toggle -->
      <div class="d-flex">
        <span class="mb-0 video-extent-text">Adjust Clip Length
          <span class="caption-reset">
            <a href="#" @click="toggleZoom($event)">
              Scale
              <i class="fa" :class="zoomed? ' fa-search' : 'fa-search-plus'" />
            </a>
          </span>
          <span v-if="modified>0" class="caption-reset"> <a href="#" @click="reset($event)"> Reset</a></span>
        </span>
        <label for="switch_1" class="ml-auto mb-0 video-extent-text"  v-if="includes('video-caption-edit')">Caption Editor</label>
        <b-form-checkbox id="switch_1" v-model="showEditor" switch class="ml-2 mr-n2 video-editor-toggle" v-if="includes('video-caption-edit')"></b-form-checkbox>
  		</div>

      <!-- video extent editor -->
      <vue-slider v-if="ceiling" :tooltip-placement="'bottom'" v-model="extent" :step="0.1" :min="floor" :max="ceiling" tooltip="always" :enable-cross="false">
        <template v-slot:tooltip="{ value }">
          <small class="custom-tooltip" :class="bound(value)" >{{ encode(value) }}</small>
        </template>
      </vue-slider>

    </div>

    <!-- captions editor -->
    <CaptionsEditor v-if="extent && captionEditor && showEditor" :extent="extent" :source="source" @captions-edited="captionsEdited" />

  </div>

</template>

<script>

import { includes } from '@/services/product.js'
import CaptionsEditor from './CaptionsEditor'

import moment from 'moment'

const encode = (value) => {
  const val = moment.utc(moment.duration((value||0),'seconds').as('milliseconds')).format('H:m:ss')
  return val.startsWith('0:')? val.substring(2) : val
}

export default {

  name: 'VideoEditor',

  data() {
    return {
      floor: 0,
      ceiling: 0,
      video: false,
      isClip: false,
      extent: false,
      originalClip:{},
      originalExtent:false,
      modified: -1,
      zoomed:false,
      showEditor: false,
      includes: includes,
      captionEditor:false,
      originalExtent:false
    }
  },
  props: {
    source: {
      type: Object,
      default: ''
    },
    post: {
      type: Object,
      required: true
    }
  },

  methods: {

    toggleZoom(ev) {
      console.log('toggleZoom',this.zoomed,this.floor,this.ceiling)
      ev.stopPropagation()
      ev.preventDefault()
      if ( !this.zoomed ) {
        this.originalCeiling = this.ceiling
        this.ceiling = Math.floor(this.extent[1]*1.1)
        this.floor = Math.floor(this.extent[0]*0.9)
      } else {
        this.ceiling = this.originalCeiling
        this.floor = 0
      }
      console.log('toggleZoom',this.zoomed,this.floor,this.ceiling)
      this.zoomed = !this.zoomed
    },

    reset(ev) {
      ev.stopPropagation()
      ev.preventDefault()
      this.extent = this.originalExtent
      this.modified = -1
    },

    removeAttachment() {
      this.$emit('detached',this.source)
    },

    encode(value) {
      const result = encode(value)
      return result
    },

    bound(value) {
      const base = 'custom-tooltip'
      const bound = value === this.extent[0] && value === this.extent[1]? 'upper' : value === this.extent[0] ? 'lower' : 'upper'
      return `${base}-${bound}`
    },

    canPlay() {
      //console.log('canPlayHandler')
    },

    captionsEdited(captions) {
      this.$emit('captions-edited',captions)
    },

    timeUpdate(ev) {
      if ( this.video.currentTime < this.extent[0] ) {
        this.video.currentTime = this.extent[0];
      }
      if ( this.video.currentTime >= this.extent[1] ) {
        this.video.pause()
        this.video.currentTime = this.extent[0]
      }
    }

  },

  mounted() {
    this.video = this.$refs.videoPlayer
    this.video.currentTime = this.extent? this.extent[0] : 0
    this.video.addEventListener('canplay', this.canPlay);
    this.video.addEventListener('timeupdate',this.timeUpdate)
  },

  created() {
    const source = this.source
    if ( this.post.status === 'DT' && source.clip && source.clip.tStart && source.clip.duration ) {
      const ceil = Math.floor(source.duration/1000)
      const start = moment.duration(this.source.clip.tStart).asSeconds()
      const end = start + moment.duration(this.source.clip.duration).asSeconds()
      this.extent = [start,end]
      this.originalExtent = [start,end]
      this.ceiling = ceil
      this.captionEditor = includes('video-caption-edit')
    }
  },

  watch: {

    extent: {

      // watch paddle changes and if necessary adjust video start/duration
      handler() {

        console.log(this.extent[0],this.extent[1])

        if ( this.video.currentTime < this.extent[0] ) {
          this.video.currentTime = this.extent[0]
        }

        if ( this.video.currentTime > this.extent[1] ) {
          this.video.currentTime = this.extent[1]
        }

        if ( this.video.paused ) {
          this.video.currentTime = this.extent[0]
        }

        this.source.clip.tStart = moment.utc(this.extent[0]*1000).format('HH:mm:ss')
        this.source.clip.duration = moment.utc((this.extent[1]-this.extent[0])*1000).format('HH:mm:ss')

        this.modified = this.originalExtent.toString() !== this.extent.toString()

      },

      deep:true

    }

  },

  components: {
    CaptionsEditor
  }

}

</script>

<style lang="scss" >

/* .video-extent-text padding-top occupies 3px hence 17 vs 20 padding top */
.video-editor-v2 .video-extent {
  padding: 17px 0px 14px 0px;
}

.video-editor-v2 .video-extent .video-extent-text {
  font-size: 13px;
  font-weight: bold;
  padding-top: 3px;
}

.video-editor-v2 .caption-reset a {
  color: #008CFF!important;
}

.video-editor-v2 .video-extent .video-editor-toggle {
  padding-bottom: 3px;
}

.video-editor-v2 .custom-tooltip {
  margin:0px;
  padding:0px;
  font-size: 0.8em;
  float: left;
}

.video-editor-v2 .custom-tooltip-lower {
  padding-right:20px;
}

.video-editor-v2 .custom-tooltip-upper {
  padding-left:20px;
}

.video-editor-v2 .caption-helper {
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 14px;
}

.video-editor-v2 .caption-reset{
  margin-left: 10px;
}

.video-editor-v2 .caption-helper-switch-container {
  display:inline!important;
  padding:0px!important;
}

.video-editor-v2 .caption-helper-switch {
  display:inline!important;
  padding:0px!important;
  width:200px;
}

.video-editor-v2 video {
  max-width: 70%;
}


</style>
