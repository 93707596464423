<template>

  <div class="row">

    <b-modal id="set-user-password-modal" title="Set User Password" v-model="setPassword.show" hide-footer no-close-on-backdrop>

        <template #default="">

          <b-form>

            <b-form-group>
              <label>New password</label>
              <b-input type="password" v-model="setPassword.pwd" />
              <small>At least 8 characters, alpha-numeric or symbols</small>
            </b-form-group>
            <b-form-group>
              <label>Confirm new password</label>
              <b-input type="password" v-model="setPassword.confirm" />
              <small>At least 8 characters, alpha-numeric or symbols</small>
            </b-form-group>
            <p class="text-danger">{{setPassword.msg}}</p>
            <span class="float-right">
              <b-button @click="setPassword.show=false" variant="outline-default">Cancel</b-button>
              <b-button @click="completeSetPassword()" variant="outline-success">SUBMIT</b-button>
            </span>

          </b-form>

        </template>

    </b-modal>

    <div class="col-md-11 admin-section-heading">

      <b-form>

      <h3> {{user? user._id? 'Edit User' : 'Create User' : 'Manage Users' }}

          <!--
          <small v-if="!user" class="float-right">
            <b-button-group>
              <b-button size="sm" :variant="paging.filter === 'all'? 'success':'transparent'" @click="filter('all')">All</b-button>
              <b-button size="sm" :variant="paging.filter === 'subscribed'? 'success':'transparent'" @click="filter('subscribed')">Subscribed</b-button>
              <b-button size="sm" :variant="paging.filter === 'unsubscribed'? 'success':'transparent'" @click="filter('unsubscribed')">Unsubscribed</b-button>
            </b-button-group>
          </small>
          -->

          <span v-if="user" class="float-right">

            <b-button @click="setPassword.show=true" type="button" variant="default">Reset Password</b-button>

            <b-button v-if="user.state=='complete'" @click="impersonate(user)" type="button" variant="default">Login As</b-button>
            <b-button @click="user=false" type="reset" variant="default">Cancel</b-button>
            <b-button v-if="user.subscription.version =='V2' && !user.subscription.customerId && user.email.endsWith('@lately.ai')" @click="removeUser(user)" type="button" variant="danger">Delete</b-button>            
            <b-button @click="updateUser(user)" type="submit" variant="primary">SUBMIT</b-button>
          </span>

          <span v-if="!user" class="float-right" style="width:50%">
            <div class="btn-group" style="width:400px">
              <b-button @click="toggleUserVersion('V1')" :variant="paging.version.includes('V1')? 'primary':'secondary'">Legacy</b-button>
              <b-button @click="toggleUserVersion('V2')" :variant="paging.version.includes('V2')? 'primary':'secondary'">NoCC</b-button>        
              <b-form-input style="margin-top:5px" v-model="paging.search" type="search" placeholder="Search Users" debounce="1000" @input="query()"></b-form-input>                    
            </div>   
          </span>

      </h3>

      </b-form>

      <hr>

      <p v-if="errorMessage" class="text-danger">{{errorMessage}}</p>

      <!--
      user EDITOR - TODO - COMPONENTIZE
      -->
      <div v-if="user">
          <button type="submit" style="display:none" />

          <!--title-->
          <div class="row">

            <b-form-group class="col-md-8"
              label="Email"
            >
              <b-form-input
                readonly
                v-model="user.email"
                type="text"
              ></b-form-input>

            </b-form-group>

            <b-form-group class="col-md-4"
              label="Registration"
            >
              <b-form-input
                readonly
                v-model="user.state"
                type="text"
              ></b-form-input>

            </b-form-group>

          </div>

          <!--Roles
          TODO - TURN THESE INTO CHIPS
          -->
          <div class="row">

            <b-form-group
              label="Roles"
            >
              <b-form-input
                v-model="user.roles"
                type="text"
              ></b-form-input>

            </b-form-group>

          </div>

          <!--Flags
          TODO - TURN THESE INTO CHIPS
          -->
          <div class="row" >

            <b-form-group>
              <label>Flags
                <b-dropdown size="sm" variant="link" no-caret text="Add Flag" v-if="availableFlags.length">
                  <b-dropdown-item v-for="(flag,idx) in availableFlags" :key="idx" @click="addFlag($event,flag)">{{flag.label}}</b-dropdown-item>
                </b-dropdown>
              </label>
              <ChipEditor prompt=""
                :format="tooltipForFlag"
                :chips="user.flags"
                :editable="true"
                :onRemove="removeFlag" />

            </b-form-group>

          </div>

        </b-form>

      </div>

      <!--
      user LIST
      -->
      <div v-else>

        <table class="admin-table table table-striped table-hover">
          <thead>
            <tr>
              <td>
                <a @click="toggleSort('createdAt')" href="#">
                  Created
                  <i v-if="paging.sort.includes('createdAt')" class="fa" :class="{'fa-caret-up':paging.sort.startsWith('+'),'fa-caret-down':paging.sort.startsWith('-') }" />
                </a>
              </td>
              <td>Email</td>
              <td>Plan</td>
              <td>CustomerId</td>              
              <td width="20%">
                  Status
              </td>
              <td>
                  Onboarding
              </td>              
              <td></td>
            </tr>
          </thead>
          <tbody>

            <tr v-for="user in users" :key="user._id" @click="selectUser(user)">
              <td>{{user.createdAt|date('MMM DD, YYYY')}}</td>
              <td>{{user.email}} {{user.roles.includes('admin')?'(admin)':''}}</td>
              <td>
                <span v-if="user.subscription && user.subscription.product && user.subscription.price">
                  {{user.subscription.product.name}} - ${{user.subscription.price.amount}} {{user.subscription.price.interval}}ly
                </span>
              </td>
              <td>
                <span>{{  user.subscription.customerId||'' }} </span>
              </td>              
              <td>
                <span>{{  subscriptionStatus(user) }} </span>
              </td>
              <td>
                <span v-if="user.subscription.version==='V2'"> {{ user.onboarding && user.onboarding.publisher && user.onboarding.generator? 'Complete' : user.onboarding.publisher? 'Awaiting Generator' : 'Awaiting Publisher'}}</span>
                <span v-else></span>
              </td>              
            </tr>
          </tbody>
        </table>

        <!-- pagination -->
        <Pagination :paging="paging" @on-nav="nav" />

      </div>

    </div>
  </div>
</template>

<script>

'use strict'

import ChipEditor from '@/components/ChipEditor'
import Pagination from '@/components/Pagination'

import { actions,store } from '@/services/store'
import User from '@/services/user'

import moment from 'moment'

export default {

  name: 'users',

  data() {
    return {
      users:[],
      user:false,
      search: '',
      version: ['V1','V2'],
      errorMessage:'',
      availableFlags:[],
      paging: {
        page: 0,
        skip: 0,
        pages: 0,
        limit: 50,
        search:'',
        filter: '',
        version: ['V1','V2']
        ,sort:''
      },
      setPassword: {
        show: false,
        pwd: '',
        confirm:'',
        msg: ''
      }
    }
  },

  components: {
    Pagination,
    ChipEditor
  },

  async created() {
    this.query()
  },

  methods: {

    /**
     * Allow removal of non-subscription users 
     * @param {*} user 
     */

    async removeUser(user) {
      if ( window.confirm(`Permanently removing user with email '${user.email}' will also remove associated dashboards, sessions and content. They are not currently a Stripe customer. Proceed?`)) {
        try {
        await actions.removeUser(user)
        this.users = this.users.filter((u)=>{return u._id!==user._id})
        this.user = undefined
        } catch ( err ) {
          this.$toasted.error(err.message)
        }
      }
    },

    toggleUserVersion(v) {
      let idx = this.paging.version.indexOf(v)
      if ( idx === -1 ) {
        this.paging.version.push(v)
      } else this.paging.version.splice(idx,1)
      this.query()
    },

    subscriptionStatus(user) {
      let subscription = (user.subscription||{})
      let onboarding = (user.onboarding||{})
      if ( subscription.version === 'V2' ) {
        if (subscription.status === 'trialing' && !onboarding.generator ) {
          return 'NoCC trial awaiting onboarding'
        } else if ( subscription.status === 'trialing' ) {
          let expiry = moment(subscription.trialEnd).format('YYYY-MM-DD')
          return expiry < new Date()? `NoCC trial expired ${expiry}` : `NoCC trial expiring ${expiry}`
        } else return subscription.status
      } else return subscription.status 
    },

    addFlag(ev,flag) {
      ev.stopPropagation()
      ev.preventDefault()
      this.user.flags.push(flag.id)
      this.setAvailableFlags()
    },

    removeFlag(flag) {
      const idx = this.user.flags.indexOf(flag)
      if ( idx !== -1 ) this.user.flags.splice(idx,1)
      this.setAvailableFlags()
    },

    tooltipForFlag(flag) {
      return User.descriptionForFlag(flag)
    },

    setAvailableFlags() {
      this.availableFlags = User.availableFlags(this.user)
    },

    toggleSort(key) {
      if ( this.paging.sort === ('+'+key) ) {
        this.paging.sort = '-' + key
      } else if ( this.paging.sort === ('-'+key) )  {
        this.paging.sort = ''
      } else {
        this.paging.sort = '+' + key
      }
      this.query()
    },

    filter(state) {
      this.paging.filter = state
      this.query()
    },


    nav(page) {
      this.paging.page = page
      this.query()
    },

    query() {

      if ( this.timer ) {
        clearTimeout(this.timer)
        this.timer = false;
      }

      this.timer = setTimeout(this.queryImpl,1000)

    },

    async queryImpl() {

      try {
        // console.log('queryImpl', JSON.stringify(this.paging,0,1))
        const resp = await actions.fetchUsers(this.paging)
        this.paging.pages = Math.ceil(resp.count/this.paging.limit)
        resp.users.forEach((u)=>{u.roles=u.roles.join(' ')})
        this.users = resp.users;
      } catch(err){
        console.error(err)
        this.errorMessage = err.toString()
      } finally {
        this.timer = false
      }

    },

    async updateUser(user) {
      try {
        this.user = false;
        user.roles = user.roles.split(' ');
        await actions.updateUser(user)
        user.roles = user.roles.join(' ');
        const idx = this.users.findIndex((u)=>{return u._id==user._id})
        if ( idx != -1 ) this.users.splice(idx,1,user)
        this.$toasted.success(`User updated`)
      } catch( err ) {
        console.error(err)
      }
    },

    selectUser(user) {
      user.flags = user.flags || []
      this.user = JSON.parse(JSON.stringify(user));
      this.setAvailableFlags()
    },

    async completeSetPassword() {
      if ( this.setPassword.pwd.length < 8 ) {
        return this.setPassword.msg = 'Password must be > 8 chars'
      } else if ( this.setPassword.pwd !== this.setPassword.confirm ) {
        return this.setPassword.msg = 'Passwords do not match'
      } else {
        const resp = await actions.adminUpdatePassword(this.user._id,{newPwd:this.setPassword.pwd})
        .then((resp)=>{
          this.$toasted.success(`Password updated`)
          this.setPassword.show = false
          this.setPassword.pwd = ''
          this.setPassword.confirm = ''
          this.setPassword.msg = ''
        },(err)=>{
          console.error(err)
          this.$toasted.error(err.message)
        })
      }
    },

    async impersonate(user) {

        try {
            const resp = await actions.impersonateUser(user)
            if ( store.user.state === 'complete' ) {
              this.$router.push(`/${store.dashboard._id}/sources`)
            } else this.$router.push(`/app/signup?user=${user._id}`)
        } catch(err) {
            console.error(err)
        }


    }

  }

}
</script>

<style lang="scss" >

.user-actions i {
  padding:5px;
  float:right;
}

.admin-table thead tr td {
  border-top-width:0px;
}

</style>
