import { render, staticRenderFns } from "./VideoEditor.vue?vue&type=template&id=620c2a84&"
import script from "./VideoEditor.vue?vue&type=script&lang=js&"
export * from "./VideoEditor.vue?vue&type=script&lang=js&"
import style0 from "./VideoEditor.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports