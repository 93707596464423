<template>

  <div class="row" v-if="status==='transcribed'" :key="source._id">
    <div class="col-md-12 text-left">
      <b-textarea readonly rows="10" ng-if="transcript" v-model="transcript" />
    </div>
    <div class="col-md-12">
      <br>
      <span class="transcript-actions" v-if="!merging">
        <p class="text-primary">Click the 'Edit' button below to edit your transcript externally, then click 'Merge' to sync your edits back into Lately.
          <br>*Please note that it may take several minutes for your edits to be ready to merge into Lately.</p>
        <span class="float-right">
          <b-button variant="outline-success" v-b-tooltip.hover title="View and edit your transcript" target="new" :href="editorUrl">Edit Transcript</b-button>
          <b-button variant="outline-success" v-b-tooltip.hover title="Merge your transcript changes back into Lately" @click="mergeTranscript()">Merge Transcript</b-button>
          <b-button variant="primary" v-b-tooltip.hover title="Continue with this transcript" @click="resumeSession()">Continue Generating </b-button>
        </span>
      </span>
      <span class="transcript-actions" v-if="merging">
        <p class="text-primary">Please wait while we fetch and merge your edits, or click 'Cancel Merge' to stop waiting... <i class="fa fa-spinner fa-spin" /> </p>
        <span class="float-right">
          <b-button @click="cancelMerge()" variant="outline-success" v-b-tooltip.hover title="Stop waiting for merged results">Cancel Merge</b-button>
        </span>
      </span>
    </div>
  </div>

</template>

<script>

import { actions, getters } from '@/services/store'

export default {

  name: 'TranscriptView',

  data() {

    return {
      status:false,
      source:false,
      merging:false,
      transcript:false,
      editorUrl:false,
      errMessage: false
    }

  },

  props: {

    session: {
      type: Object,
      required:true
    }

  },

  async created() {

    console.log('temiTranscriptView.created',JSON.stringify(this.session.config.steps,0,1))

    try {

      const dashboard = getters.dashboard()
      this.source = await actions.fetchSource( dashboard._id, 'file', this.session.config.steps.source.source._id )

      if ( this.source && this.source.transcription ) {
        this.status = this.source.transcription.status
        this.editorUrl = this.source.transcription.editor_url
        this.transcript = this.source.transcription.transcript
      } else throw new Error('We were unable to locate this source')

    } catch ( err ) {
      console.error(err)
      this.$toasted.error(`Unable to locate session source`)
    }

  },

  destroyed() {
    this.cancelMerge()
  },

  methods: {

    cancelMerge() {
      if ( this.timer ) {
        clearTimeout(this.timer)
      }
      this.merging = false
    },

    async mergeTranscript() {

      try {
        this.timer = false
        this.merging = true
        const dashboard = getters.dashboard()
        const resp = await actions.mergeTranscript( dashboard._id, 'file', this.session.config.steps.source.source._id )

        if ( resp && resp.updated ) {
          setTimeout(()=>{
            this.transcript = resp.transcript
            this.merging = false
            this.$toasted.success(`Merged your edits :)`)
          })
        } else {
          this.timer = setTimeout( this.mergeTranscript, 3000 )
        }
      } catch (err) {
        this.cancelMerge()
        this.$toasted.error(`We're not able to sync your transcript at this time - please try again in a few minutes`)
      }
    },

    async resumeSession() {
      this.$emit('resume-session')
    }

  }

}
</script>

<style lang="scss" >

.transcript-actions {
  padding-top:5px;
}

.transcript-actions a {
  margin:5px;
}

.transcript-actions button {
  margin:5px;
}


</style>
