<template>
  <div>
    <ChipsMultiselect v-model="selections" :items='options' />
  </div>
</template>

<script>

import ChipsMultiselect from 'v-chips-multiselect'

export default {

  name: 'Chips',

  data() {
    return {
      updates: 0,
      options: this.choices.slice().map((c)=>c.title),
      selections: this.initial.slice()
    }
  },

  props: {
    initial: {
      type: Array,
      required:true
    },
    choices: {
      type: Array,
      required:true
    }
  },

  methods: {
    fetchSelections() {
      return this.selections.slice()
    }
  },

  watch: {
    selections() {
      // workaround for widget not preserving initial model
      if ( !this.updates++ ) {
        this.selections.push.apply( this.selections, this.initial )
      }
    }
  },

  components: {
    ChipsMultiselect
  }

}
</script>

<style lang="scss" >

h1.msg {
  color: $gray-900;
}
</style>
