<template>
  <div class="row member">
    <div class="col-md-2 member-email">
      <span>{{participant.user.email}}</span>
    </div>
    <div class="col-md-6 member-status">
      <small v-if="participant.type=='invitation'">
        <a href="#" @click="copy($event)" class="text-info"> <i class="fa fa-link"/>Copy Link</a>
        <a href="#" @click="resend($event)" class="text-info"> <i class="fa fa-envelope" /> Resend Invitation</a>
      </small>
      <b-badge pill :variant="status.cls">{{status.label}}</b-badge>
    </div>
    <div class="col-md-3 member-abilities">
      <span>Can {{abilities}}</span>
    </div>
    <div class="col-md-1 owner-actions">
      <b-button v-if="isOwner" size="sm" :disabled="!isOwner || participant.type==='owner'" @click="remove()" variant="light"><i class="fa fa-lg fa-trash" /></b-button>
    </div>
    <div class="col-md-12">
      <hr>
    </div>
  </div>
</template>

<script>

import { actions, getters } from '@/services/store'

export default {

  name: 'MemberView',

  data() {
    return {
      actions:[],
      abilities:[],
      isOwner:false,
      status: {
        label:'',
        cls:''
      },
    }
  },

  props: {
    participant: {
      type: Object,
      required:true
    }
  },

  created() {

    const user = getters.user()
    this.isOwner = getters.isOwner()
    const dashboard = getters.dashboard()

    // setup user abilities
    let abilities = [], perms = this.participant.permissions
    if ( perms.create ) abilities.push('generate')
    if ( perms.approve ) abilities.push('edit')
    if ( perms.execute ) abilities.push('publish')
    abilities = abilities.join(', ')

    // replace last occurrence of ',' with '&'
    const idx = abilities.lastIndexOf(', ')
    if ( idx !== -1 ) this.abilities = `${abilities.substring(0,idx)} & ${abilities.substring(idx+1)}`
    else this.abilities = abilities

    // setup status
    if ( this.participant.user.email === user.email ) {
      this.status.label = 'You'
      this.status.cls = 'secondary'
    } else if ( this.participant.type === 'member' ) {
      this.status.label = 'Active'
      this.status.cls = 'success'
    } else if ( this.participant.type === 'invitation' ) {
      this.status.label = 'Invite Pending'
      this.status.cls = 'primary'
    }

  },

  methods: {

    async resend(ev) {
      try {
        ev.stopPropagation()
        ev.preventDefault()
        const dashboard = getters.dashboard()
        await actions.resendInvitation( dashboard._id, this.participant._id )
        this.$toasted.success('Invitation resent!')
      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      }
    },

    copy(ev) {

      const dashboard = getters.dashboard()
      const origin = window.origin
      const relative = `/${dashboard._id}/invitation/${this.participant._id}`

        ev.stopPropagation()
        ev.preventDefault()

        navigator.clipboard.writeText(`${origin}/#${relative}`).then(() => {
          this.$toasted.success("Link copied to clipboard");
        });

    },

    async remove() {
      try {
        const dashboard = getters.dashboard()
        await actions.removeMember( dashboard._id, this.participant.user.email )
        this.$emit('member-removed',this.participant)
      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      }
    }
  }
}
</script>

<style lang="scss" >

.member-status {
  text-align: right;
}

.member-status a {
  color: #008CFF;
  font-family: Nunito Sans;
  font-size: 15px;
  padding-right: 18px;
}

.member-status a i {
  padding-right: 2px;
}

.member-abilities {
  text-align: right;
}

.owner-actions {
  text-align: right;
}

</style>
