<template>

  <div class="list-item-actions" v-if="post">

    <p class="text-danger" v-for="error in errors">
      <small>{{error.message}}</small>
    </p>

    <p class="text-left actions" v-if="!busy">
      <a v-if="partner === 'lately' && post.status !== 'T' && post.status !== 'P' && !errors.length" variant="link" class="primary" @click="onAction('send')">Publish to {{post.contentType|channelname|titlecase}}</a>
      <a v-if="partner === 'lately' && post.status !== 'P' && post.publishAt && !errors.length" variant="link" class="primary" @click="onAction('reschedule')">Reschedule</a>
      <a v-if="partner === 'lately' && post.status !== 'P' && !post.publishAt && !errors.length" variant="link" class="primary" @click="onAction('self-schedule')">Schedule</a>
      <a v-if="partner !== 'lately' && post.status !== 'T' && post.status !== 'P' && !errors.length" variant="link" class="primary" @click="onAction('send')">Send to {{partner|titlecase}}</a>
      <a v-if="post.status !== 'T' && post.status !== 'P'" variant="link" class="default" @click="onAction('edit')">Edit</a>

      <b-dropdown ref="picker" class="clone" variant="link" toggle-class="text-decoration-none" no-caret v-if="channels.length && includes('content-clone')">
        <template #button-content>
          <a variant="link" class="default">Clone</a>
        </template>
        <b-dropdown-header>
          <b>Select a channel to clone your post into</b>
        </b-dropdown-header>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-for="(channel,idx) in channels" @click="onAction('clone',{channel})" :key="idx">
          <span class="channel">
            <i class="fa-brands " :class="iconFor(channel.type)" :style="{color:colorFor(channel.type)}"/>            
            <!--
            <b-img :src="channel.content.logo" height="20" rounded="circle"></b-img>
            -->
            <span>{{channel.content.name}}</span>
          </span>
        </b-dropdown-item>
        <!--
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>
          <span class="float-right clone-actions">
          <b-button size="sm" variant="default" @click="onAction('cancel-clone')">Cancel</b-button>
          <b-button size="sm" variant="primary" @click="onAction('clone',{selectedChannels})">Clone</b-button>          
          </span>
        </b-dropdown-item>
        -->
      </b-dropdown>

      <a v-if="!publishing" variant="link" class="default" @click="onAction('remove')">Trash</a>
    </p>

  </div>

</template>

<script>

import { includes } from '@/services/product'
import { media } from '@/services/constants'
import { getters } from '@/services/store'

export default {
  name: 'Actions',
  data() {
    return {
      msg:'',
      includes,
      actions:[],
      busy:false,
      partner:'',
      sendMessage:'',
      channels:[],
      selectedChannels:[]
    }
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      default: false
    },
    publishing: Boolean
  },

  watch: {
    'post.attachments'() {
      try {
      this.busy = this.post.attachments.filter((a)=>{
        return a.mimetype && a.mimetype.startsWith('video') && a.clip && a.clip.tStart && a.clip.duration && a.status !== 'completed'
      }).length
      } catch( err ) {
        console.error('caught', err)
      }
    },
  },

  created() {

    let dashboard = getters.dashboard()
    let channels = dashboard.channels.filter((ch)=>{ return ch.auth && ch.content } ) // ch.type === this.post.contentType && ch.content.id !== this.post.channel })
    this.channels = channels.sort((ch1,ch2)=>{return ch1.type.localeCompare(ch2.type)})

    this.partner = getters.partner()
    this.sendMessage = this.partner === 'lately'? 'Publish' : 'Send'

    this.busy = this.post.attachments.filter((a)=>{
      return a.mimetype && a.mimetype.startsWith('video') && a.clip && a.clip.tStart && a.clip.duration && a.status !== 'completed'
    }).length

    if ( this.post.publish_err && this.post.publish_err.includes('401') && this.post.channel_meta ) {
      this.msg = 'Please reconnect this channel.'
      this.actions.unshift({
        id:'send',
        text: 'RESEND',
        variant: 'link',
        title:`Send this post to ${this.post.channel_meta.partner}`
      })
    } else if ( this.post.status === 'D' || this.post.status === 'F' && this.post.channel_meta ) {
      this.msg = this.post.publish_err || this.post.err
      this.actions.unshift({
        id:'send',
        text: 'RESEND',
        variant: 'link',
        title:`Send this post to ${this.post.channel_meta.partner}`
      })
    }
  },
  methods: {

    iconFor(type) {
      if ( type === 'twitter' ) return 'fa-square-x-twitter' 
      else return `fa-${type}`
    },

    colorFor(type) {
      const defn = media[type]
      return defn? defn.color : 'white'
    },

    toggleSelection(channel,ev) {
      let idx = this.selectedChannels.indexOf(channel)
      if ( idx < 0 ) this.selectedChannels.push(channel)
      else this.selectedChannels.splice(idx,1)
      this.$refs.picker.show(true)
    },

    onAction(action,args) {
      this.$emit('on-action', {
        action,
        post: this.post,
        args
      })
    }
  }
}
</script>

<style lang="scss" >

.list-item-actions {

  .actions {
    a {
      margin-right: 28px;
    }
    a.default {
      color: #ababab!important;
    }
    a.primary {
      color: #008CFF;
    }

    .clone {
      hr {
        margin:8px;
      }      
      button {
        margin-top: -3px;
        padding: 0px;
        border: 0px;
      }
      .channel {
        i {
          margin-right: 5px;
        }
        img {
          margin-top: -3px;
          margin-right: 3px;
        }
        span: {
          padding-top: 4px;
        }
      }
      .clone-actions {
        button {
           margin: 5px 10px 5px 10px;
           padding: 5px;
        }
      }
    }
  }
}

</style>
