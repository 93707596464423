<template>

  <div class="row goal-trackers" v-if="ready">

    <!-- heading / interval picker -->
    <div class="col-md-12">
      <h5 class="goal-interval-picker">
        <span class="float-left">
          <b-dropdown variant="outline-default" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <h5 class="goal-interval">
                <a href="#">{{(interval=='day'?'dai':interval)|titlecase}}ly</a> Goals
              </h5>
            </template>
            <b-dropdown-item v-if="interval!='day'" @click="setInterval('day')">Daily</b-dropdown-item>
            <b-dropdown-item v-if="interval!='week'" @click="setInterval('week')">Weekly</b-dropdown-item>
            <b-dropdown-item v-if="interval!='month'" @click="setInterval('month')">Monthly</b-dropdown-item>
            <b-dropdown-item v-if="interval!='year'" @click="setInterval('year')">Yearly</b-dropdown-item>
          </b-dropdown>
        </span>
        <small class="float-right goal-interval-settings">
          <router-link :to="{ name:'Goals', dashboardId:dashboard._id }" href="#">
            <i v-b-tooltip.hover title="Set Goals" class="fa fa-cog fa-lg" />
          </router-link>
        </small>
      </h5>
    </div>

    <!-- goals list -->
    <div class="col-md-12 goal-feature">
      <div class="goal-feature-view" v-for="feature in features" :key="feature.id" v-if="feature.id === 'text-generate' || feature.id === 'video-generate' || feature.id == 'send-third-party' || feature.id == 'generate-export'">

        <p>{{feature.label}}</p>

        <div v-if="feature.goal">
          <b-progress :value="feature.goal.all.progress" :max="feature.goal.all.max" variant="success"></b-progress>
          <p>Total {{feature.goal.all.progress}} / {{feature.goal.all.max}}
            <small class="float-right" v-if="feature.goal.all.progress">
              <a @click="toggleDetails(feature.id)" href="#">{{details[feature.id]? 'Hide':'Details'}}</a>
            </small>
          </p>
        </div>
        <br>

        <div class="row goal-detail" v-if="details[feature.id] && feature.goal">

          <!-- TODO - componentize these views -->
          <div class="col-md-12">
            <div v-if="feature.goal.twitter.progress">
              <b-progress class="dash-progress twitter-progress" :value="feature.goal.twitter.progress" :max="feature.goal.twitter.max" ></b-progress>
              <p>Twitter  {{feature.goal.twitter.progress}} /  {{feature.goal.twitter.max}}</p>
            </div>
            <div v-if="feature.goal.facebook.progress">
              <b-progress class="dash-progress facebook-progress" :value="feature.goal.facebook.progress" :max="feature.goal.facebook.max" ></b-progress>
              <p>Facebook   {{feature.goal.facebook.progress}} /  {{feature.goal.facebook.max}}</p>
            </div>
            <div v-if="feature.goal.linkedin.progress">
              <b-progress class="dash-progress linkedin-progress" :value="feature.goal.linkedin.progress" :max="feature.goal.linkedin.max" ></b-progress>
              <p>Linkedin   {{feature.goal.linkedin.progress}} /  {{feature.goal.linkedin.max}}</p>
            </div>
            <div v-if="feature.goal.instagram.progress">
              <b-progress class="dash-progress instagram-progress" :value="feature.goal.instagram.progress" :max="feature.goal.instagram.max" ></b-progress>
              <p>Instagram  {{feature.goal.instagram.progress}} /  {{feature.goal.instagram.max}}</p>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { actions, getters } from '@/services/store'

export default {

  name: 'Goals',

  data() {
    return {
      ready: false,
      product: false,
      details: false,
      features: false,
      interval: 'week',
      dashboard: false
    }
  },

  methods: {

    toggleDetails(id) {
      this.details[id] = !this.details[id]
    },

    toggleEdit(ev,feature) {
      ev.stopPropagation()
      ev.preventDefault()
      this.editing = this.editing? false : feature
    },

    setInterval(interval) {
      this.interval = interval;
      this.fetchActivity()
    },

    async fetchActivity() {
      this.ready = false;
      const activity = await actions.fetchActivity(this.$router.currentRoute.params.dashboardId,this.interval)

      this.features.forEach((f)=>{

        // find corresponding goal
        const g = this.dashboard.goals.find((g)=>{return g.id===f.id}) || {
          id:f.id,
          progress: 0,
          min: 0,
          max: 12
        }

        // find corresponding activity
        const a = activity.find((aa)=>{ return aa.name === f.id }) || {
          count: {
            all: 0,
            twitter: 0,
            facebook: 0,
            linkedin: 0,
            instagram: 0
          }
        }

        // annotate the feature with goal and progress
        if ( a && g ) {

          const entryFor = (key) => {
            return {
              progress: a.count[key],
              min: g[this.interval]? g[this.interval][key].val[0] : 0,
              max: g[this.interval]? g[this.interval][key].val[1] : 12 // see todo above
            }
          }

          f.goal = {
            all: entryFor('all'),
            twitter: entryFor('twitter'),
            facebook: entryFor('facebook'),
            linkedin: entryFor('linkedin'),
            instagram: entryFor('instagram')
          }

        } else {
          console.log('unable to find goal for feature', f.id, a, g )
        }

      })
      this.ready = true;
    }

  },

  async created() {

    this.dashboard = getters.dashboard()
    this.product = getters.product()
    this.features = this.product.limits
    this.details = this.features.reduce((acc,f,idx)=>{
      acc[f.id] = !idx;
      return acc;
    },{})
    this.fetchActivity()
  },

}
</script>

<style lang="scss" >

.goal-feature-view {
  padding-top:5px;
}

.goal-interval-picker {
  width: 100%;
  margin:0px;
}

.goal-interval-picker button {
  padding:0px;
}

.goal-interval-settings {
  font-size: 0.6em;
  margin-top:10px;
}

.goal-feature p {
  margin:0px;
}

</style>
