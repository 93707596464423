<template>
  <div class="static-preview">
    <p v-if="post.channel_meta" class="text-left">
      <ChannelIcon size="lg" :type="post.contentType" />
      {{post.channel_meta.name}}
    </p>
    <p>{{post.content}}</p>
    <Attachment v-for="(attachment,idx) in post.attachments" :key="idx" :post="post" :attachment="attachment" :policy="policy" />
  </div>
</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import Attachment from '@/components/Attachment'

export default {
  name: 'HelloWorld',
  props: {
    post: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    }
  },
  components: {
    ChannelIcon,
    Attachment
  }
}
</script>

<style lang="scss" >
.static-preview {
  padding: 15px;
  p {
    margin: 0px;
  }
  .channel-icon {
    margin-left: -5px!important;
  }
}
</style>
