<template>

  <div class="row" >

    <div class="col-md-12">

      <h4  class="goal-title">
        {{goal.label}}
      </h4>
      <p>{{goal.description}}</p>

      <div  >

        <p class="text-right">Use common settings for all channels
        <small class="float-right" @click="noop($event)">
          <b-form-checkbox size="md" v-model="goal[scope].all.checked" name="check-button" switch />
        </small>
        </p>

        <div v-if="goal[scope].all.checked">

          <GoalPicker :goal="goal" :scope="scope" channel="all" msg="All channels"/>

        </div>

        <div v-if="!goal[scope].all.checked">

          <GoalPicker :goal="goal" :scope="scope" channel="twitter" />

          <GoalPicker :goal="goal" :scope="scope" channel="facebook" />

          <GoalPicker :goal="goal" :scope="scope" channel="instagram" />

          <GoalPicker :goal="goal" :scope="scope" channel="linkedin" />

        </div>

      </div>
    </div>

  </div>

</template>

<script>

import GoalPicker from './GoalPicker'

export default {
  name: 'GoalsPicker',
  data() {
    return {
      ready: false,
      model: false
    }
  },

  created() {
    this.model = this.goal[this.scope]
  },

  methods: {

    noop(ev) {
      ev.stopPropagation()
    }

  },

  watch: {

    // sync model.all changes with other channels
    'model.all': {
      handler() {
        const sync = (keys) => {
          keys.forEach((key)=>{
            this.model[key].max = parseInt(this.model.all.max)
            this.model[key].val[0] = this.model.all.val[0]
            this.model[key].val[1] = this.model.all.val[1]
          })
        }
        sync(['facebook','twitter','instagram','linkedin'])
      },
      deep:true
    }

  },

  props: {

    goal: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    }

  },

  components: {
    GoalPicker
  }
}
</script>

<style lang="scss" >

</style>
