<template>

  <div class="container-fluid drafts-view nomargin nopad">
    <Content :config="config" />
  </div>

</template>

<style lang="scss">
.drafts-view {
  height:100vh!important;
}
</style>

<script>

import Content from '@/components/Content'
import { getters } from '@/services/store'

export default {

  name: 'Drafts',

  data() {
    return {
      config: {
        header: {
          title: 'Queue',
          refreshTitle: "Some Drafts may still be in progress",          
          views: [{
            id:'list',
            title:'List View'
          }]
        },
        actions: [{
          id:'remove',
          partner: '*',
          float: 'right',
          title: {
            prefix: 'DELETE',
            suffix: 'DRAFTS',
          },
          variant:'outline-secondary'
        },{
          id:'schedule',
          float:'right',
          cls: 'primary',
          variant:'primary',
          partner:'lately',
          title: {
            prefix: 'SCHEDULE',
            suffix: 'DRAFTS',
            float: 'right',

          },
        }],
        filter: {
          channels: {
            value:'*',
            editable:true
          },
          status:{
            value: 'D',
            editable:false,
            checked:true,
            initial:'D'
          }
        }
      }
    }
  },

  created() {
    this.config.title = getters.partner() ==='lately'? 'Scheduler':'Drafts'
  },

  components: {
    Content
  }

}
</script>

