<template>

  <div class="container-fluid analytics">

    <div class="row title">
      <div class="col-md-12">
        <div class="container nopad">
        <h4><b>Analytics</b></h4>
        </div>
      </div>
    </div>

    <div class="container nopad content">
    
      <div class="row">
        <div class="col-md-12">

          <b-form inline style="float-right">

            <div class="analytics-date">
              <span>From: </span><b-form-datepicker id="from-datepicker" v-model="from" class="mb-2"></b-form-datepicker>
            </div>

            <div class="analytics-date">
              <span>To: </span><b-form-datepicker id="to-datepicker" v-model="to" class="mb-2"></b-form-datepicker>
            </div>

            <b-button-group class="pull-right analytics-source-selection">
              <b-button variant="primary" @click="submit()">Update</b-button>
            </b-button-group>

          </b-form>

        </div>
        
      </div>

      <b-overlay :show="busy">

        <div class="row analytics-row">
          <div class="col-md-12 overall-stats-chart">
            <OverallStatsChart :stats="raw" :from="from"/>
          </div>
        </div>        

        <div class="row analytics-row">
          <div class="col-md-12 overall-stats-table">
            <OverallStatsTable :stats="raw" :from="from"/>
          </div>
        </div>    
      

        <div class="row analytics-row">
          <div class="col-md-12 top-posts">
            <TopPosts :stats="raw" :from="from"/>
          </div>
        </div>     
      </b-overlay>

    </div>

  </div>

</template>

<style lang="scss" >

.analytics {

  height: 100vh!important;

  .title {
    background-color: #20C763;
    font-size: 20px;
    margin-top: 30px;
    padding: 30px 0px 30px 0px;
    color: white;
    h4 {
      font-size: 40px;
    }
  }

  .content {
    margin-top: 20px;
  }

  .analytics-date {
    display: inline;
    margin-right: 10px;
    padding-top: 10px;
  }

  .analytics-chart {
    height: 100%;
  }

  .analytics-posts-sent {
    min-height: 400px;
    padding: 10px;
  }

  .analytics-row {
    margin-top: 20px;
    height: 100%!important;
  }

  .analytics-section {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .analytics-time-saved {
    background: #00c66b;
    color: white;
  }

  .analytics-source-selection {
    position: absolute;
    right: 20px;
  }

}

</style>

<script>

import { actions, getters } from '@/services/store'
import { includes } from '@/services/product'
import { ANALYTICS } from '@/services/constants'

import OverallStatsChart from './OverallStatsChart'
import OverallStatsTable from './OverallStatsTable'
import TopPosts from './TopPosts'

const moment = require('moment')

export default {

  name: 'Analytics',

  components: {
    OverallStatsChart,
    OverallStatsTable,
    TopPosts
  },

  data() {
    return {
      busy:true,
      to: false,
      from: false,      
      raw: undefined
    }
  },


  created() {
    this.to = moment().toDate(),
    this.from = moment().subtract(30,'days').format('YYYY-MM-DD')
    this.submit()
  },

  methods: {

    select(source) {
      this.selected = source
    },

    async submit() {

      this.busy = true
      this.raw = undefined

      try {

        const dashboard = getters.dashboard()

        // todo - filter by channels
        let channels = dashboard.channels
        .filter((ch) => {
          return ch.authed && ch.content && ch.content.id;
        })
        .map((ch) => {
          return {
            id: ch.content.id,
            type: ch.type,
            dashboardId: dashboard._id,
          };
        });

        let body = {
            channels,
            campaigns: [],
            type: "views",
            endDate: moment(this.to).format('YYYY-MM-DD'),
            startDate: moment(this.from).format('YYYY-MM-DD'),
        }; 

        // fetch analytics
        let results = await actions.fetchAnalytics2( dashboard._id, body )
        this.raw = results.pop().arrayOfAnalytics[0]
        this.busy = false

      } catch (err) {
        console.error(err)
        this.$toasted.error(`Apologies but we're not able to fetch your analytics at the moment`)
      }

    }

  }

}

</script>

