<template>

  <div class="row">

    <div class="col-md-9 d-flex justify-content-between channel-nav nopad text-left">

      <!-- channel picker -->
      <div class="btn-group channel-picker float-left" role="group">

        <b-button class="btn" @click="onChannelSelected(ch)" variant="light" :style="{'background-color':colorFor(ch), 'border': '1px solid ' + borderFor(ch) }" :class="{active:ch===channel}" v-for="(ch,idx) in channels" :key="idx">
          <span class="fa-stack fa-lg channel-icon-v2">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i :class="'fab fa-stack-1x fa-inverse fa-'+iconFor(ch)" :style="{color:colorFor(ch)}"></i>
          </span>
          <span class="posts-remaining">{{postsRemaining[ch]}}</span>
        </b-button>

      </div>

      <!-- nav -->
      <div class="btn-group float-right post-picker" role="group">
        <b-button @click="onPrevNext(-1,$event)" type="button" class="btn" variant="light" :disabled="prevDisabled">
          <span class="btn-text"><i class="fa fa-sm fa-chevron-left" /> PREV</span>
        </b-button>
        <b-button @click="onPrevNext(1,$event)" type="button" class="btn" variant="light" :disabled="nextDisabled">
          <span class="btn-text">NEXT <i class="fa fa-sm fa-chevron-right" /></span>
        </b-button>
      </div>

    </div>
  </div>

</template>

<script>

import { media } from '@/services/constants'

export default {

  name: 'ChannelBtnNav',

  props: {
    postsRemaining: Object,
    dashboard: Object,
    session: Object,
    channels: Array,
    channel: String,
    content: Array,
    idx: Number
  },

  data() {
    return {
      showOriginals:false
    }
  },

  created() {
    this.showOriginals = this.showAIOriginals === '*'
  },

  methods: {

    onPrevNext(dir,ev) {
      ev.stopPropagation()
      ev.preventDefault()
      this.$emit('on-prev-next',dir)
    },

    onChannelSelected(ch) {
      this.$emit('on-channel-selected',ch)
    },

    colorFor(ch) {
      if ( this.channel === ch ) {
        return media[ch].color
      } else return 'white'
    },

    borderFor(ch) {
      if ( this.channel === ch ) {
        return media[ch].color
      } else return '#D6D6D6'
    },

    iconFor(ch) {
      switch(ch) {
        case 'facebook': return 'facebook-f'
        case 'linkedin': return 'linkedin-in'
        case 'twitter' : return 'square-x-twitter'
        default: return ch
      }
    }

  },

  computed: {

    prevDisabled() {
      return (this.idx<=0 || this.content.length === 0)
    },

    nextDisabled() {
      return (this.content.length===0 || this.idx >= this.content.length-1 )
    }

  }

}
</script>

<style lang="scss" >

 .channel-nav {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 10px 10px 0px 0px!important;
  box-shadow: 2px 2px 2px lightgrey;

  .channel-picker {
    border: 0px;

    .btn {
      opacity: 0.3;
      border-radius: 0px;
      padding: 8px 0px 8px 14px;
    }

    .btn.active {
      color:white!important;
      opacity: 1.0;
    }

    button:first-child {
      border-radius: 10px 0 0 0px;
    }

    .channel-icon-v2 {
      font-size: 18px;
      margin: opx;
      width: 36px;
    }

  }

  .genvariant-picker {
    padding-top: 18px;
    .video-editor-toggle {
      // margin:0px!important;
    }
  }

  .post-picker .btn.disabled {
    background-color: white;
    border-color: white;
  }

  .post-picker button {
    border: 0px!important;
    padding: 4px;
    background-color: white;
    margin: 8px 14px 0px 0px;
    font-size: 0.8em;
    font-weight: bold;
  }

  .posts-remaining {
    font-size:0.8em;
    padding: 14px 14px 14px 10px!important;
  }

}

</style>
