<template>
  <small class="float-right pagination">
    <b-button variant="transparent primary" :disabled="!paging.page" @click="nav(paging.page-1)">
      <i class="fa fa-chevron-left"/>
    </b-button>
    <span>
      Page
      <b-dropdown size="sm" no-caret variant="link" id="dropdown-1" :text="Math.min(paging.pages,paging.page+1).toString()">
        <b-dropdown-item @click="nav(page)" v-for="(page,idx) in pageList" :key="idx">{{page+1}}</b-dropdown-item>
      </b-dropdown>
      of {{paging.pages}}
    </span>
    <b-button variant="transparent primary" :disabled="paging.page == (paging.pages-1)" @click="nav(paging.page+1)">
      <i class="fa fa-chevron-right"/>
    </b-button>
  </small>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    paging: {
      type: Object,
      default: ()=>{
        return {
          skip: 0,
          page: 0,
          pages: 0,
          limit: 15,
          filter: '',
          sort: Session.get(SORT_KEY,'-publishAt'),
          sortLabel:'Most recent first'
        }
      }
    }
  },
  computed: {
    pageList() {
      return Array.from({length: this.paging.pages}, (_, i) => i )
    }
  },
  methods: {
    nav(dir) {
      this.$emit('on-nav',dir)
    }
  }
}
</script>

<style lang="scss" >

.pagination {
  font-size: 16px;
  span {
    padding-top: 5px;
  }
  .dropdown-toggle {
    margin-top: -2px;
    font-size: 24px;
    font-weight: bold;
  }
  button {
    margin: 5px;
  }
}
</style>
