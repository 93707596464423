


<!--
ONBOARDING WORKFLOW PER https://app.asana.com/0/1206858696947582/1208078754325060
-->

<template>
  <div class="onboarding-2 text-center" v-if="state">
    <!--
    <span><a @click="next(-1)">Back </a>{{ state }} <a @click="next(1)"> Next</a></span>
    -->
    <Start v-if="state===1" @onboarding-next="next"/>
    <Info v-if="state===2" @onboarding-next="next"/>
    <Connect v-if="state===3" @onboarding-next="next"/>
    <Model v-if="state===4" @onboarding-next="next"/>
  </div>
</template>

<style lang="scss" >
.onboarding-2  {

  margin-top: -48px;
  display: flex;
  min-height: 100vh;
  font-family: noto sans;  
  flex-direction: column;

  h3 {
    font-size: 30px;
    font-weight: bold;
  }  
  
  p {
    font-size: 18px;
    margin-top: 20px;
  }

  button {
    background-color: #20C763;
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    margin-top: 40px;
    border: 0px;
  }
}
</style>

<script>

import Connect from './connect'
import Start from './start'
import Model from './model'
import Info from './info'


import { getters } from '@/services/store'
import Logger from '@/services/logger'

let STATES = ['start','info','connect','model']

export default {
  name: 'Onboarding2',
  data() {
    return {
      state: 0
    }
  },
  created() {
    try {

      let user = getters.user()     
      let dashboard = getters.dashboard()  

      // get state from route. state=1 would be sufficient but need 1.x (random) 
      // to convince router that it's a different route  
      let state = parseInt(this.$router.currentRoute.query.state||'1.4')
      
      if ( !user.onboarding.publisher ) {
        this.state = state 
      } else if ( !user.onboarding.generator ) {
        this.state = 4 
      } 
      
      // dashboard has channels needing selection 
      else if ( dashboard.channels.find((ch)=>{
        return ch.needsSelection
      })) {
        this.state = 3
      } else { // all done
        let route = `/${this.$router.currentRoute.params.dashboardId}/start`
        this.$router.push(route)
      }

    } catch ( err ) {
      console.error(err)
    }
  },
  methods: {

    // update state and amplitude logging
    setState(state,log) {
      this.state = state
      if ( log ) {
        try {
          let step = STATES[this.state-1]
          Logger.onboardingStep(step)
        } catch ( err ) {
          console.error(err)
        }      
      }
    },
    next(dir) {

      if ( dir > 0 ) {
        if ( this.state <= 3 ) {
          this.setState(this.state += 1,true)
        } else {
          this.setState(1,false)
        }
      } else if ( this.state > 1 ) {
        setState(this.state - 1,true)
      }
      let query = this.$router.currentRoute.query
      query.state = this.state

      // updating to the same guarded route requires a unique key
      let state = (Math.random() + this.state).toString().substring(0,4)

      this.$router.push({ path: this.$route.path, query: { state: state }}).catch((err)=>{
        console.log('next caught',{route,err})
      })        

    }
  },
  components: {
    Start,
    Info,
    Connect,
    Model,
  }
}
</script>

