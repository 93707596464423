import { actions, getters } from '@/services/store'

class BulkActions {
    /**
     * Bulk remove posts, updating session.postsRemaining and content
     * @param {} posts
     * @param {*} dashboard
     * @param {*} session
     * @param {*} content
     * @returns
     */
    static async remove(posts = [], session, content) {
        let dashboard = getters.dashboard()

        let ids = posts.map((p) => {
            return p._id
        })
        await actions.removePosts(dashboard._id, ids)

        for (var p of posts) {
            let idx = content.findIndex((p2) => {
                return p2._id === p._id
            })
            content.splice(idx, 1)
        }

        let post = posts[0]
        let type = post.contentType === 'temporary' ? post.channel : post.contentType
        session.postsRemaining[type] -= posts.length
        session.postsRemaining.all -= posts.length
        return posts.length
    }
}

export default BulkActions
