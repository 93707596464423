<template>
  <div class="container onboard-page">

    <div class="row onboard-page-header">
      <div class="col-10 offset-1">
        <img src="@/assets/onboarding/onb_connected.png"/>
      </div>
      <div class="col-10 offset-1">
        <span class="title" v-if="stats.status==='COMPLETED'">Woohoo! Lately just created your very own custom writing model!</span>
        <span class="title" v-if="stats.status!=='COMPLETED'">Just a moment while we build your custom writing model...</span>
        <p class="description">We also analyzed your posting data from the channels you just connected and have some recommendations.... </p>
      </div>
    </div>

    <div class="row onboard-page-footer">
      <div class="col-10 offset-1 text">
        <div v-if="stats.status==='COMPLETED'">
          <b-button @click="next(1)" :disabled="stats.status !== 'COMPLETED'" class="onboard-continue">SHOW ME</b-button>
        </div>
        <div v-else>
          <p class="text-summary">{{message}}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { actions } from '@/services/store'

export default {

  name: 'GeneratorOnboard.Wahoo',

  data() {
    return {
      random:[],
      message:'',
      messages:[],
      timer:false
    }
  },

  async created() {

    document.body.style.backgroundColor = 'white';
    try {
      this.messages = await actions.fetchRandomContent()
      this.randomize()
      if ( this.stats.status !== 'COMPLETED' ) {
        this.poll()
      }
    } catch( err ) {
      console.error(`Unable to fetch random content : ${err}`)
    }
  },

  destroyed() {
    if ( this.timer ) {
      clearTimeout(this.timer)
    }
  },

  methods: {

    next(dir) {
      this.$emit('on-next',dir)
    },

    poll() {

      if ( this.random.length ) {
        this.message = this.random.pop() + '...'
        this.timer = setTimeout(this.poll,5000)
      } else if ( this.messages.length ) {
        this.randomize()
        this.poll()
      }

    },

    randomize() {
      this.random = this.messages.slice().sort(() => Math.random() - 0.5)
    }
  },

  props: {
    stats: {
      type: Object,
      required: true
    }
  }

}
</script>

<style lang="scss" >



</style>
