<template>

  <div class="container nopad generator-launcher" v-if="user && store.product && ready">

    <div class="row">

      <div class="col-md-10 offset-1 heading text-center">
        <p v-if="numSessions">What type of longform content do you want to generate social posts from?</p>
        <p v-if="!numSessions && user.signup && user.signup.publisher">
          Great! Now that your {{user.signup.publisher.id|partnerName}} account is connected,
          choose what type of content you want to generate <em>from.</em>
        </p>
      </div>

    </div>

    <div class="row options text-center">

      <div class="col-md-12 text-center option">
        <b-button class="mt-auto" :disabled="!includes('text-generate')" @click="generate('text')" variant="success">{{includes('text-generate')? 'TEXT':'UPGRADE'}}</b-button>
        <b-button v-if="includes('video-generate,audio-generate')" class="mt-auto" @click="generate('audio')" :variant="includes('video-generate')? 'success' : 'outline-success'">{{includes('video-generate')? 'AUDIO':'UPGRADE'}}</b-button>
        <b-button v-if="includes('video-generate,audio-generate')" class="mt-auto" @click="generate('video')" :variant="includes('video-generate')? 'success' : 'outline-success'">{{includes('video-generate')? 'VIDEO':'UPGRADE'}}</b-button>
      </div>

    </div>

  </div>

</template>

<script>

import { features, includes } from '@/services/product';
import { getters, actions, store } from '@/services/store';

export default {

  name: 'Success',

  data() {
    return {
      includes,
      features,
      includes,
      store,
      ready:false
    }
  },

  props: {

    numSessions: {
      type: Number
    },

    dashboard: {
      type: Object,
      required: true
    },

    user: {
      type: Object,
      required: true
    }

  },

  created() {

    if ( !includes('video-generate') ) {
      this.generate('text')
    } else {
      this.ready = true
    }

  },

  methods: {

    async generate(source) {

      if ( (source === 'video' || source==='audio') && !includes('video-generate') ) {
        this.portal()
      } else {
        const resp = await actions.createSession(source)
        this.$router.push(`/${store.dashboard._id}/generate/${resp.session._id}/${resp.count}`)
      }
    }

  }
}
</script>

<style lang="scss" >

.generator-launcher {

  font-family: Noto Sans;

  .heading {
    p {
      font-size: 42px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }

  .options {

    margin-top: 70px;

    .option {

      h3 {
        align:left;
        padding: 20px;
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 0px;
      }
      p {
        font-size: 16px;
        font-weight: regular;
        padding: 0px 20px 0px 20px;
        margin-bottom:0px;
      }
      button {
        width: 270px;
        background-color: #20C763;
        border: 0px;
        margin: 30px 0px 0px 15px;
        border-radius: 40px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        padding: 20px;
        margin-bottom: 30px;
      }
    }

  }

}
</style>
