<template>
  <div class="onboarding-model d-flex align-items-center justify-content-center">
    <div class="container text-center">
        <h3>You did it! But wait there's more... </h3>
        <p>We need to build your Voice Model - the foundation for how we customize Lately for you to create the best content for your audience. Connecting your social channels lets us look at your analytics and build that model.</p>           
        <b-button @click="next(1)">SOUNDS GOOOD!</b-button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Model',

  methods: {
    next(dir) {
      this.$emit('on-action',{
        action:'model'
      })
    }
  }
 
}
</script>

<style lang="scss" >
.onboarding-model {

  flex: 1;
  background-image: url('~@/assets/onboarding/v2/bg1.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;

  font-family: Noto Sans;

  min-height: 100vh;

  h3 {
    font-size: 32px;
    font-weight: bold;
  }

  button {
    color: white;
    background-color: #20C763;
  }

  p.randomisms {
    margin-top: 30px;
    color: grey;
  }
}

.onboarding-model.completed {
  background-image: url('~@/assets/onboarding/v2/bg6.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
}
</style>
