
<!--
IMPLEMENTS ONBOARDING WORKFLOW PER https://app.asana.com/0/1206858696947582/1208479501733448
-->

<template>
  <div class="onboarding-4" v-if="state">

    <div class="container-fluid heading" v-show="showNav">
      <div class="row">
        <div class="col-md-12">
      <Nav :state="state" :session="session"  />
      </div>
      </div>
    </div>
    <div class="content">
      <Welcome :state="state" @on-action="onAction" v-if="state==='welcome'" :session="session" />
      <Generate :state="state" @on-action="onAction" v-if="state==='generate'" />
      <Results :state="state" @on-action="onAction" v-if="session && ['review','edit','preview'].includes(state)" :sessionId="session._id" mode="onboard"/>        
      <Model :state="state" @on-action="onAction" v-if="state==='model'" />
      <Connect :state="state" @on-action="onAction" v-if="state==='connect'" />      
    </div>
  </div>
</template>

<style lang="scss" >
.onboarding-4  {

  min-height: 100vh;  
  margin-top: 30px;
  font-family: Noto Sans;

  .heading {
    border-bottom: 2px solid rgb(32, 199, 99);
    height: 100px;
  }

  .content {

    padding-top: 0px;

    button {
      font-size: 1rem;
      font-weight: normal;
      margin-top: 0px;
      border: 0px;
    }

  }
}
</style>

<script>

import Results from '@/components/Results/v3'
import Generate from './generate'
import Connect from './connect'

import Welcome from './welcome'
import Model from './model'
import Info from './info'
import Nav from './nav'

import { events, actions, getters } from '@/services/store'
import Onboarding from '@/services/onboarding'

// onboarding state in user.onboarding.state
// states per https://app.asana.com/0/1206858696947582/1208479501733448      
let STATES = ['welcome','generate','review','edit','preview','model','connect','complete']

export default {
  name: 'Onboarding4',
  data() {
    return {
      STATES,
      user:undefined,
      state: '',   // view selector
      step: 'generate', // nav header state
      session:undefined,            
      dashboard:undefined,
      platform: undefined
    }
  },
  destroyed() {
    events.$off('user-resolved')
  },
  async created() {
    try {

      // watch user updates
      events.$on('user-resolved',()=>{
        this.user = getters.user()
      })

      this.user = getters.user()     
      this.dashboard = getters.dashboard()      

      let state = this.user.onboarding.state
      let sessions = await actions.fetchSessions({page:0,skip:0,limit:10,type:'*'})   

      if ( state === 'complete' ) {
        return this.$router.push(`/${this.dashboard._id}/start`)
      } else {
        if ( sessions.num ) {
          this.session = sessions.sessions[0]
          if ( ['review','edit','preview'].includes(this.user.onboarding.state) ) {
            this.setOnboardingState('review')
          } else {
            this.setOnboardingState(this.user.onboarding.state)
          }
        } else {
          this.setOnboardingState('generate')
        }
      }
 
    } catch ( err ) {
      console.error(err)
    }
  },

  computed: {
    showNav() {
      let state = (this.state && ['generate','review','edit','preview'].includes(this.state))
      let session = this.session? this.session.postsCreated.all : true 
      return state && session 
    }
  },
  watch: {
    state() {
      this.setHelp(this.state)
    }
  },
  methods: {

    fetchHelp(state,args) {
      switch(state) {
        case 'review': {
          if ( this.platform ) {
            let platform = this.$options.filters.channelname(this.platform)
            return `Here are your ${platform} results! Select a post to tune your content.`            
          } else return ''
          break;
        }
        case 'edit': return `Editing your post is essential to train your Lately Voice model on how to improve! Edit each post and get it ready for prime time to get the most out of Lately.`
        case 'preview': return `Great! Now use the 👁️ action to preview how your post would appear when published. The preview may differ by channel, but make sure everything looks good before it goes live!`
        default: return ''
      }
    },

    setHelp(state) {
      let help = this.fetchHelp(state)
      Onboarding.setHelpText(help)
    },

    async setOnboardingState(state,args={}) {

      window.scrollTo(0,0);      

      args.state = state

      try {

        // TODO - better normalize these for legacy users
        args.version = 'v4'
        args.publisher = true
        args.generator = true 

        await Onboarding.setState(state,args)   
        this.setHelp(state)
        this.state = state

      } catch ( err ) {
        console.error(err)
      }
    },

    async onAction(evt) {
      // console.log('onboarding.v4.onAction',{action:evt.action,state:this.state,evt})
      switch (evt.action) {
        case 'welcome': {
          if (this.session) {
            await this.setOnboardingState('review')
          } else {
            await this.setOnboardingState('generate')
          }
          break;
        }
        case 'generate': {
          this.session = evt.session
          await this.setOnboardingState('review')
          break;
        }
        case 'session-loaded': {
          this.session = evt.session
          console.log('session-loaded',{state:this.state,posts:this.session.postsCreated.all,platform:this.platform,status:this.session.status.bucket})
          if ( !this.platform && this.session.postsCreated.all ) {
            let key = Object.keys(this.session.postsCreated).find((key)=>{
              return key !== 'all' && this.session.postsCreated[key]
            })
            this.platform = key
          }

          if ( this.state === 'generate' && this.session.postsCreated.all ) {
            await this.setOnboardingState('review')
          }
          break;
        }        
        case 'platform-selected': {
          this.platform = evt.platform
          this.setHelp('review')
          break;
        }
        case 'post-selected': {
          if ( !this.post || this.post._id !== evt.post._id ) {     
            this.post = evt.post
            this.setOnboardingState('edit')
          }
          break;
        }
        case 'content-edited': {
          await this.setOnboardingState('preview')    
          break;      
        }
        case 'preview-closed': {
          if ( evt.finish ) {
            await this.setOnboardingState('model')    
          }
          break;      
        }        
        case 'model': {
          await this.setOnboardingState('connect',{publisher:true})    
          break;      
        }       
        case 'connect-complete': {
          await this.setOnboardingState('complete',{generator:1,publisher:1})   
          this.$router.push(`/${this.dashboard._id}/sources`)          
          break;
        }             
      }
    },

  },

  components: {
    Generate,
    Welcome,
    Connect,
    Results,
    Model,
    Info,    
    Nav
  }
}
</script>

