<template>
<div class="container" v-if="invitation">

  <!-- initial -->
  <div class="row" v-if="state==='initial'">
    <div class="col">
      <h3 class="msg">Greetings future Lately peep!</h3>
      <p>{{invitation.createdBy.fullname}} has invitated you to join them on Lately :) What would you like to do?</p>
      <span class="float-left">
        <b-button @click="setState('decline')" variant="default">Decline Invitation</b-button>
        <b-button @click="setState('accept')" variant="primary">Accept Invitation </b-button>
      </span>
    </div>
  </div>

  <!-- accept -->
  <div class="row" v-if="state==='accept'">
    <div class="col">
      <h3 class="msg">Accept Invitation</h3>
      <p>Awesome! Please provide a few details below so we can get you up and running. </p>

      <b-form>

        <!-- first name -->
        <b-form-group label="First Name:" label-for="firstName">
          <b-form-input class="col-md-6"
            id="firstName"
            v-model="firstName"
            type="text"
            placeholder="First Name"
            required
            :state="vfirstName"
          ></b-form-input>
        </b-form-group>

        <!-- last name -->
        <b-form-group label="Last Name:" label-for="lastName">
          <b-form-input  class="col-md-6"
            id="lastName"
            v-model="lastName"
            type="text"
            placeholder="Last Name"
            required
            :state="vlastName"
          ></b-form-input>
        </b-form-group>

        <!-- password -->
        <b-form-group label="Password:" label-for="pwd">
          <b-form-input  class="col-md-6"
            id="pwd"
            v-model="pwd"
            type="password"
            placeholder="8 or more alphanumeric characters"
            required
            :state="vpassword"
          ></b-form-input>
        </b-form-group>

        <!-- confirmation -->
        <b-form-group label="Confirm Password:" label-for="pwdConfirm">
          <b-form-input  class="col-md-6"
            id="pwdConfirm"
            v-model="pwdConfirm"
            type="password"
            placeholder="8 or more alphanumeric characters"
            required
            :state="vpasswordConf"
          ></b-form-input>
        </b-form-group>

        <span class="float-left">
          <b-button @click="setState('initial')" type="cancel" variant="default">Back</b-button>
          <b-button @click="accept($event)" :disabled="errors.length>0" type="submit" variant="primary">Accept Invitation</b-button>
        </span>

      </b-form>
    </div>
  </div>

  <!-- decline -->
  <div class="row" v-if="state==='decline'">
    <div class="col-md-12">
      <h3 class="msg">Decline Invitation</h3>
      <p>No problem, you can provide an optional reason below.</p>
    </div>
    <br/>
    <div class="col-md-12 reason">
      <b-form>
        <b-form-group label-for="reason">
          <b-form-textarea
            maxLength="128"
            id="reason"
            v-model="reason"
            type="text"
            placeholder="Reason"
            required
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </div>
    <div class="col-md-12">
      <span class="float-left">
        <b-button @click="setState('initial')" type="cancel" variant="default">Back</b-button>
        <b-button @click="decline()" type="submit" variant="primary">Decline</b-button>
      </span>
    </div>
  </div>

  <!-- initial -->
  <div class="row" v-if="state==='declined'">
    <div class="col">
      <h3 class="msg">Invitation declined</h3>
      <p>You may close this browser window</p>
    </div>
  </div>

</div>
</template>

<script>

import { actions } from '@/services/store'

export default {

  name: 'InvitationView',

  data() {
    return {
      invitation: false,
      state: 'initial',
      pwdConfirm:'',
      firstName:'',
      lastName:'',
      user: false,
      busy: false,
      reason:'',
      errors:[],
      pwd:'',
    }
  },

  async created() {
    try {
      const resp = this.invitation = await actions.fetchInvitation(
        this.$router.currentRoute.params.dashboardId,
        this.$router.currentRoute.params.invitationId)

      this.invitation = resp.invitation
      this.user = resp.user
    } catch( err ) {
      console.error(err)
      this.$toasted.error('Unable to fetch invitation')
    }
  },

  computed: {
    vfirstName() {
      return  this.mark('firstName',(typeof this.firstName == 'string' && this.firstName.length > 0 ))
    },
    vlastName() {
      return  this.mark('lastName',(typeof this.lastName == 'string' && this.lastName.length > 0 ))
    },
    vpassword() {
      return  this.mark('pwd',(typeof this.pwd == 'string' && this.pwd.length >= 8))
    },
    vpasswordConf() {
      return  this.mark('pwdConfirm',(typeof this.pwd == 'string' && this.pwd.length >= 8 && this.pwdConfirm === this.pwd ))
    },
  },
  methods: {

    setState(state) {
      this.state = state
    },

    async accept(ev) {

      this.busy = true
      ev.preventDefault()
      ev.stopPropagation()

      try {

        await actions.respondToInvitation(
          this.invitation.dashboard._id,
          this.invitation._id,
          'accept', {
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.pwd
        })

        // to dashboard
        // this.$router.push(`/dashboard/${this.invitation.dashboard._id}`)

        // to login
        this.$router.push(`/app/login?email=${this.invitation.email}`)

      } catch( err ) {
        this.$toasted.error('Unable to complete registration')
      } finally {
        this.busy = false
      }

      return false

    },

    async decline() {

      try {

        await actions.respondToInvitation(
          this.invitation.dashboard._id,
          this.invitation._id,
          'decline', {
            reason: this.reason
        })

        this.setState('declined')

      } catch ( err ) {
        console.error('decline failed', err.message)
        this.$toasted.error('Unable to decline invitation')
      }

    },

    mark( field, val ) {
      const idx = this.errors.indexOf(field)
      if ( idx==-1 && !val ) this.errors.push(field)
      else if ( val && idx >= 0 ) this.errors.splice(idx,1)
      return val;
    },
  }
}
</script>

<style lang="scss" >
.reason {
  padding-top:10px;
}
</style>
