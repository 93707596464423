<template>
    <div class="check-row" :class="{'banner':row.class=='banner',last:row.last}" :style="{'border':row.border,'border-radius':row.radius}">
      <span class="text-left col c0" :class="{'border-left':!row.border}">
        {{row.cols[0]}}
      </span>
      <span class="text-center col c1">
        <i v-if="row.cols[1]==='y'" class="fa fa-check fa-xl" />
      </span>
      <span class="text-center col c2" :class="{'border-right':!row.border}">
        <i v-if="row.cols[2]==='y'" class="fa fa-check fa-xl" />
      </span>                                
    </div>
</template>

<script>
export default {
  name: 'TextRow',
  props: {
    row: Object
  }
}
</script>

<style lang="scss" >
.check-row {
  display:flex;
  font-size: 18px;
  font-weight: normal;  
  .col.border-left {
    border-left:1px solid lightgrey;
  }
  .col.border-right {
    border-right:1px solid lightgrey;
  }  
  .col {
    padding: 20px 30px 20px 30px;
  }

  .c0 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    text-align:left;
  }
  .c1 {
    width: 25%;
    color: #20C763;
    text-align: center;
    font-weight: bold;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;    
  }
  .c2 {
    width: 25%;
    color: #0076FE;    
    font-weight: bold;
    text-align:center
  }
}

.check-row.banner {
  font-size:24px;
  font-weight:bold;
  color:#174C43;
}

.check-row.last {
  border-bottom: 1px solid lightgrey;
  border-radius: 0px 0px 30px 30px;
  c0 {
    border-left: 1px solid lightgrey;
    border-radius: 0px 0px 0px 30px;
  }
}

</style>
