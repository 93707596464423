

<template>

<div class="container instagram-preview">

  <div class="row nopad">
    <div class="col-md-6 media">
      <Attachment :post="post" :policy="policy" :attachment="post.attachments[0]" width="100%" v-if="post.attachments.length"/>
      <i class="fa fa-file fa-5x" v-else />      
    </div>
    <div class="col-md-6 content">
      <div class="author" v-if="false && post.channel_meta">
      <!-- todo add account profile -->
      </div>
      <div class="author" v-else>
        <i class="fa-solid fa-circle-user fa-2x" />
        <span>{{ user.fullname }}</span> · <a href="/follow">Follow</a>
      </div>
      <div class="body">
        <i class="fa-solid fa-circle-user fa-2x" />             
        <span class="text" v-html="annotated"></span>
      </div>
    
      <div class="actions">
        <p>
          <span><i class="fa-regular fa-heart fa-2x"></i></span>
          <span><i class="fa-regular fa-comment fa-2x"></i></span>
          <span><i class="fa-regular fa-paper-plane fa-2x"></i></span>			
        </p>
        <p class="likes"><b>{{ likes|magnitude }} likes</b></p>        
        <p class="date">Just now</p>         
      </div>    

      <hr/>  
    </div>

  </div>    

</div>

</template>

<style lang="scss">

.instagram-preview {

  border-radius: 0px;
  border: 1px solid lightgrey;
  min-height: 200px;

  .media {
    border-radius: 0px;
    width: 100%;
    margin: 0px;
    padding: 0px;

    i {
      padding: 150px;
    }
  }

  .content {

    padding: 20px 10px 10px 10px;

    .author {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      span {
        margin-left: 20px;
        margin-right: 5px;
        font-weight: bold;
      }
      a {
        margin-left: 5px;
      }
    }

    .body {
      display: flex;
      flex-direction: row;      
      span.text {
        margin-left: 20px;
        font-weight: bold;
      }
    }

    .actions {
      position: relative;
      bottom: 0px;
      margin: 20px 10px 10px 10px;
      i {
        margin-right: 10px;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0px;
      }
    }    

  }

}
</style>

<script>

'use strict'

import Attachment from './Attachment'

import { getters } from '@/services/store'

export default {

  name: 'TwitterPreview',

  data() {
    return {
        type:'',
        user: getters.user(),
        now: new Date(),
        likes: Math.random()*10000000,
    }
  },

  computed: {
    annotated() {
      let hrefs = {
        hashtag:'https://www.instagram.com/explore/tags',
        mention:'https://www.instagram.com/explore/mentions'
      }      
      return this.$options.filters.links((this.post? this.post.content : ''),hrefs);        
    }
  },

  created() {
    let user = getters.user()
    this.type = this.post.contentType === 'temporary'? this.post.channel : this.post.contentType
  },

  props: {
    preview: Object,    
    policy: Object,
    post: Object    
  },

  components: {
    Attachment
  }

}

</script>








