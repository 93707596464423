<template>

  <div class="row analytics-overall-table" v-if="ready">

    <div class="col text-left">
      <h4 class="msg">Overall Stats</h4>  

      <!--
      <b-table :fields="fields" :items="items" responsive="md">
        <template #cell(name)="data">
          <span class="text-start">
            <i :class="data.item.icon" :style="'color:'+data.item.color"></i>
            {{ data.value}} {{ data.item.type }} {{ data.item.color }}
          </span>
        </template>     
        <template #cell()="data">
          <span class="text-right">{{ data.value }}</span>
        </template>
      </b-table>    
      -->

      <table class="table table-striped table-hover overall-stats-table" style="width:100%;background-color:white">
        <tr>
          <th>Name</th>
          <th class="text-right">Shares</th>
          <th class="text-right">Likes</th>
          <th class="text-right">Engagements</th>
          <th class="text-right">Posts</th>                                        
          <th class="text-right">Impressions</th>
          <th class="text-right">Reach</th>                
        </tr>
        <tr v-for="row in items">
          <td class="row-name text-left" > 
            <i :class="row.type|channelIcon" style="margin-right:10px"></i>
            <span>{{ row.name }}</span>
          </td>
          <td v-if="!row.valid" colspan="6" class="text-right">
            <span class="text-danger">No data</span>
          </td>        
          <td v-if="row.valid" class="text-right">
            <span>{{ row.shares }}</span>
          </td>
          <td v-if="row.valid" class="text-right">
            <span>{{ row.likes }}</span>
          </td>
          <td v-if="row.valid" class="text-right">
            <span>{{ row.engagements }}</span>
          </td>
          <td v-if="row.valid" class="text-right">
            <span>{{ row.posts }}</span>
          </td>
          <td v-if="row.valid" class="text-right">
            <span>{{ row.impressions }}</span>
          </td>
          <td v-if="row.valid" class="text-right">
            <span>{{ row.reach }}</span>
          </td>                                                                           
        </tr>
      </table>

    </div>

  </div>

</template>


<style lang="scss" >
.overall-stats-table {
  tr td {
    text-align: right;
  }
  tr td .row-name {
    text-align:left!important;
  }
}
</style>


<script>

import { getters } from '@/services/store'
import { media } from '@/services/constants'

export default {
  name: 'OverallStatsTable',
  data() {
      return {
        fields: [
          'name',
          'type',
          'shares',
          'likes',
          'engagements',
          'posts',
          'impressions',
          'reach'
        ],        
        items: undefined,
        ready: false
      }
    },
  props: {
    from: String,
    stats: {
      type: Object,
    }
  },
  methods: {

      magnitude(item=0,precision=1) {
        function round(number) {
          var multiplier = Math.pow(10, precision)
          return Math.round(number * multiplier) / multiplier
        }
        let result
        if (typeof item == 'number') {
          item = Math.abs(item)
          if (item >= 1000000) result = round(item / 1000000) + 'M'
          else if (item >= 1000) result = round(item / 1000) + 'K'
          else result = Math.round(item)
        } else result = item
        return result.toString() === '0' ? '' : result
      },

      iconFor(summary) {
        let type = summary.type
        switch (type.toLowerCase()) {
          case 'twitter':
            type = 'X'
          case 'tiktok':
            type =  'TikTok'
          case 'linkedin':
            type =  'LinkedIn'
          case 'facebook':
            type =  'Facebook'
          case 'instagram':
            type =  'Instagram'
        }

        let result = `fa-brands fa-fw fa-${type}`  
        return result.toLowerCase()

      },

      colorFor(summary) {
        let type = summary.type
        return media[type].color
      },

      nameFor(summary) {
        if (summary.name) return summary.name
          const ch = this.dashboard.channels.find((ch) => {
              return ch.content && ch.content.id == summary.id
          })
          return ch ? ch.content.name || ch.content.handle || ch.content.title : 'Unknown'        
      },

    summarize( s )  {

      let r = {    
        likes:0,   
        reach:0,
        posts:0,        
        shares:0,          
        type: s.type,   
        engagements:0,
        impressions:0,        
        valid:s.total_posts,        
        name: this.nameFor(s),
        icon: this.iconFor(s),
        color: this.colorFor(s),                    
      }      

      switch( s.type ) {
        case 'facebook': {
          if ( s.total_posts ) {
            r.posts = this.magnitude(s.total_posts)
            r.impressions = this.magnitude(s.impressions)
            r.engagements = this.magnitude(s.sessions)            
            r.shares = this.magnitude(s.shares)
            r.likes = this.magnitude(s.likes)            
            r.reach = this.magnitude(s.reach)
          }
          break
        }
        case 'instagram': {
          if ( s.total_posts ) {
            r.posts = this.magnitude(s.total_posts)
            r.impressions = this.magnitude(s.impressions)
            r.engagements = this.magnitude(s.sessions)                   
            r.shares = this.magnitude(s.shares)
            r.likes = this.magnitude(s.likes)            
            r.reach = this.magnitude(s.reach)
          }          
          break;
        }
        case 'linkedin': {
          if ( s.total_posts ) {
            r.shares = this.magnitude(s.share_count)
            r.likes = this.magnitude(s.like_count)
            r.posts = this.magnitude(s.total_posts)
            r.impressions = this.magnitude(s.impressions)
            r.engagements = this.magnitude(s.sessions)                   
            r.reach = this.magnitude(s.reach || (s.followers*s.total_posts))
          }             
          break;
        }
        case 'twitter': {
          if ( s.total_posts ) {
            r.shares = this.magnitude(s.retweet_count)
            r.likes = this.magnitude(s.like_count)
            r.posts = this.magnitude(s.total_posts)
            r.impressions = this.magnitude(s.impressions)
            r.engagements = this.magnitude(s.sessions)                   
            r.reach = this.magnitude(s.reach || (s.followers*s.total_posts))
          }             
          break;         
        }
        case 'youtube': {
          if ( s.total_posts ) {
            r.likes = this.magnitude(s.like_count)
            r.posts = this.magnitude(s.total_posts)
            r.impressions = this.magnitude(s.impressions)
            r.engagements = this.magnitude(s.sessions)                   
            r.reach = this.magnitude(s.reach || (s.followers*s.total_posts))
          }    
          break;
        }
      }

      return r

    }
  },
  created() {
    this.dashboard = getters.dashboard()
  },
  watch: {
    stats() {
      if ( this.stats && this.stats.summaries ) {
        this.items = this.stats.summaries.reduce((acc,s)=>{
          try {
          acc.push(this.summarize(s))
          return acc
          } catch ( err ) {
            console.error(err)
          }
        },[])
        this.ready = true    
      } else {
        this.ready = false
        this.items = undefined        
      }
    }
  }
}
</script>
