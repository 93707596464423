<template>

    <div class="container-fluid nopad list-view nomargin" v-if="policy">

      <!-- editor -->
      <div class="row editor" v-if="editing && session">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <Editor :post="editing" :policy="policy" :errors="errors" :session="session" :suppress="{actions:1}" :noSave="true" mode="inline" @update-schedule="handleUpdateSchedule" />
            </div>
          </div>
        </div>
      </div>

      <!-- list view -->
      <div class="row master" v-if="!editing">

        <b-overlay :show="busy">

          <div class="container nopad">

            <!-- list view -->
            <div class="row nomargin">

              <div class="col-12 nopad" v-if="content && !content.length">
                <h4>No results</h4>
              </div>

              <!-- items -->
              <div class="col-12 nopad" v-for="post in content" :key="post._id">
                <ListItem v-if="!editing" :post="post" :policy="policy" :selections="selectedContent" :sending="sending" @on-select="onSelect" @on-action="onAction" />
              </div>

            </div>

          </div>

        </b-overlay>

      </div>

      <!-- pagination -->
      <div class="row pagination nopad" v-if="content.length && !editing && paging.pages > 1">
        <div class="container">
          <div class="row">
            <div class="col-12 nopad">
              <Pagination :paging="paging" @on-nav="nav" />
            </div>
          </div>
        </div>
      </div>

    </div>

</template>


<style lang="scss">

.list-view {

  font-family: Noto Sans;

  .draft-item {
    padding: 0px;
    border: 1px solid lightgrey;
    margin-bottom: 40px;
    border-radius: 8px;
  }

  .action-buttons button {
    margin-left:10px;
  }

  .refresh small {
    font-size: 20px;
    margin-right: 10px;
  }

  .pagination {
    padding-top: 10px!important;
    button.disabled {
      border-width: 0px
    }
    .page-picker {
      padding-top: 4px!important;
    }
  }


}

</style>


<script>

import Editor from '@/components/Content/ListView/Editor'
import Pagination from '@/components/Pagination'
import ListItem from './ListItem'

export default {
  name: 'ContentPost.ListView',

  inject: [
    'busy',
    'sending',
    'show',
    'paging',
  ],

  props: {

    config: {
      type: Object,
      required: true,
    },
    policy: Object,
    content: {
      type: Array,
      required: true,
    },
    editing: {
      type: [Object, Boolean],
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    selectedChannels: {
      type: Array,
      default: () => [],
    },
    selectedContent: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Object,
      required: true,
    },
    session: {
      type: [Object, Boolean],
      default: null,
    },

  },

  created() {/*
    // Log all the props
    console.log('Props received from parent:', {
      config: this.config,
      session: this.session,
      content: this.content,
      editing: this.editing,
      errors: this.errors,
      selectedChannels: this.selectedChannels,
      selectedContent: this.selectedContent,
      dashboard: this.dashboard,
      policy: this.policy,
      busy: this.busy,
      sending: this.sending,
      show: this.show,
      paging: this.paging,
      showPostActionsDialog: this.showPostActionsDialog,
      removeDialogPrompt: this.removeDialogPrompt,
      removeDialogOpen: this.removeDialogOpen,
      scheduleContent: this.scheduleContent,
      rescheduleContent: this.rescheduleContent,
    });*/
  },

  watch: {
    scheduleContent() {
      console.log('scheduleContent changed',this.scheduleContent)
    }
  },

  destroyed() {
    if ( this.timer ) {
      this.$emit('clear-timeout', this.timer)
    }
  },

  computed: {
    pageList() {
      return Array.from({length: this.paging.pages}, (_, i) => i )
    },
  },

  methods: {

    refresh() {
      this.$router.go()
    },

    onSelect(id) {
      this.$emit('on-select', id)
    },

    handleUpdateSchedule(updatedSchedule) {
      this.$emit('handle-update-schedule', updatedSchedule)
    },

    completeRemove(confirm) {
      this.$emit('complete-remove', confirm)
    },

    removeAll() {
      this.$emit('poll', true)
    },

    nav(page) {
      this.paging.page = page
      this.$emit('remove-all')
    },

    async onAction(event) {
      return this.$emit('on-action',event)
    }

  },

  components: {
    Pagination,
    ListItem,
    Editor
  }

}
</script>
