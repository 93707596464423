<template>
  <div class="container">

    <div class="row">

      <b-modal id="website-added-modal" v-model="showModal" hide-header hide-footer no-close-on-backdrop>

          <template #default="">

            <div class="website-modal-body">
              <h1 class="text-success thicker">
                Hot diggity dingdong!
              </h1>
              <h1 class="text-success thicker">
                You did it!
              </h1>

              <h4 class="thicker">Congratulations on creating your Lately account.</h4>

              <p>Ready for our AI to start building your very own custom writing model? </p>

              <div class="text-center">
                <img height="200px" src="../assets/lately_signup_heart.png" />
              </div>

              <br/>

              <b-button @click="showModal=false" block variant="outline-success">YEPPERS, LET'S GO!</b-button>
            </div>

          </template>

      </b-modal>

      <div class="col-md-6">
        <h3>Add Your Company’s URL</h3>
        <p>Lately's AI brain is hungry! When you add your company's URL, we'll analyze what's there and start building your custom writing model. </p>
        <p><i>A company URL is required to jumpstart the AI Don't have one? Don't panic! Try adding one from a competitor or business similar to yours.</i></p>
        <b-form-group
          label="Company URL"
          description=""
        >
          <b-form-input
            v-model="url"
            type="text"
            placeholder="ex. https://www.lately.ai"
            :state="website"
          ></b-form-input>

        </b-form-group>

        <br>
        <b-button :disabled="!valid" @click="submit()" class="float-left" variant="primary">CONTINUE</b-button>
        <b-button v-if="errors" variant="outline-danger" @click="skip()" class="float-right"> SKIP</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import { actions, getters } from '../services/store'
import * as linkify from 'linkifyjs'

const PLACEHOLDER = '__skipped.com__'

export default {

  name: 'GatherWebsite',

  data() {
    return {
      url:'',
      errors: 0,
      valid: false,
      showModal: true,
      dashboard: false,
    }
  },

  created() {
    this.dashboard = getters.dashboard()
  },

  methods: {

    async skip() {
      this.submit(PLACEHOLDER) // need to provide something for the schema..
    },

    async submit(url) {

      try {

        await actions.setDashboardWebsite( this.dashboard._id, (url || this.url ) )
        if ( url!== PLACEHOLDER ) {
          this.$toasted.success(`Website updated`)
        }
        setTimeout(()=>{
          this.$router.push( `/${this.dashboard._id}/sources` )
        },250)

      } catch ( err ) {
        this.errors += 1
        this.$toasted.error(`Please use another URL, or you may skip this step.`)
      }

    }

  },

  computed: {

    website() {
      const matches = linkify.find(this.url).filter((m)=>{return m.type === 'url'})
      this.valid = (matches.length === 1 && matches[0].value === this.url.trim())
      return this.valid;
    }

  }

}
</script>

<style>

[id^=website-added-modal] .modal-content {
  border-radius:20px;
  padding: 20px;
}

.website-modal-body .thicker {
  font-weight: 1000;
}

</style>
