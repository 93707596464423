<template>

  <div class="container onboard-page onboard-sources">

    <div class="row onboard-page-header">
      <div class="col-12">
        <img src="@/assets/onboarding/onb_text.png" />
      </div>
      <div class="col-12">
        <span class="title">To start, we'll generate with text</span>
        <p class="description">Here are some examples of the types of longform text that work best:</p>
      </div>
    </div>

    <div class="row onboard-page-body sources-list">
      <div class="d-flex justify-content-center">
        <div class="intermediary"></div>
        <div class="p-6 flex-grow-1">
          <div class="sources-list-container">
            <div class="row sources-list">
              <div class="col-md-12 source-entry" v-for="(source,idx) in sources" :idx="idx">
                <span class="source-bullet"><i class="fa fa-sm fa-circle"/></span>
                <span class="source-text">{{source}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="intermediary"></div>
      </div>
    </div>

    <div class="row onboard-page-footer">
      <div class="col-12">
        <b-button @click="next(-1)" class="onboard-back">BACK</b-button>
        <b-button @click="next(1)" class="onboard-continue">GOT IT!</b-button>
      </div>
    </div>

  </div>
</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import { media } from '@/services/constants'

export default {

  name: 'sources',

  data() {
    return {
      sources:['Blog Posts','Newsletters','Online articles','Chapters of books','Press releases','Pretty much anything 1,000 characters or more!']
    }
  },

  methods: {

    next(dir) {
      this.$emit('on-next',dir)
    },

    colorFor(ch) {
      return media[ch].color
    },

    backgroundFor(ch) {
      return media[ch].color
    },

    borderFor(ch) {
      if ( this.channel === ch ) {
        return media[ch].color
      } else return '#D6D6D6'
    },

    iconFor(ch) {
      switch(ch) {
        case 'facebook': return 'facebook-f';
        case 'linkedin': return 'linkedin-in'
        default: return ch
      }
    }

  },

  components: {
    ChannelIcon
  }

}
</script>

<style lang="scss" >

.onboard-sources {

  .ready-text {
    background-color:white;
    font-size: 28px;
    font-family: Noto Sans;
    padding: 54px 48px 36px 48px;
  }

  .sources-list {
    .intermediary {
      width: 40%;
    }
  }

  .sources-list-container {

    background-color: #F7F7F7;
    padding: 36px 36px 24px 36px;
    border-radius: 10px;

    .source-entry {
      margin-bottom: 18px;
    }

    .source-text {
      font-size: 24px;
      padding-bottom: 18px;
    }

    .source-bullet {
      color: #20C763;
      padding: 20px 20px 0px 0px;
      margin-bottom: 20px!important;
    }

  }

}

</style>
