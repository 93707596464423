
<template>
  <div class="row">
    <div class="col">
      <NoResultsVideo v-if="source==='video'" />
      <NoResultsText v-if="source==='text'" />
      <NoResultsUrl v-if="source==='url'" />
    </div>
  </div>
</template>

<script>

import NoResultsVideo from './NoResultsVideo'
import NoResultsText from './NoResultsText'
import NoResultsUrl from './NoResultsUrl'

export default {

  name: 'NoResultsView',

  data() {
    return {
      source:''
    }
  },

  created() {
    if ( this.session.config.source === 'video' ) {
      this.source = 'video'
    } else this.source = this.session.config.steps.source.type
  },

  props: {
    session: {
      type: Object,
      required: true
    }
  },

  components: {
   NoResultsVideo,
   NoResultsText,
   NoResultsUrl
  }

}
</script>

<style lang="scss" >

.no-results-view {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

ul.no-results-list {
  list-style: none; /* Remove default bullets */
}

ul.no-results-list li {
  padding-bottom: 5px;
}

ul.no-results-list li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: lightgreen; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1.5em; /* Also needed for space (tweak if needed) */
  margin-left: -2em; /* Also needed for space (tweak if needed) */
}

</style>
