<template>
  <div id="app">
    <transition name="fade">
      <component :is="layout">
        <router-view :key="$route.fullPath"/>
      </component>
    </transition>
  </div>
</template>

<script>
import '@/scss/app.scss'
import '@/scss/global.scss'

export default {
  computed: {
    layout() {
      return `layout-${this.$route.meta.layout || 'default'}`
    }
  }
}
</script>

<style lang="scss" >

.nomargin {
  margin: 0px!important;
}

.nopad {
  padding: 0px!important;
}

.modal {
  background-color: rgba(32, 199, 99, 0.7);
}

</style>
