<template>

    <div class="row" v-if="source && policy">

      <div class="col-md-1">
        <img src="../assets/congen.svg" width="50" />
      </div>

      <div class="col-md-10 source-details">

        <div v-if="!source.type">

          <video controls="" height="60px">
            <source :src="source.source|preview(policy)" :type="source.source.mimetype"/>
            Your browser does not support HTML5 video.
          </video>

          <span> {{source.source.filename|truncate(96)}}</span>

        </div>

        <div v-if="source.type=='url'">

          <p class="source-title" >
            <a v-if="preview && preview.title" :href="source.url" target="new">{{preview.title | truncate(96) }}</a>
            <b v-if="!preview || !preview.title" >{{source.url | truncate(96) }}</b>
          </p>

        </div>

        <div v-if="source.type=='text'">

          <p class="source-title" >
            <b> (Pasted text)</b>
          </p>
          <p class="mb-0"> {{source.text | truncate(96) }}</p>

        </div>

      </div>

    </div>

</template>

<script>

'use strict'

export default {
  name: 'SelectedSource',
  props: {
    source: {
      type:Object,
      required: true
    },
    preview: {
      type:Object,
      required:false
    },
    policy: {
      type:Object,
      required:true
    }
  }
}
</script>

<style lang="scss" >
p.source-title {
  margin: 0px 0px 0px 0px;
}
</style>
