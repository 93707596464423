<template>

  <div class="user-analytics">
    <h4>{{logins}} Logins total
      <small class="float-right">
        by {{distinct}} distinct Users
        among {{users.length}}
      </small>
    </h4>
    <b-table striped hover :items="users" :fields="fields" :filter="search" />
  </div>

</template>

<script>

import { actions } from '@/services/store'
import moment from 'moment'

export default {

  name: 'UserAnalytics',
  data() {
    return {
      logins:0,
      distinct:0,
      users:[],
      fields:[{
        key:'fullname',
        label:'Name',
        sortable:true
      },{
        key:'email',
        label:'Email',
        sortable:true
      },{
        key:'lastLogin',
        label:'Last Login',
        sortable:true,
        formatter: function(value,key,item) {
          return value? moment(value).format('MMM DD, YYYY') : ''
        }
      }]
    }
  },

  props: {
    date: Date,
    search: String
  },

  async created() {
    this.query()
  },

  methods: {
    async query() {
      try {
        const date = moment(this.date)
        const resp = await actions.fetchAdminAnalytics('user',this.date,this.date)
        this.distinct = resp.distinct
        this.logins = resp.logins
        this.users = resp.users
      } catch(err) {
        console.error(err)
        this.$toasted.error('Unable to fetch User analytics')
      }
    }
  },

  watch: {
    date() {
      this.query()
    }
  }
}
</script>

<style lang="scss" >

.user-analytics {
  h4 {
    margin: 20px 0px 20px 0px;
  }
}
</style>
