<template>

    <div class="row">

        <b-form v-if="user">

            <div class="col-md-12">
                <h4>My Password</h4>
                <hr>
            </div>

            <div class="form-group col-md-12">
                <label>Current Password</label>
                <b-form-input type="password" v-model="currentPwd" class="form-control" :state="currentPassword"></b-form-input>
                <b-form-invalid-feedback :state="currentPassword">
                    Please enter your currentPwd password.
                </b-form-invalid-feedback>
            </div>

            <div class="form-group col-md-12">
                <label>New Password</label>
                <b-form-input type="password" v-model="newPwd" class="form-control" :state="newPassword"></b-form-input>
                <b-form-invalid-feedback :state="newPassword">
                    Please enter your newPwd password.
                </b-form-invalid-feedback>
            </div>

            <div class="form-group col-md-12">
                <label>Confirm Password</label>
                <b-form-input type="password" v-model="confirmPwd" class="form-control" :state="confirmPassword"></b-form-input>
                <b-form-invalid-feedback :state="confirmPassword">
                    Please confirmPwd your newPwd password.
                </b-form-invalid-feedback>
            </div>

            <div class="form-group col-md-12">
                <span class="float-right">
                    <b-button @click="reset()" variant="default">Reset</b-button>
                    <b-button @click="submit()" :disabled="(errors.length>0)" variant="primary">Save</b-button>
                </span>
            </div>

        </b-form>

    </div>

</template>

<script>

import { actions, store } from '@/services/store';

export default {

  name: 'ChangePassword',

  data() {
        return {
            user:false,
            currentPwd:'',
            confirmPwd:'',
            errors:[],
            newPwd:'',
        }
    },

    async created() {
        this.user = await actions.fetchUser()
    },

    methods: {

        mark(key,val) {
            const idx = this.errors.indexOf(key)
            if ( idx === -1 && !val ) this.errors.push(key)
            else if ( idx != -1 && val ) this.errors.splice(idx,1)
            return val;
        },

        reset() {
            this.currentPwd = '',
            this.confirmPwd = '',
            this.newPwd = ''
        },

        async submit() {

            try {

                await actions.updatePassword(this.user._id,{
                    currentPwd: this.currentPwd,
                    newPwd: this.newPwd
                })
                this.reset()
                this.$toasted.success(`Password updated`)

            } catch( err ) {
                console.error(err.message)
                this.$toasted.error(`Failed to update your password`)
            }

        }

    },

    computed: {

        currentPassword() {
            const val = new Boolean(this.currentPwd && this.currentPwd.length>=8).valueOf()
            return this.mark('currentPwd',val)
        },

        newPassword() {
            const val = new Boolean(this.newPwd && this.newPwd.length>=8).valueOf()
            return this.mark('newPwd',val)
        },

        confirmPassword() {
            const val = new Boolean(this.confirmPwd && this.confirmPwd===this.newPwd).valueOf()
            return this.mark( 'confirmPwd',val)
        }

    }

}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
