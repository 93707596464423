<template>
  <div class="container" v-if="ready">

    <div class="row">

      <div class="col-md-12">

        <h2 class="msg">
          Goals
          <span class="float-right">
            <b-button @click="submit()" size="sm" variant="primary">Save Changes</b-button>
          </span>
        </h2>
        <p>Give us some guidance about your targets and we'll help keep you on track!</p>
        <br/>

      </div>

      <b-tabs content-class="mt-3" pills card>

        <b-tab title="Daily" >
          <b-card-text v-for="goal in goals" :key="goal.id">
            <GoalsPicker :goal="goal" scope="day" />
          </b-card-text>
        </b-tab>

        <b-tab title="Weekly" active>
          <b-card-text v-for="goal in goals" :key="goal.id">
            <GoalsPicker :goal="goal" scope="week" />
          </b-card-text>
        </b-tab>

        <b-tab title="Monthly">
          <b-card-text v-for="goal in goals" :key="goal.id">
            <GoalsPicker :goal="goal" scope="month" />
          </b-card-text>
        </b-tab>

        <b-tab title="Yearly">
          <b-card-text v-for="goal in goals" :key="goal.id">
            <GoalsPicker :goal="goal" scope="year" />
          </b-card-text>
        </b-tab>

      </b-tabs>

    </div>

  </div>
</template>

<script>

'use strict'

import { getters, actions } from '@/services/store'

import GoalsPicker from './GoalsPicker'

export default {

  name: 'Goals',

  data() {
    return {
      plan: false,
      ready: false,
      goals: false,
      dashboard: false
    }
  },

  async created() {

    this.plan = getters.product()
    this.dashboard = getters.dashboard()

    // extract or setup default goals
    this.goals = this.plan.limits.reduce((acc,f)=>{
      const goal = (this.dashboard.goals.find((g)=>{ return f.id === g.id}) || this.defaultsFor(f))
      // console.log('goal',goal)
      if (goal.id == 'send-third-party' || goal.id == 'generate-export' ||goal.id==='send-lately'){
        acc.push(goal)
      }

      return acc;
    },[])

    this.ready = true;
  },

  methods: {

    async submit() {

      try {
        await actions.updateGoals( this.dashboard._id, this.goals )
        this.$toasted.success(`Goals updated`)
      } catch( err ) {
        this.$toasted.error(`Failed to update goals`)
      }

    },

    defaultsFor(feature) {

      return {
        id:feature.id,
        label:feature.label,
        description: feature.description,
        day:{
          all: {
            max: 4,
            val: [1,4],
            checked: false
          },
          facebook: {
            max: 4,
            val: [1,4]
          },
          twitter: {
            max: 4,
            val: [1,4]
          },
          linkedin: {
            max: 4,
            val: [1,4]
          },
          instagram: {
            max: 4,
            val: [1,4]
          }
        },
        week:{
          all: {
            max: 35,
            val: [4,14],
            checked: false
          },
          facebook: {
            max: 24,
            val: [7,14]
          },
          twitter: {
            max: 35,
            val: [14,21]
          },
          linkedin: {
            max: 24,
            val: [5,10]
          },
          instagram: {
            max: 24,
            val: [7,14]
          }
        },
        month:{
          all: {
            max: 60,
            val: [10,40],
            checked: false
          },
          facebook: {
            max: 60,
            val: [10,40]
          },
          twitter: {
            max: 60,
            val: [10,40]
          },
          linkedin: {
            max: 60,
            val: [10,40]
          },
          instagram: {
            max: 60,
            val: [10,40]
          }
        },
        year:{
          all: {
            max: 300,
            val: [60,100],
            checked: false
          },
          facebook: {
            max: 300,
            val: [60,100]
          },
          twitter: {
            max: 300,
            val: [60,100]
          },
          linkedin: {
            max: 300,
            val: [60,100]
          },
          instagram: {
            max: 300,
            val: [60,100]
          }
        }
      }

    }

  },

  components: {
    GoalsPicker
  }

}

</script>

<style>


</style>
