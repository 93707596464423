<template>
  <div class="onboarding-start d-flex align-items-center justify-content-center">
    <div class="container onboarding-body">
      <h3>Great! Let's create your free account!</h3>
      <p>First, we need to build your <b>Voice Model</b> - the foundation for how we identify what
        messaging will make up the ideal messaging for you and your target audience. </p>
      <b-button @click="next(1)">SOUNDS GOOD!</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  methods: {
    next() {
      this.$emit('onboarding-next',1)
    }
  }
}
</script>

<style lang="scss" >
.onboarding-start {

  flex: 1;

  margin-top: -48px;

  background-image: url('~@/assets/onboarding/v2/bg1.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;

  h3 {
    font-size: 32px;
    font-weight: bold;
  }

}
</style>
