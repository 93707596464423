<template>

  <div class="container onboard-page onboard-source">

    <b-modal v-model="modalShow" hide-header hide-footer centered cancel-disabled ok-disabled content-class="no-blog-modal">

      <template #default="">

        <div class="text-center">

          <h3>No Blog Post, No Worries!</h3>

          <p class="text-center">There are lots of ways to get your longform content to lately: audio, video, copy/paste, prompt, and the list goes on!</p>
          <p>You can continue this tutorial using one of Lately's blogs if you'd like, or if you'd rather skip the tutorial and try one of the other ways, go for it!</p>

          <p class="text-center"><b-button class="btn-use" variant="primary" @click="useLatelyBlog()">USE A LATELY BLOG POST</b-button></p>
          <p><b-button class="btn-skip" variant="outline-primary" @click="useNoBlog()">SKIP</b-button></p>
        </div>

      </template>

    </b-modal>

    <div class="row onboard-page-header">
      <div class="col-10 offset-1">
        <img src="@/assets/onboarding/onb_blog.png" height="199px"/>
      </div>
      <div class="col-10 offset-1">
        <span class="title">Great, first let's try a blog post.</span>
      </div>
    </div>

    <div class="row onboard-page-body source-body">
      <div class="col-md-12">
        <div class="row source-body">
          <div class="col-11 offset-1 source-text">
            <div class="source-url-container">
              <p class="source-description">Paste in the URL of any blog on the planet:</p>
              <b-form-input autofocus placeholder="https://www.yourblog.com/specific-source-post.html" v-model="url"></b-form-input>
              <p class="text-danger" v-if="errMessage">{{errMessage}}</p>
            </div>
          </div>
        </div>
    </div>

      <div class="row">
        <div class="col-11 offset-1">
          <div class="d-flex justify-content-center source-reminder">
            <img src="@/assets/onboarding/smiley.png" />
            <span class="description text-center">
              <i><b>Heads up:</b> The URL you choose should be an individual blog post and not a blog/website homepage, Facebook page or Google doc. </i>
            </span>
            <img src="@/assets/onboarding/smiley.png" />
          </div>
        </div>
      </div>

    </div>

    <div class="row onboard-page-footer">
      <div class="col-md-10 offset-1 text-center nopad">
        <b-button @click="next(-1)" class="onboard-back">BACK</b-button>
        <b-button :disabled="!valid" @click="next(1)" class="onboard-continue">GENERATE!</b-button>
      </div>
      <p class="text-center source-nav">
        <a href="#" @click="noBlog()">I don't have a blog post</a>
      </p>      
    </div>

  </div>

</template>

<script>

import { randomSource, text_sources_domain_filter } from '@/services/constants'
import { getters, actions } from '@/services/store'
import * as linkify from 'linkifyjs'
import Vue from 'vue'

export default {
  name: 'GeneratorOnboard.blog',

  data() {

    return {
      url:'',
      valid:false,
      errMessage:'',
      modalShow:false
    }
  },

  watch: {
    url() {
      this.validate()
    }
  },

  methods: {

    // use a random lately blog
    async useLatelyBlog() {
      try {
        const blogs = await actions.fetchBlogEntries(20)
        if ( blogs.length ) {
          const r = Math.floor(Math.random()*blogs.length); 
          this.url = blogs[r].href
          this.modalShow = false
          this.next(1) // proceed with generation
        } else throw new Error('no blogs returned')
      } catch( err ) {
        console.error(err)
        this.$toasted.error(`Whoops we seem to have encountered a problem, please try again.`)        
      }
    },

    // skip by setting generator onboarding to true and reloading 
    async useNoBlog() {
      try {
        const user = getters.user()
        user.onboarding = user.onboarding || {}
        user.onboarding.generator = true
        await actions.patchUser(user._id,'onboarding',user.onboarding)
        this.$router.go(0)
      } catch( err ) {
        console.error(err)
      }
    },

    noBlog() {
      this.modalShow = !this.modalShow
    },

    next(dir) {
      if ( dir < 1 ) this.$emit('on-next',dir)
      else this.$emit('on-generate', this.url)
    },

    setRandomLink() {
      this.url = randomSource();
    },

    validate(){
      const matches = linkify.find(this.url).filter((m)=>{return m.type === 'url'})
      const blockedMsg = matches.length? text_sources_domain_filter(matches[0].href) : ''
      if ( blockedMsg ) {
        this.errMessage = blockedMsg
        this.valid = false
      } else {
        this.errMessage = ''
        this.valid = (matches.length === 1 && matches[0].value === this.url.trim() )
      }
    }
  }
}
</script>

<style lang="scss">

.onboard-source {

  .source-url-container {
    padding: 0px;
    width: 100%!important;
    p {
      font-size: 19px;
      font-weight: bold;
    }
  }

  .source-text {
    background-color:white;
    border-width: 1px 1px 1px 1px;
    border-color: lightgrey;
    border-style: solid;
    border-radius: 20px 20px 20px 20px;
    font-size: 26px;
    padding: 42px;
    box-shadow: 2px 2px 2px lightgrey;
    margin-bottom: 48px;
  }

  .source-reminder {
    background-color: #EDFBF4;
    border-radius: 15px;
    padding: 24px;

    img {
      margin-top:10px;
      width: 52px;
      height: 52px;
    }

    .description {
      padding-left: 10px;
      font-size: 24px;
      font-weight: normal;
      border-radius: 0px;
    }

  }

  p.source-nav {
    padding-top: 20px;
    a {
      color: blue;
    }
  }

}

.no-blog-modal {

  font: noto sans;
  padding: 40px;

  border-width: 0px;
  border-radius: 40px!important;
  button {
    border-width: 0px;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    width: 250px;
  }

  button.btn-skip {
    border: 2px solid #1473E6!important;    
    color: #1473E6!important;
  }

  button.btn-use {
    margin-top: 5px;
    background-color: #1473E6!important;    
    color: white
  }

}    

</style>
