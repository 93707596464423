
// from https://developer.grammarly.com/docs/api/editor-sdk/#reference
export const GrammarlyConfig = {
  suggestions: {
    ConjunctionAtStartOfSentence: false,
    Fluency: true,
    InformalPronounsAcademic: true,
    MissingSpaces: true,
    NumbersBeginningSentences: true,
    NumbersZeroThroughTen: true,
    OxfordComma: false,
    PassiveVoice: true,
    PersonFirstLanguage: true,
    PossiblyBiasedLanguage: true,
    PossiblyBiasedLanguageGenderRelated: true,
    PossiblyBiasedLanguageLgbtRelated: true,
    PossiblyOutdatedLanguageLgbtRelated: true,
    PrepositionAtTheEndOfSentence: true,
    PunctuationWithQuotation: true,
    ReadabilityFillerwords: true,
    ReadabilityTransforms: true,
    ReclaimedLanguage: true,
    SplitInfinitive: true,
    StylisticFragments: true,
    UnnecessaryEllipses: false,
    Variety: true,
    Vocabulary: true,
  },
  ui: {
    showToneDetector:true
  }
}

