
<template>

  <div :key="post._id" class="grammarly-edtor-v2" v-if="post">

    <p class="static" v-if="!isEditing" v-html="annotated"></p>         

    <Grammarly v-else clientId="client_2uUW7bgYEuBKkdy4Q4f93P">
      <GrammarlyEditorPlugin :config="config">
        <textarea :ref="'post_editor_'+post._id"
          v-model="post.content"
          @input="findPosition($event)"
          @keyup="findPosition($event)"
          @click="findPosition($event)"
        rows="6" />

        <!-- 
        <div class="row row-content-preview"  v-if="mode==='preview' && isEditing && post.preview_cache && post.preview_cache.title && !post.attachments.length">
          <div class="col-md-12">
            <Preview :post="post" :policy="policy" />
          </div>
        </div>
        -->

        <!-- use v-show here so that peer to peer events propagate to ContentStatus -->
        <div class="row nopad pr-3 row-content-status-wrapper">
          <div class="w-full nopad row-content-status" v-show="isEditing">
            <ContentStatus :post="post" :session="session" :policy="policy" :errors="errors" @on-toggle-emoji="onToggleEmoji" @attached="attached" @on-select-hashtag="onSelectHashtag" @on-select-keyword="onSelectKeyword"/>
          </div>
        </div>

      </GrammarlyEditorPlugin>
    </Grammarly>

  </div>

</template>

<script>

'use strict'

import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-vue";
import { GrammarlyConfig } from '@/services/grammarly-config'
import Preview from '@/components/Content/ListView/Preview'
import ContentStatus from "./ContentStatus.vue";

export default {

  name: 'EditorV2',

  data() {
    return {
      config: GrammarlyConfig,
      useGrammarly: true,
      selectionStart: 0,
      selectionEnd:0
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default:'normal'
    }
  },

  computed: {
    annotated() {
      return this.$options.filters.links(this.post? this.post.content : '');   
    },
  },

  methods: {

    onToggleEmoji() {
      this.$emit('on-toggle-emoji')
    },

    onSelectKeyword(kw) {
      this.$emit('on-select-keyword',kw)
    },

    onSelectHashtag(ht) {
      console.log('GrammarlyEditor.onSelectHashtag',ht)
      this.$emit('on-select-hashtag',ht)
    },

    attached(filesUploaded) {
      this.$emit('on-attached', filesUploaded)
    },

    findPosition(e) {
      this.selectionStart = e.target.selectionStart
      this.selectionEnd = e.target.selectionEnd
    },

    fetchPosition() {
      return this.selectionEnd
    },

    fetchEditor() {
      return this.$refs.post_editor_v2
    },

    fetchSelection() {
      return {
        start: this.selectionStart,
        end: this.selectionEnd
      }
    }

  },

  // set focus to editor in order for grammarly to function
  watch: {
    isEditing() {
      if ( this.isEditing ) {
        setTimeout(()=>{
          this.$refs[`post_editor_${this.post._id}`].focus()
        },100)
      }
    },
    'post.content'() {
      this.$emit('on-action',{
        action:'content-edited',
        post: this.post
      })
    }
  },

  components: {
    GrammarlyEditorPlugin,
    ContentStatus,
    Grammarly,
    Preview
  }
};
</script>

<style>

.grammarly-edtor-v2 {
  border-radius: 8px;
  width: 100%;

  p  {
    padding: 15px;
    overflow: scroll;
    width: 100%;
  }

  .grammarly-edtor textarea {
    resize: none !important;
    border: 0px solid lightgrey!important;
    border-radius: 8px;
    padding: 14px;
    width: 100%;
    outline: 0;
  }

  .row-content-status-wrapper {
    min-height: 54.5px;
    width: 100%;
  }

  .row-content-status {
    border-top: 1px solid lightgrey !important;
    height: 54.5px;
    position: relative;
  }

  .row-content-status::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -49px;
    width: 49px;
    height: 1px;
    background-color: lightgrey;
    transition: background-color 0.2s ease, color 0.2s ease;
  }

}

</style>
