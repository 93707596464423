<template>
  <div class="row">

    <b-modal v-model="showModal" id="modal-center" centered>

      <div slot="modal-title">
        <span class="wordcloud-modal-title">{{statusFor(selected[2])}}</span>
      </div>

      <div class="word-cloud-selection">
        <h3 class="selected-word" :style="{color:colorFor(selected[2])}">{{selected[0]}}</h3>
        <p><i class="fa fa-info-circle text-info" /> Generated {{Math.floor(summary.impressions)}} impressions and {{summary.engagements}} engagements in {{summary.posts}} posts</p>
      </div>

      <template #modal-footer>

        <span>

          <!-- suggested -->
          <div v-if="selected[2] === 0">
            <b-button size="sm" @click="onSelectionAction('add')"> Add to {{type|titlecase}}</b-button>
          </div>

          <!-- existing in content -->
          <div v-else>
            <b-button size="sm" @click="onSelectionAction('remove')"> Remove from {{type|titlecase}}</b-button>
          </div>

        </span>

        <b-button variant="primary" size="sm" class="float-right" @click="showModal=false" >
            Close
        </b-button>

      </template>

    </b-modal>

    <div class="col word-cloud text-center">
      <vue-word-cloud
        style="height: 480px; width: 100%;"
        :words="words"
        :spacing="0.2"
        :color="([,,status]) => { return colorFor(status) }"
        :font-size-ratio="10"
        font-family="Baloo Bhaijaan">
          <template slot-scope="{text, weight, word}">
            <div :title="weight" style="cursor: pointer;" @click="onWordClick(word)">
              {{ text }}
            </div>
          </template>
        </vue-word-cloud>
        <p class="keyword-legend text-info">Suggested {{typeFor()}}</p>
        <p class="keyword-legend text-success">Existing {{typeFor()}} in Content</p>
        <p class="keyword-legend text-danger">Existing {{typeFor()}} not in Content</p>
    </div>
  </div>
</template>

<script>

// docs https://seregpie.github.io/VueWordCloud/
import VueWordCloud from 'vuewordcloud';

export default {

  name: 'WordCloud',

  created() {
    this.onAnalysisUpdated()
  },

  data() {
    return {
      words:[],
      summary: false,
      selected: false,
      showModal: false
    }
  },

  methods: {

    ensureWord(word,status) {
      const existing = this.words.find((w)=>{return w[0] === word})
      if ( existing ) {
        existing.found = status
      } else {
        this.words.push([word,1,status,[]])
      }
    },

    onAnalysisUpdated() {
      this.words.length = 0
      this.analysis.forEach((e)=>{
        this.words.push([e.kw, e.rank, e.found, e.impact])
      })
    },

    onWordClick(word) {
      const acc = {posts:0,engagements:0,reach:0,impressions:0}
      this.summary = Array.isArray(word[3])? word[3].reduce((acc,p)=>{
          acc.posts += 1
          acc.reach += p.reach
          acc.engagements += p.engagements
          acc.impressions += p.impressions
          return acc
        },acc) : acc

      this.selected = word
      this.showModal = true
    },

    onSelectionAction(action) {
      this.$emit('on-wordcloud-action', { action: action, message: this.selected[0], type: this.type })
      this.selected = false
      this.showModal = false
      this.summary = false
    },
    typeFor() {
      const type = this.type
      return type.substring(0,type.length-1)
      // return this.$options.filters.titlecase(type.substring(0,type.length-1)) // with titlecase
    },
    statusFor(status) {
      switch ( status ) {
        case 0: return `Suggested ${this.typeFor()}`
        case 1: return `Existing ${this.typeFor()} in Content`
        case 2: return `Existing ${this.typeFor()} not in Content`
      }
    },
    colorFor(status) {
      switch ( status ) {
        case 0: return '#73abfe'
        case 1: return '#28a745'
        case 2: return '#dc3545'
      }
    }
  },

  props: {
    type: String,
    analysis: Array
  },

  components: {
    [VueWordCloud.name]: VueWordCloud,
  },
}
</script>

<style lang="scss" >
.keyword-legend {
  margin:0px;
}
.word-cloud {
  margin: 20px;
}

.word-cloud-selection .h3 {
  margin: 0px!important;
}

.wordcloud-modal-title {
  font-size:1.5em;
}


</style>
