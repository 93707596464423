<template>
  <div class="attachment-v2" v-if="attachment && attachment.mimetype">
    <span v-if="attachment.mimetype.startsWith('image/')" >
      <img fluid :src="attachment|thumbnail(policy)" :width="width" >
    </span> 
    <span v-if="attachment.mimetype.startsWith('audio') || attachment.mimetype.startsWith('video')">
      <div>
        <video controls :width="width">
          <source :src="attachment|preview(policy)" :type="attachment.mimetype.startsWith('audio')? 'audio/mp3':'video/mp4'" />
        </video>
      </div>
      <p v-if="attachment.clip && attachment.clip.tStart && attachment.status !== 'completed' && attachment.status !== 'failed' && attachment.status !== 'ready'" class="text-info">
        <span>{{attachment|attachmentStatus|titlecase}} <i class="fa fa-yin-yang fa-spin" /></span>
      </p>
      <p v-if="attachment.status === 'failed'" class="text-danger errors">
        <span> {{attachment|attachmentStatus|titlecase}}  <a href="_" @click="onAction('reprocess-video',$event)" class="text-success">RETRY</A></span>
      </p>
    </span>
  </div>
</template>

<script>

export default {

  name: 'Attachment',

  props: {
    post: {
      type:Object,
      required:true
    },
    attachment: {
      type: Object,
      required: true,
    },
    policy: {
      type: Object,
      required: true
    },
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    }    
  },

  methods: {
    onAction(action,ev) {
      ev.stopPropagation()
      ev.preventDefault()
      this.$emit('on-action',{ action: action, post: this.post } )
      console.log('Attachment.onAction', action)
    }
  }

}
</script>

<style lang="scss" >

.attachment-v2 {

  width: 100%;

  margin: 0px;

  .errors {
    margin: 10px 0px 0px 0px;
    span {
      margin-top: 45px!important;
    }
    a {
      margin-left: 5px;
    }
  }

}
</style>
